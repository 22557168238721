import React from 'react';
import styled from 'styled-components';

import {fontSizes} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {BaseUrlsEnum} from '../../helpers/httpConstants';
import {calendarInviteCampaignLink} from '../calendarInvite/calendarInviteHelpers';

const StyledFooterDiv = styled.div`
  @media screen and (max-width: 650px) {
    width: 95%;
    font-size: 10px;
  }
  font-size: ${fontSizes.large};
  width: 520px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledFrontLogo = styled.img`
  width: 107px;
  height: 44px;
  display: block;
  margin: auto;
  &:hover {
    cursor: pointer;
  }
`;

const StyledFooterContent = styled.div`
  @media screen and (max-width: 650px) {
    padding: 0 32px;
  }
  color: #808080;
  padding: 0 16px;
  text-align: center;
`;

const StyledFooterLogo = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const Footer = () => (
  <StyledFooterDiv>
    <StyledFooterLogo>
      <a href={calendarInviteCampaignLink} target="_blank" rel="noopener noreferrer">
        <StyledFrontLogo
          src={`${BaseUrlsEnum.EXTRA_IMG_URL}mails/notifications/logo-front-violet-footer.png`}
          alt="Front"
        />
      </a>
    </StyledFooterLogo>
    <StyledFooterContent>
      The customer operations platform for exceptional service at scale.{' '}
      {`© ${new Date().getFullYear()} FrontApp, Inc. All rights reserved.`}
    </StyledFooterContent>
  </StyledFooterDiv>
);
