import {noop} from 'lodash';
import {createContext, useContext, useEffect} from 'react';

import {useEventHandler} from '../../../core/src/helpers/react/hooks/useEventHandler';
import {Unsubscriber} from '../../../core/src/helpers/react/reactHelpers';

/*
 * Props.
 */

interface CssContextProps {
  addCssToDocument(css: string, onLoad?: () => void): Unsubscriber;
}

/*
 * Context.
 */

const CssContext = createContext<CssContextProps>({
  addCssToDocument: () => noop,
});

export const CssContextProvider = CssContext.Provider;

/*
 * Hook.
 */

export function useCss(css: string, onLoad?: () => void) {
  const {addCssToDocument} = useContext(CssContext);
  const onLoadEventHandler = useEventHandler(onLoad ?? noop);
  useEffect(() => addCssToDocument(css, onLoadEventHandler), [addCssToDocument, css, onLoadEventHandler]);
}
