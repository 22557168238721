import {alphas, greys, greysDark, palette, paletteDark} from '../../styles/colorStyles';

export const lightBannerSemanticStyles = {
  primaryText: '',
  action: greys.shade80,

  successBackground: palette.green.shade40,
  warningBackground: palette.orange.shade40,
  neutralBackground: palette.grey.shade40,

  bannerText: greys.white,
  bannerBackground: greys.shade70,
  bannerSecondaryText: greys.shade30,
  bannerActionHover: alphas.white20,
  bannerIcon: greys.shade10,
  bannerTimeRemaining: alphas.white30,
};

export type BannerSemanticStyles = typeof lightBannerSemanticStyles;

export const darkBannerSemanticStyles = {
  primaryText: '',
  action: greys.shade80,

  successBackground: palette.green.shade40,
  warningBackground: palette.orange.shade40,
  neutralBackground: palette.grey.shade40,

  bannerText: greys.shade80,
  bannerBackground: greys.shade20,
  bannerSecondaryText: greys.shade70,
  bannerActionHover: alphas.gray20,
  bannerIcon: greys.shade80,
  bannerTimeRemaining: alphas.black30,
};

export const expandedDarkBannerSemanticStyles = {
  primaryText: greysDark.black,
  action: greysDark.black,

  successBackground: paletteDark.green.shade20,
  warningBackground: paletteDark.orange.shade20,
  neutralBackground: paletteDark.grey.shade20,

  bannerText: greysDark.shade80,
  bannerBackground: greysDark.shade20,
  bannerSecondaryText: greysDark.shade70,
  bannerActionHover: alphas.gray20,
  bannerIcon: greysDark.shade80,
  bannerTimeRemaining: alphas.black30,
};
