import axios, {AxiosRequestConfig, CancelToken} from 'axios';
import * as t from 'io-ts';

import {ApiType, readApiModel} from './apiType';
import {getBuildTimestamp} from './env';
import {CancelError, HttpResponseError} from './errors';
import {BaseUrlsEnum, RequestMethodsEnum} from './httpConstants';
import {isAbsoluteUrl, replaceUrlHost} from './url';

/*
 * Types.
 */

export interface HttpSendOptions {
  baseURL?: BaseUrlsEnum;
  method?: RequestMethodsEnum;
  path: string;
  body?: unknown;
  cancelToken?: CancelToken;
}

/*
 * Setup.
 */

const requester = axios.create({
  timeout: 30000,
  xsrfCookieName: 'front.csrf',
  xsrfHeaderName: 'X-Front-Xsrf',
  headers: {
    'X-Front-Scheduling-Build-Timestamp': `${getBuildTimestamp()}`,
  },
});

/*
 * API.
 */

/** Perform a generic HTTP method. */
export async function sendAsync(options: HttpSendOptions): Promise<any> {
  const {baseURL, method, path, body, cancelToken} = options;

  // If the url is absolute, change the host so it's the same as the current.
  const url = isAbsoluteUrl(path) ? replaceUrlHost(path) : path;

  const axiosOptions: AxiosRequestConfig = {
    baseURL,
    url,
    method,
    cancelToken,
  };

  // If a body was provided, add it.
  if (body) {
    axiosOptions.data = body;
  }

  // Perform the request.
  try {
    const response = await requester.request(axiosOptions);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new CancelError();
    }

    if (error.response) {
      throw new HttpResponseError(url, error.response.status);
    }

    throw error;
  }
}

export async function deleteAsync<T extends t.Mixed>(
  options: Pick<HttpSendOptions, 'path' | 'cancelToken' | 'baseURL'>,
): Promise<t.TypeOf<T>> {
  return sendAsync({
    ...options,
    method: RequestMethodsEnum.DELETE,
  });
}

/** Perform a generic HTTP method and parse the api model. */
export async function sendApiAsync<T extends t.Mixed>(
  apiType: ApiType<T>,
  options: HttpSendOptions,
): Promise<t.TypeOf<T>> {
  const response = await sendAsync(options);
  return readApiModel(apiType, response);
}

export async function fetchApiAsync<T extends t.Mixed>(
  apiType: ApiType<T>,
  options: Pick<HttpSendOptions, 'path' | 'cancelToken' | 'baseURL'>,
): Promise<t.TypeOf<T>> {
  const response = await sendAsync({
    ...options,
    method: RequestMethodsEnum.GET,
  });
  return readApiModel(apiType, response);
}

export async function postApiAsync<T extends t.Mixed>(
  apiType: ApiType<T>,
  options: Pick<HttpSendOptions, 'path' | 'body' | 'cancelToken' | 'baseURL'>,
): Promise<t.TypeOf<T>> {
  const response = await sendAsync({
    ...options,
    method: RequestMethodsEnum.POST,
  });
  return readApiModel(apiType, response);
}

export async function patchApiAsync<T extends t.Mixed>(
  apiType: ApiType<T>,
  options: Pick<HttpSendOptions, 'path' | 'body' | 'cancelToken' | 'baseURL'>,
): Promise<t.TypeOf<T>> {
  const response = await sendAsync({
    ...options,
    method: RequestMethodsEnum.PATCH,
  });
  return readApiModel(apiType, response);
}
