import {memoize} from 'lodash';
import {transparentize} from 'polished';
import {DefaultTheme} from 'styled-components';

import {PaletteColorsEnum} from '../../../../core/src/models/api/paletteModel';
import {blendColors} from '../../../../core/src/models/helpers/colorHelpers';
import {isDarkTheme} from '../../theme/themeTypeHelpers';

/** Visual variants for buttons. */
export enum ButtonVariantsEnum {
  PRIMARY = 'primary',
  BRAND = 'brand',
  SECONDARY = 'secondary',
  SECONDARY_DANGER = 'secondary_danger',
  DANGER = 'danger',
  CUSTOM = 'custom',
  CUSTOM_LINK = 'custom_link',
  CUSTOM_DISABLED = 'custom_disabled',
  LINK = 'link',
  BRAND_LINK = 'brand_link',
  WHITE_ON_BLACK = 'white_on_black',
  DARK_MODE_LINK = 'dark_mode_link',
  TOOLTIP_LINK = 'tooltip_link',
  TOOLTIP_SECONDARY = 'tooltip_secondary',
}

type ColorResolver = (customColor: PaletteColorsEnum) => string;
type OptionalColorResolver = (customColor?: PaletteColorsEnum) => string;
type ButtonColors = {
  [T in ButtonVariantsEnum]: {
    text: string | OptionalColorResolver;
    textDisabled: string | undefined;
    background: ColorResolver;
    backgroundHover: ColorResolver;
    backgroundDisabled: string | undefined;
    chevronShadow: ColorResolver;
  };
};

/** Button color constants by variant. */
export const findButtonColors = memoize(
  (theme: DefaultTheme): ButtonColors => ({
    [ButtonVariantsEnum.PRIMARY]: {
      text: theme.invertedText,
      textDisabled: isDarkTheme(theme) ? theme.greys.shade80 : theme.greys.shade70,
      background: () => theme.palette[PaletteColorsEnum.BLUE].shade40,
      backgroundHover: () => theme.palette[PaletteColorsEnum.BLUE].shade50,
      backgroundDisabled: isDarkTheme(theme) ? theme.greys.shade60 : theme.greys.shade40,
      chevronShadow: () => theme.button.chevronShadow,
    },
    [ButtonVariantsEnum.BRAND]: {
      text: theme.brandPalette.violets.shade100,
      textDisabled: isDarkTheme(theme) ? theme.greys.shade80 : theme.greys.shade70,
      background: () => theme.brandPalette.violets.shade700,
      backgroundHover: () => theme.brandPalette.violets.shade800,
      backgroundDisabled: isDarkTheme(theme) ? theme.greys.shade60 : theme.greys.shade40,
      chevronShadow: () => theme.button.chevronShadow,
    },
    [ButtonVariantsEnum.SECONDARY]: {
      text: theme.greys.shade80,
      textDisabled: theme.greys.shade70,
      background: () => theme.button.foreground,
      backgroundHover: () =>
        isDarkTheme(theme)
          ? transparentize(0.92, theme.greys.black)
          : blendColors(theme.foreground, theme.alphas.gray10),
      backgroundDisabled: theme.greys.shade40,
      chevronShadow: () => theme.button.chevronShadow,
    },
    [ButtonVariantsEnum.SECONDARY_DANGER]: {
      text: theme.palette[PaletteColorsEnum.RED].shade40,
      textDisabled: theme.greys.shade70,
      background: () => theme.button.foreground,
      backgroundHover: () => blendColors(theme.foreground, theme.alphas.gray10),
      backgroundDisabled: theme.greys.shade40,
      chevronShadow: () => theme.button.chevronShadow,
    },
    [ButtonVariantsEnum.DANGER]: {
      text: theme.invertedText,
      textDisabled: theme.greys.shade70,
      background: () => theme.palette[PaletteColorsEnum.RED].shade40,
      backgroundHover: () => theme.palette[PaletteColorsEnum.RED].shade50,
      backgroundDisabled: theme.greys.shade40,
      chevronShadow: () => theme.button.chevronShadow,
    },
    [ButtonVariantsEnum.CUSTOM]: {
      text: theme.invertedText,
      textDisabled: undefined,
      background: (color) => theme.palette[color].shade40,
      backgroundHover: (color) => theme.palette[color].shade50,
      backgroundDisabled: undefined,
      chevronShadow: (color) => theme.palette[color].shade50,
    },
    [ButtonVariantsEnum.CUSTOM_DISABLED]: {
      text: theme.invertedText,
      textDisabled: theme.greys.shade70,
      background: (color) => theme.palette[color].shade40,
      backgroundHover: (color) => theme.palette[color].shade50,
      backgroundDisabled: theme.greys.shade40,
      chevronShadow: () => theme.button.chevronShadow,
    },
    [ButtonVariantsEnum.LINK]: {
      text: (color) => {
        if (!color || color === PaletteColorsEnum.BLUE) {
          return theme.interactiveText;
        }

        return theme.palette[color].shade40;
      },
      textDisabled: theme.greys.shade60,
      background: () => theme.alphas.transparent,
      backgroundHover: () => theme.alphas.gray10,
      backgroundDisabled: undefined,
      chevronShadow: () => theme.button.chevronShadow,
    },
    [ButtonVariantsEnum.BRAND_LINK]: {
      text: theme.brandPalette.violets.shade900,
      textDisabled: theme.greys.shade60,
      background: () => theme.alphas.transparent,
      backgroundHover: () => theme.alphas.gray10,
      backgroundDisabled: undefined,
      chevronShadow: () => theme.button.chevronShadow,
    },
    [ButtonVariantsEnum.CUSTOM_LINK]: {
      text: (color) => (color ? theme.palette[color].shade50 : theme.invertedText),
      textDisabled: undefined,
      background: () => theme.alphas.transparent,
      backgroundHover: (color) => theme.palette[color].shade30,
      backgroundDisabled: undefined,
      chevronShadow: () => theme.button.chevronShadow,
    },
    // For P1 Auto Rec banner
    [ButtonVariantsEnum.DARK_MODE_LINK]: {
      text: theme.invertedText,
      textDisabled: undefined,
      background: () => theme.alphas.transparent,
      backgroundHover: () => transparentize(0.85, theme.greys.white),
      backgroundDisabled: undefined,
      chevronShadow: () => theme.button.chevronShadow,
    },
    // For experiment
    [ButtonVariantsEnum.WHITE_ON_BLACK]: {
      text: theme.greys.white,
      textDisabled: theme.greys.shade70,
      background: () => theme.greys.black,
      backgroundHover: () => transparentize(0.15, theme.greys.black),
      backgroundDisabled: theme.greys.shade40,
      chevronShadow: () => theme.alphas.white20,
    },
    [ButtonVariantsEnum.TOOLTIP_LINK]: {
      text: isDarkTheme(theme) ? theme.greys.white : theme.invertedText,
      textDisabled: theme.greys.shade70,
      background: () => theme.alphas.transparent,
      backgroundHover: () => transparentize(0.85, theme.greys.white),
      backgroundDisabled: undefined,
      chevronShadow: () => theme.button.chevronShadow,
    },
    [ButtonVariantsEnum.TOOLTIP_SECONDARY]: {
      text: isDarkTheme(theme) ? theme.greys.white : theme.invertedText,
      textDisabled: isDarkTheme(theme) ? theme.greys.shade70 : transparentize(0.4, theme.greys.white),
      background: () => theme.alphas.transparent,
      backgroundHover: () => transparentize(0.85, theme.greys.white),
      backgroundDisabled: isDarkTheme(theme) ? theme.greys.shade90 : transparentize(0.7, theme.greys.white),
      chevronShadow: () => theme.button.chevronShadow,
    },
  }),
);

export function isVariantWithBorder(variant: ButtonVariantsEnum) {
  return [
    ButtonVariantsEnum.SECONDARY,
    ButtonVariantsEnum.SECONDARY_DANGER,
    ButtonVariantsEnum.TOOLTIP_SECONDARY,
  ].includes(variant);
}
