import {mapValues} from 'lodash';

import {isMobile} from '../environment/mobileEnvHelpers';

/** Base class to use when subclassing Error. */
export class CustomError extends Error {
  constructor(message?: string) {
    // "Error" breaks the prototype chain here.
    super(message);

    // We need to restore it.
    const actualPrototype = new.target.prototype;
    Object.setPrototypeOf(this, actualPrototype);
  }

  // eslint-disable-next-line class-methods-use-this
  get numStackFramesToIgnore(): number {
    return 0;
  }

  // eslint-disable-next-line class-methods-use-this
  get groupingHash(): string | undefined {
    return undefined;
  }

  toSerializableObject(): unknown {
    const propertyDescriptors = Object.getOwnPropertyDescriptors(this);
    return mapValues(propertyDescriptors, ({value}) => {
      if (value instanceof CustomError) {
        return value.toSerializableObject();
      }

      return value;
    });
  }
}

/** Extend an existing error with an additional message and stack. */
export class ExtendedError extends CustomError {
  constructor(message: string, originalError: Error) {
    super(message);
    this.originalError = originalError;
  }

  readonly originalError: Error;
}

/** Generic error class to provide metadata in addition to the message. */
export class ErrorWithData<T extends Object> extends CustomError {
  constructor(message: string, data: T) {
    super(message);
    this.data = data;
  }

  // eslint-disable-next-line class-methods-use-this
  get numStackFramesToIgnore(): number {
    // First part of stacktrace removed on mobile to prevent these errors from being grouped.
    // Context: https://front.frontapp.com/open/cnv_ffvhi9v?key=J3pfJLwQJPplPIRbG68MlQ5HUgRy0v9i
    if (isMobile()) {
      return 12;
    }

    return 0;
  }

  readonly data: T;
}

/** Error used to write exhaustive switch statements. It should never be triggered. */
export class UnreachableError extends CustomError {
  constructor(_arg: never) {
    super('This code should never be reached.');
  }
}

/** Error when an operation times out. */
export class TimeoutError extends CustomError {
  constructor(elapsedWallTime: number) {
    super('Callback timed out');

    this.elapsedWallTime = elapsedWallTime;
  }

  readonly elapsedWallTime: number;
}

/** Error when an attachment is too large. */
export class AttachmentSizeError extends CustomError {
  constructor() {
    super('File size exceeds limit.');
  }
}
