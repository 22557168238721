import React, {FC, PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';

import {PaletteColorsEnum} from '../../../../../core/src/models/api/paletteModel';
import {fontWeights} from '../../../styles/fontStyles';
import {ellipsis} from '../../../styles/mixins';
import {useUserTheme} from '../../../theme/userThemeProvider';
import {TooltipCoordinator, TooltipCoordinatorProps} from '../../tooltips/tooltipCoordinator';

/*
 * Props.
 */

export interface DropdownItemContentProps {
  isChecked?: boolean;
  isBold?: boolean;
  className?: string;
  isEditingWorkspace?: boolean;
  color?: PaletteColorsEnum;
  renderTooltip?: TooltipCoordinatorProps['render'];
}

/*
 * Style.
 */

interface ContentStyleProps {
  $isBold?: boolean;
  $color?: PaletteColorsEnum;
  $hasTooltip?: boolean;
}
const StyledContentDiv = styled.div<ContentStyleProps>`
  grid-area: content;
  max-width: 100%;
  min-height: 20px;

  color: ${(p) => (p.$color && p.theme.palette[p.$color].shade40) || p.theme.greys.shade80};
  font-weight: ${(p) => (p.$isBold ? fontWeights.semibold : fontWeights.normal)};
  line-height: 20px;
  ${ellipsis()};

  ${(p) => maybeAddTooltip(p.$hasTooltip)}
`;

function maybeAddTooltip(hasTooltip?: boolean) {
  if (hasTooltip) {
    return css`
      display: flex;
      align-items: center;
    `;
  }

  return undefined;
}

const StyledTooltipCoordinator = styled(TooltipCoordinator)`
  margin-left: 6px;
`;

/*
 * Component.
 */

export const DropdownItemContent: FC<PropsWithChildren<DropdownItemContentProps>> = (props) => {
  const {isChecked, isBold, isEditingWorkspace, children, className, color, renderTooltip} = props;
  const renderAsBold = Boolean(isBold || isChecked);
  const hasTooltip = Boolean(renderTooltip);

  const theme = useUserTheme();

  return (
    <StyledContentDiv
      $isBold={renderAsBold && !isEditingWorkspace}
      $hasTooltip={hasTooltip}
      className={className}
      $color={color}
    >
      {children}
      {renderTooltip && <StyledTooltipCoordinator theme={theme} render={renderTooltip} />}
    </StyledContentDiv>
  );
};
