import _, {last, partition, sortedIndexBy} from 'lodash';
import {Observable, Subject} from 'rxjs';
import {delay} from 'rxjs/operators';

import {isMobile} from '../environment/mobileEnvHelpers';
import {onlyItem} from '../utilities/arrayHelpers';
import {buildPerformanceObserver} from './performanceObserver';

/*
 * Constants.
 */

// Sorted array of annotations of what occurred on the event loop.
const annotationsBufferSize = 300;

// For now, we'll be generous and only consider tasks above 500ms as long tasks.
const longTaskThreshold = 500;

const legacyFramePath = '/?serve=legacy';

/*
 * Types.
 */

/** https://developer.mozilla.org/en-US/docs/Web/API/TaskAttributionTiming */
export interface TaskAttributionTiming {
  containerType: string;
  containerSrc: string;
  containerId: string;
  containerName: string;
}

/** https://developer.mozilla.org/en-US/docs/Web/API/PerformanceLongTaskTiming */
interface PerformanceLongTaskTiming extends PerformanceEntry {
  entryType: 'longtask';
  attribution: ReadonlyArray<TaskAttributionTiming>;
}

/** A module that starts a task on the event loop. */
export enum TaskSourcesEnum {
  COMPONENT_RENDER = 'COMPONENT_RENDER',
  GARBAGE_COLLECTOR = 'GARBAGE_COLLECTOR',
  IOTS = 'IOTS',
  LOGGING = 'LOGGING',
  PUSH = 'PUSH',
  PUSHER = 'PUSHER',
  PREFETCH = 'PREFETCH',
  USER_INTERACTION = 'USER_INTERACTION',
}

/** A type of task on the event loop. */
export enum TaskTypesEnum {
  /** Animation frame scheduled via Front. */
  ANIMATION_FRAME = 'ANIMATION_FRAME',
  /** IndexedDB response callback. */
  DATABASE_RESPONSE = 'DATABASE_RESPONSE',
  /** Click event from a Front instrumented component. */
  CLICK_EVENT = 'CLICK_EVENT',
  /** Keypress event from a Front instrumented component. */
  KEYPRESS_EVENT = 'KEYPRESS_EVENT',
  /** Network response callback. */
  NETWORK_RESPONSE = 'NETWORK_RESPONSE',
  /** Idle callback scheduled via Front. */
  IDLE_CALLBACK = 'IDLE_CALLBACK',
  /** Same-origin iframe blocking event loop. */
  IFRAME = 'IFRAME',
  /** Interval scheduled via Front. */
  INTERVAL = 'INTERVAL',
  /** Timeout scheduled via Front. */
  TIMEOUT = 'TIMEOUT',
  /** Event listener firing on any EventTarget. */
  EVENT_LISTENER = 'EVENT_LISTENER',
  /** Animation frame scheduled directly on the window. */
  WINDOW_ANIMATION_FRAME = 'WINDOW_ANIMATION_FRAME',
  /** Idle callback scheduled directly on the window. */
  WINDOW_IDLE_CALLBACK = 'WINDOW_IDLE_CALLBACK',
  /** Timeout scheduled directly on the window. */
  WINDOW_TIMEOUT = 'WINDOW_TIMEOUT',
  /** Interval scheduled directly on the window. */
  WINDOW_INTERVAL = 'WINDOW_INTERVAL',
  /** Multiple task types (used for reporting). */
  MULTIPLE = 'MULTIPLE',
  /** Task types that don't fall in any of the above.  */
  UNKNOWN = 'UNKNOWN',
}

/** A type of timing that occurs within an event loop task. */
export enum TaskTimingTypesEnum {
  IOTS_PARSE = 'IOTS_PARSE',
  IOTS_VALIDATION = 'IOTS_VALIDATION',
  RENDER = 'RENDER',
  INSTRUMENTATION = 'INSTRUMENTATION',
}

export interface TaskMetadata {
  type?: TaskTypesEnum;
  source?: TaskSourcesEnum;
}

type EventLoopTaskSteps = ReadonlyArray<{
  name: string;
  time: number;
}>;

type EventLoopTaskTimings = Partial<{
  [key in TaskTimingTypesEnum]: number;
}>;

export interface EventLoopTask {
  startTime: number;
  duration: number;
  effectiveDuration: number;
  type: TaskTypesEnum;
  steps: EventLoopTaskSteps;
  timings: EventLoopTaskTimings;
}

interface TaskTypeAnnotation {
  annotationType: 'type';
  time: number;
  type: TaskTypesEnum;
}

interface TaskStepAnnotation {
  annotationType: 'step';
  time: number;
  data: {[name: string]: number | string};
  step: string;
}

interface TaskTimingAnnotation {
  annotationType: 'timing';
  timingType: TaskTimingTypesEnum;
  time: number;
  duration: number;
  isOverlappingTiming: boolean;
  timingName?: string;
}

export type TaskAnnotation = TaskTypeAnnotation | TaskStepAnnotation | TaskTimingAnnotation;

export enum LegacyFrameStatusesEnum {
  WAS_NEVER_VISIBLE = 'was_never_visible',
  IS_VISIBLE = 'is_visible',
  WAS_VISIBLE = 'was_visible',
}

/*
 * State.
 */

let eventLoopTasks$ = new Subject<EventLoopTask>();

let isTracing = false;
const annotationsBuffer: Array<TaskAnnotation> = [];

// Temporary logging to measure distribution of IFRAME long tasks.
let legacyFrameStatus: LegacyFrameStatusesEnum = LegacyFrameStatusesEnum.WAS_NEVER_VISIBLE;

/*
 * Api.
 */

export function annotateTaskType(type: TaskTypesEnum, metadata?: TaskMetadata) {
  if (isMobile()) {
    // On mobile, we need to manually time tasks since we don't have PerformanceObserver.
    maybeTraceTaskMobile();
  }

  addToAnnotationBuffer({
    annotationType: 'type',
    time: performance.now(),
    type,
  });

  if (metadata?.source) {
    annotateTaskStep(metadata.source);
  }
}

export function annotateTaskStep(step: string, data: {[name: string]: number | string} = {}) {
  addToAnnotationBuffer({
    annotationType: 'step',
    step,
    data,
    time: performance.now(),
  });
}

export function annotateTaskTiming(
  timingType: TaskTimingTypesEnum,
  startTime: number,
  duration = performance.now() - startTime,
  timingName?: string,
  isOverlappingTiming = false,
) {
  addToAnnotationBuffer({
    annotationType: 'timing',
    timingType,
    time: startTime,
    duration,
    isOverlappingTiming,
    timingName,
  });
}

export function fromEventLoopTasks(): Observable<EventLoopTask> {
  if (isMobile()) {
    return fromEventLoopTasksMobile();
  }

  return fromEventLoopTasksDesktop();
}

export function annotateLegacyFrameVisibilityChange(isVisible: boolean) {
  if (isVisible) {
    legacyFrameStatus = LegacyFrameStatusesEnum.IS_VISIBLE;
    return;
  }

  if (!isVisible && legacyFrameStatus === LegacyFrameStatusesEnum.IS_VISIBLE) {
    legacyFrameStatus = LegacyFrameStatusesEnum.WAS_VISIBLE;
  }
}

/** For testing. */
export function resetEventLoopHelpers() {
  eventLoopTasks$ = new Subject<EventLoopTask>();
  isTracing = false;
}

/*
 * Helpers.
 */

function maybeTraceTaskMobile() {
  if (isTracing) {
    // If we're already tracing a task, don't start tracing a new one. This could happen if call
    // annotateTaskType more than once in a task. For some tasks, such as requestAnimationFrame,
    // we call multiple times for each handler we provide, so this is not an error, and we have
    // logic in findTaskType to determine if there is instrumentation error.
    return;
  }

  const startTime = performance.now();
  isTracing = true;

  // Wait for the next event loop task to complete. This will let all microtasks complete.
  setTimeout(() => {
    isTracing = false;
    const duration = performance.now() - startTime;

    // We don't have the source attribution on mobile.
    const attribution = undefined;
    const task = buildTaskToReport(startTime, duration, attribution);
    if (!task) {
      return;
    }

    eventLoopTasks$.next(task);
  });
}

function fromEventLoopTasksMobile() {
  // Give the visibility helpers a chance to update state before sending this to be reported.
  return eventLoopTasks$.pipe(delay(0));
}

function fromEventLoopTasksDesktop() {
  return new Observable<EventLoopTask>((subscriber) => {
    const performanceObserver = buildPerformanceObserver((list) =>
      list.getEntries().forEach((entry) => {
        if (!isLongTaskEntry(entry)) {
          return;
        }

        const attribution = onlyItem(entry.attribution);
        const task = buildTaskToReport(entry.startTime, entry.duration, attribution);
        if (!task) {
          return;
        }

        subscriber.next(task);
      }),
    );
    performanceObserver.observe({entryTypes: ['longtask']});

    return () => {
      performanceObserver.disconnect();
    };
  });
}

function buildTaskToReport(
  startTime: number,
  duration: number,
  attribution: TaskAttributionTiming | undefined,
): EventLoopTask | undefined {
  if (duration < longTaskThreshold) {
    return undefined;
  }

  const {type, hasMultipleTypes} = findTaskType(startTime, duration, attribution);
  const steps = findTaskSteps(startTime, duration, hasMultipleTypes, attribution);
  const timings = findTaskTimings(startTime, duration);
  const instrumentationDuration = timings[TaskTimingTypesEnum.INSTRUMENTATION] || 0;
  return {
    startTime,
    duration,
    effectiveDuration: duration - instrumentationDuration,
    type,
    steps,
    timings,
  };
}

function findTaskType(startTime: number, duration: number, attribution: TaskAttributionTiming | undefined) {
  const attributionType = findTaskTypeForAttribution(attribution);
  const typeAnnotations = annotationsBuffer
    .filter(isTypeAnnotation)
    .filter(({time}) => isInTimeRange(time, startTime, duration))
    .map(({type, time}) => ({type, time: time - startTime}));

  if (attributionType) {
    // The task attribution corresponds to a type.
    return {
      type: attributionType,
      hasMultipleTypes: typeAnnotations.length !== 0,
    };
  }

  if (typeAnnotations.length === 0) {
    // No types were annotated.
    return {
      type: TaskTypesEnum.UNKNOWN,
      hasMultipleTypes: false,
    };
  }

  const onlyTypeAnnotation = onlyItem(typeAnnotations);
  if (onlyTypeAnnotation) {
    // Only one type was annotated.
    return {
      type: onlyTypeAnnotation.type,
      hasMultipleTypes: false,
    };
  }

  const nativeTypes = _(typeAnnotations)
    .map((annotation) => annotation.type)
    .filter((type) => nativeTypesSet.has(type))
    .value();
  const onlyNativeType = onlyItem(nativeTypes);
  if (onlyNativeType) {
    // There's only one native type. Let's just report it to not break logging.
    return {
      type: onlyNativeType,
      hasMultipleTypes: true,
    };
  }

  const [annotationsInFirstMs, annotationsAfterFirstMs] = partition(typeAnnotations, ({time}) => time < 1);
  const lastTaskTypeInFirstMs = last(annotationsInFirstMs)?.type;
  if (lastTaskTypeInFirstMs && annotationsAfterFirstMs.length === 0) {
    // Due to rounding errors and lack of precision with performance.now(), we may include
    // annotations from previous tasks. If this happens and multiple tasks are reported in
    // the first millisecond, just use the last one reported. That is the task that is problematic
    // anyways since the other ones complete in less than a ms.
    return {
      type: lastTaskTypeInFirstMs,
      hasMultipleTypes: true,
    };
  }

  // Collapse the type annotations for comparison.
  const uniqueTypes = _(typeAnnotations)
    .map((annotation) => annotation.type)
    .uniq()
    .value();
  const onlyUniqueType = onlyItem(uniqueTypes);
  if (onlyUniqueType) {
    // Multiple instances of the same type were recorded in the same task.
    return {
      type: onlyUniqueType,
      hasMultipleTypes: true,
    };
  }

  // There are multiple types annotated to this task. This could happen if we processed
  // an animation frame after a UI event.
  return {
    type: TaskTypesEnum.MULTIPLE,
    hasMultipleTypes: true,
  };
}

/** Task types that are natively recorded in Front. */
const nativeTypesSet = new Set([
  TaskTypesEnum.ANIMATION_FRAME,
  TaskTypesEnum.IDLE_CALLBACK,
  TaskTypesEnum.TIMEOUT,
  TaskTypesEnum.INTERVAL,
  TaskTypesEnum.CLICK_EVENT,
  TaskTypesEnum.KEYPRESS_EVENT,
  TaskTypesEnum.NETWORK_RESPONSE,
  TaskTypesEnum.DATABASE_RESPONSE,
]);

function findTaskTypeForAttribution(attribution: TaskAttributionTiming | undefined) {
  if (!attribution) {
    return undefined;
  }

  if (attribution.containerType === 'iframe') {
    // The TaskAttributionTiming indicates that this task occured in an iframe.
    return TaskTypesEnum.IFRAME;
  }

  return undefined;
}

function findTaskSteps(
  startTime: number,
  duration: number,
  shouldIncludeTypes: boolean,
  attribution: TaskAttributionTiming | undefined,
): EventLoopTaskSteps {
  const attributionSteps = attribution
    ? [
        {
          containerType: attribution.containerType,
          containerSrc: attribution.containerSrc,
          name: 'attribution',
          time: 0,
        },
      ]
    : [];
  const legacyFrameSteps =
    attribution?.containerSrc === legacyFramePath
      ? [
          {
            legacyFrameStatus,
            name: 'legacyFrame',
            time: 0,
          },
        ]
      : [];
  const annotationSteps = _(annotationsBuffer)
    .filter(({time}) => isInTimeRange(time, startTime, duration))
    .map((annotation) => {
      if (isStepAnnotation(annotation)) {
        const {step, time, data} = annotation;
        return {
          ...data,
          name: step,
          time: time - startTime,
        };
      }

      if (isTimingAnnotation(annotation)) {
        const {timingType, timingName, time, duration: timingDuration} = annotation;
        return {
          name: `${timingType} ${timingName}`,
          time: time - startTime,
          duration: timingDuration,
        };
      }

      if (shouldIncludeTypes && isTypeAnnotation(annotation)) {
        const {time, type} = annotation;
        return {
          name: type,
          time: time - startTime,
        };
      }

      return undefined;
    })
    .compact()
    .value();

  return [...attributionSteps, ...legacyFrameSteps, ...annotationSteps];
}

function findTaskTimings(startTime: number, duration: number): EventLoopTaskTimings {
  return _(annotationsBuffer)
    .filter(({time}) => isInTimeRange(time, startTime, duration))
    .filter(isTimingAnnotation)
    .filter((timing) => !timing.isOverlappingTiming)
    .groupBy((timing) => timing.timingType)
    .mapValues((timings: ReadonlyArray<TaskTimingAnnotation>) =>
      timings.reduce((value, timing) => value + timing.duration, 0),
    )
    .value();
}

function addToAnnotationBuffer(annotation: TaskAnnotation) {
  if (annotationsBuffer.length >= annotationsBufferSize) {
    annotationsBuffer.shift();
  }

  const insertionIndex = sortedIndexBy(annotationsBuffer, annotation, (a) => a.time);
  annotationsBuffer.splice(insertionIndex, 0, annotation);
}

function isTypeAnnotation(annotation: TaskAnnotation): annotation is TaskTypeAnnotation {
  return annotation.annotationType === 'type';
}

function isStepAnnotation(annotation: TaskAnnotation): annotation is TaskStepAnnotation {
  return annotation.annotationType === 'step';
}

function isTimingAnnotation(annotation: TaskAnnotation): annotation is TaskTimingAnnotation {
  return annotation.annotationType === 'timing';
}

function isInTimeRange(time: number, start: number, duration: number) {
  return time >= start && time < start + duration;
}

function isLongTaskEntry(entry: PerformanceEntry): entry is PerformanceLongTaskTiming {
  return entry.entryType === 'longtask';
}
