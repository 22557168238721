import {isNumber} from 'lodash';
import React, {FC, PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';

import {fontSizes, fontWeights} from '../../styles/fontStyles';
import {boxShadow} from '../../theme/themeStyleHelpers';
import {RepositionPopover, RepositionPopoverProps} from '../popovers/repositionPopover';
import {RepositionPlacement} from '../reposition/repositionHelpers';

interface TooltipProps extends RepositionPopoverProps {
  isMultiline?: boolean;
  customMargin?: number | string;
  maxWidth?: number;
  minWidth?: number;
  placement?: RepositionPlacement;
  persistent?: boolean;
  color?: string;
  borderRadius?: number;
  isBoxShadowDisabled?: boolean;
  zIndex?: number;
  /** This removes the styled wrapper - it should only be used by TooltipCoordinator. It allows the use of new Tooltip presentaional components ./tooltipContents */
  shouldRemoveWrapper?: boolean;
}

/*
 * Style.
 */

interface TooltipStyleProps {
  $isMultiline?: boolean;
  $customMargin?: number | string;
  $maxWidth?: number;
  $minWidth?: number;
  $color?: string;
  $borderRadius?: number;
  $isBoxShadowDisabled?: boolean;
  $zIndex?: number;
}
const StyledTooltipDiv = styled.div<TooltipStyleProps>`
  padding: 6px 8px;
  max-width: ${(p) => `${p.$maxWidth || 240}px`};
  min-width: ${(p) => `${p.$minWidth || 0}px`};
  ${(p) =>
    p.$zIndex &&
    css`
      z-index: ${p.$zIndex};
    `};

  border-radius: ${(p) => `${p.$borderRadius || 8}px`};
  background-color: ${(p) => p.$color || p.theme.greys.shade90};
  ${(p) => !p.$isBoxShadowDisabled && boxShadow(p.theme, `0 2px 4px ${p.theme.alphas.black40}`)};
  margin: ${(p) => getMarginStyles(p)};

  color: ${(p) => p.theme.greys.white};
  font-size: ${fontSizes.verySmall};
  font-weight: ${fontWeights.normal};
  line-height: 16px;
`;

function getMarginStyles(props: TooltipStyleProps) {
  if (props.$customMargin) {
    return isNumber(props.$customMargin) ? `${props.$customMargin}px` : props.$customMargin;
  }

  if (props.$isMultiline) {
    return '7px';
  }

  return 'auto';
}

/*
 * Component.
 */

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = (props) => {
  const placement = props.placement || 'bottom';

  const isInteractive = Boolean(props.persistent);

  return (
    <RepositionPopover {...props} isInteractive={isInteractive} placement={placement} shouldSkipApplyMaxSize>
      {props.shouldRemoveWrapper ? (
        <>{props.children}</>
      ) : (
        <StyledTooltipDiv
          $isMultiline={props.isMultiline}
          $customMargin={props.customMargin}
          $maxWidth={props.maxWidth}
          $minWidth={props.minWidth}
          $color={props.color}
          $borderRadius={props.borderRadius}
          $isBoxShadowDisabled={props.isBoxShadowDisabled}
          $zIndex={props.zIndex}
        >
          {props.children}
        </StyledTooltipDiv>
      )}
    </RepositionPopover>
  );
};
