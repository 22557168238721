import {isString} from 'lodash';
import memoize from 'lru-memoize';
import {gte, valid} from 'semver';

import {globalWindow} from '../browser/browserHelpers';
import {isMobile} from './mobileEnvHelpers';

/*
 * Platform.
 */

export enum PlatformsEnum {
  WINDOWS = 'WINDOWS',
  MACOS = 'MACOS',
  LINUX = 'LINUX',
}

export const findPlatform = memoize()((): PlatformsEnum | undefined => {
  // We can switch to navigator.userAgentData.platform once it is no longer experimental and typed in Typescript.
  //  https://erikmartinjordan.com/navigator-platform-deprecated-alternative.
  const {platform} = globalWindow.navigator;

  // navigator.platform is an empty array on React Native 0.66.
  if (isMobile() || !isString(platform)) {
    return undefined;
  }

  const browserPlatform = platform.toLowerCase();

  if (browserPlatform.includes('mac')) {
    return PlatformsEnum.MACOS;
  }

  if (browserPlatform.includes('linux')) {
    return PlatformsEnum.LINUX;
  }

  return PlatformsEnum.WINDOWS;
});

export function isMacOS(): boolean {
  return findPlatform() === PlatformsEnum.MACOS;
}

/*
 * Browser type.
 */

export const isChrome = memoize()((): boolean => !isMobile() && computeUserAgentIncludes('Chrome'));
export const isSafari = memoize()(
  (): boolean => !isMobile() && computeUserAgentIncludes('Safari') && !isChrome(),
);
export const isElectron = memoize()((): boolean => !isMobile() && computeUserAgentIncludes('Electron'));

function computeUserAgentIncludes(name: string): boolean {
  return globalWindow.navigator.userAgent.includes(name);
}

/*
 * Features.
 */

export function isDesktopAppVersionGTE(version: string): boolean {
  const match = globalWindow.navigator.userAgent.match(/Front\/([^\s]+)/);
  const electronVersion = match && valid(match[1]);
  if (!electronVersion) {
    return false;
  }

  return gte(electronVersion, version);
}

export function isElectronVersionGTE(version: string): boolean {
  const match = globalWindow.navigator.userAgent.match(/Electron\/([^\s]+)/);
  const electronVersion = match && valid(match[1]);
  if (!electronVersion) {
    return false;
  }

  return gte(electronVersion, version);
}

export function isMacAppStoreElectronBuild(): boolean {
  return globalWindow.navigator.userAgent.includes('front-desktop mas');
}

/** Decides whether a popup should be rendered into a child window. */
export function shouldPopout(shouldForcePopout = false): boolean {
  // In the browser: only when requested. In Electron: by default.
  return shouldForcePopout || isElectron();
}

export const popoutRequiresAboutBlank = memoize()((): boolean => isElectron());

export function supportsPopout(): boolean {
  return true;
}

export function supportsDownloadDragSource(): boolean {
  return isChrome() || isElectron();
}

export function canRegisterDefaultEmailClient(): boolean {
  return isElectron() || Boolean(globalWindow.navigator && globalWindow.navigator.registerProtocolHandler);
}
