import React, {Component, createRef, ReactNode, Ref, RefObject} from 'react';
import styled, {css} from 'styled-components';

import {
  containsFocus,
  InputLike,
  isHtmlInputElement,
  isInputLike,
} from '../../../../core/src/helpers/browser/domHelpers';
import {FrontMouseEvent, makeInputId, RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {textSelection} from '../../styles/mixins';
import {makeSizeConstants} from '../../styles/styleHelpers';
import {FieldDescription} from '../fields/fieldDescription';
import {FieldLabel} from '../fields/fieldLabel';
import {BoxedInputChildProps, boxedInputColorReaders} from './boxedInputConstants';

/*
 * Props.
 */

export interface BoxedInputInheritableProps {
  className?: string;
  boxClassName?: string;
  boxStyles?: React.CSSProperties;
  boxWrapperStyles?: React.CSSProperties;
  /** The size variant to render. */
  size?: VisualSizesEnum;

  /** The label to display above the input. */
  label?: ReactNode;
  /** The additional text to display above the input, to the right of the label and the isRequired indicator (*). */
  additionalLabelInfo?: ReactNode;

  /** Should interactions with the input be allowed. */
  isDisabled?: boolean;
  /** Should the input steal the focus. */
  shouldAutoFocus?: boolean;
  /** Force the hover visual state. */
  hasHover?: boolean;

  /** Is this input in an error state. */
  hasError?: boolean;
  /** The error message to display below the input. Will take precedence over `message` */
  errorMessage?: ReactNode;
  /** The message to display below the input. */
  message?: ReactNode;

  /** Ref to the box around the input. */
  boxRef?: Ref<HTMLDivElement>;
  /** Should this input behave like a button. */
  isButton?: boolean;

  /** Override the background color. */
  backgroundColor?: string;
  backgroundHoverColor?: string;

  /** Display a required indicator next to the label. */
  isRequired?: boolean;

  /** Disable Y-axis margin. */
  noMargin?: boolean;
  /** Disables margin from the error message or message fields. */
  shouldDisableBottomTextMargin?: boolean;
}

export interface BoxedInputProps extends BoxedInputInheritableProps {
  children: RendererOf<BoxedInputChildProps>;
  getInput?: () => InputLike | undefined;
}

/*
 * Wrapper style.
 */

interface StyledProps extends BoxedInputInheritableProps {
  size: VisualSizesEnum;
  noMargin?: boolean;
}

interface BoxedInputStyleProps {
  $size: VisualSizesEnum;
  $noMargin?: boolean;
  $isDisabled?: boolean;
  $hasError?: boolean;
  $hasHover?: boolean;
  $backgroundColor?: string;
  $backgroundHoverColor?: string;
  $isButton?: boolean;
  $label?: ReactNode;
  $hasMessage?: boolean;
}

const StyledWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

/*
 * Label style.
 */

export {StyledRequiredSpan, StyledTextLabel} from '../fields/fieldLabel';

/*
 * Box style.
 */

const boxMargins = makeSizeConstants('12px 0', '12px 0', '14px 0');
const heights = makeSizeConstants(26, 30, 40);
export const StyledBoxDiv = styled.div<BoxedInputStyleProps>`
  display: flex;

  border-radius: ${(p) => (p.$size === VisualSizesEnum.SMALL ? '6px' : '8px')};
  margin: ${(p) => (p.$noMargin ? 0 : boxMargins[p.$size])};
  min-height: ${(p) => heights[p.$size]}px;

  color: ${(p) => boxedInputColorReaders.text(p)};
  background-color: ${(p) => p.$backgroundColor || boxedInputColorReaders.background};

  ${textSelection()};

  /* Hover styles. */
  ${(p) => maybeAddHoverStyle(p)}

  &:focus-within {
    background-color: ${boxedInputColorReaders.backgroundFocused};
    box-shadow:
      0 0 0 1px ${(p) => p.theme.palette.blue.shade40},
      inset 0 0 0 1px ${(p) => p.theme.palette.blue.shade40};
  }

  &[focus-within] {
    background-color: ${boxedInputColorReaders.backgroundFocused};
    box-shadow:
      0 0 0 1px ${(p) => p.theme.palette.blue.shade40},
      inset 0 0 0 1px ${(p) => p.theme.palette.blue.shade40};
  }

  /* Button state. */
  ${(p) => maybeAddButtonStyle(p)}

  /* Disabled state. */
  ${(p) => maybeAddDisabledStyle(p)}

  /* Error state. */
  ${(p) => maybeAddErrorStyle(p)}

  /* Message state. */
  ${(p) => maybeAddMessageStyle(p)}
`;

function maybeAddMessageStyle(props: BoxedInputStyleProps) {
  if (!props.$hasMessage) {
    return '';
  }
  return css`
    margin-bottom: 6px;
  `;
}

function maybeAddHoverStyle(props: BoxedInputStyleProps) {
  if (props.$isDisabled) {
    return undefined;
  }

  if (props.$hasError) {
    return css`
      &:hover {
        background-color: ${boxedInputColorReaders.backgroundErrorHover};
      }
    `;
  }

  return css`
    &:hover {
      background-color: ${props.$backgroundHoverColor || boxedInputColorReaders.backgroundHover};
    }
  `;
}

function maybeAddButtonStyle(props: BoxedInputStyleProps) {
  if (!props.$isButton || props.$isDisabled) {
    return undefined;
  }

  return css`
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    ${addButtonHoverStyle(props)};
  `;
}

function addButtonHoverStyle(props: BoxedInputStyleProps) {
  const backgroundHoverColor = props.$backgroundHoverColor
    ? props.$backgroundHoverColor
    : boxedInputColorReaders.backgroundHover;

  // Find the background color depending on the error state.
  const backgroundColor = !props.$hasError
    ? backgroundHoverColor
    : boxedInputColorReaders.backgroundErrorHover;

  // If the hover is forced, set background.
  if (props.$hasHover) {
    return css`
      background-color: ${backgroundColor};
    `;
  }

  // Otherwise, set the background on hover.
  return css`
    &:hover {
      background-color: ${backgroundColor};
    }
  `;
}

function maybeAddDisabledStyle(props: BoxedInputStyleProps) {
  if (!props.$isDisabled) {
    return undefined;
  }

  return css`
    opacity: 0.5;

    color: ${boxedInputColorReaders.textDisabled};
    background-color: ${boxedInputColorReaders.backgroundDisabled};
    box-shadow: inset 0 0 0 2px ${(p) => p.theme.greys.shade30};

    -webkit-user-select: none;
    user-select: none;
    cursor: not-allowed;
  `;
}

function maybeAddErrorStyle(props: BoxedInputStyleProps) {
  if (!props.$hasError) {
    return '';
  }

  return css`
    background-color: ${boxedInputColorReaders.backgroundError};

    &:focus-within {
      background-color: ${boxedInputColorReaders.backgroundFocused};
      box-shadow:
        0 0 0 1px ${(p) => p.theme.palette.red.shade40},
        inset 0 0 0 1px ${(p) => p.theme.palette.red.shade40};
    }
    &[focus-within] {
      background-color: ${boxedInputColorReaders.backgroundFocused};
      box-shadow:
        0 0 0 1px ${(p) => p.theme.palette.red.shade40},
        inset 0 0 0 1px ${(p) => p.theme.palette.red.shade40};
    }
  `;
}

/*
 * Component.
 */

/** Base component to build various boxed inputs. */
export class BoxedInput extends Component<BoxedInputProps> {
  constructor(props: BoxedInputProps) {
    super(props);
    this.inputId = makeInputId('boxed-input');
    this.wrapperRef = createRef<HTMLDivElement>();
  }

  private readonly inputId: string;
  private readonly wrapperRef: RefObject<HTMLDivElement>;

  // Check if focus is inside our component
  private readonly isFocusInsideWrapper = () => {
    const currentWrapper = this.wrapperRef.current;
    return currentWrapper && containsFocus(currentWrapper);
  };

  /*
   * Lifecycle.
   */

  // Focus the input on mount if needed.
  componentDidMount() {
    const {shouldAutoFocus} = this.props;
    if (!shouldAutoFocus) {
      return;
    }

    this.focus();
  }

  /*
   * Public API.
   */

  focus(shouldSelectContent?: boolean) {
    const input = this.findInput();
    if (!input) {
      return;
    }

    input.focus();

    if (!shouldSelectContent || !isHtmlInputElement(input)) {
      return;
    }

    input.setSelectionRange(0, input.value.length);
  }

  blur() {
    const input = this.findInput();
    return input && input.blur();
  }

  private findInput() {
    if (this.props.getInput) {
      return this.props.getInput();
    }

    const currentWrapper = this.wrapperRef.current;
    if (!currentWrapper) {
      return undefined;
    }

    const descendants = Array.from(currentWrapper.querySelectorAll<HTMLElement>('div, input, textarea'));
    return descendants.find(isInputLike);
  }

  /*
   * Event handlers.
   */

  // If the currently selected element is the input, cancel.
  private readonly onBoxMouseDown: (event: FrontMouseEvent) => void = (event) => {
    if (!this.isFocusInsideWrapper() || isInputLike(event.target)) {
      return;
    }

    event.preventDefault();
  };

  // On a click on the box, focus the input.
  private readonly onBoxClick: (event: FrontMouseEvent) => void = (event) => {
    if (!this.isFocusInsideWrapper() || !isInputLike(event.target)) {
      this.focus();
    }
  };

  /*
   * Render.
   */

  render() {
    const fullProps = {
      ...this.props,
      size: this.props.size || VisualSizesEnum.MEDIUM,
    };
    const {boxClassName, boxStyles, boxWrapperStyles, size, isDisabled, boxRef, children} = fullProps;
    const childProps = {
      size,
      isDisabled: Boolean(isDisabled),
      inputId: this.inputId,
    };

    return (
      <StyledWrapperDiv className={this.props.className} ref={this.wrapperRef} style={boxWrapperStyles}>
        {maybeRenderLabel(fullProps, this.inputId)}
        <StyledBoxDiv
          style={boxStyles}
          className={boxClassName}
          $size={fullProps.size}
          $noMargin={fullProps.noMargin}
          $isDisabled={fullProps.isDisabled}
          $hasError={fullProps.hasError}
          $hasMessage={Boolean((fullProps.hasError && fullProps.errorMessage) || fullProps.message)}
          $hasHover={fullProps.hasHover}
          $backgroundColor={fullProps.backgroundColor}
          $backgroundHoverColor={fullProps.backgroundHoverColor}
          $isButton={fullProps.isButton}
          ref={boxRef}
          onMouseDown={this.onBoxMouseDown}
          onClick={this.onBoxClick}
        >
          {children(childProps)}
        </StyledBoxDiv>
        {maybeRenderBelowInput(fullProps)}
      </StyledWrapperDiv>
    );
  }
}

function maybeRenderLabel(props: StyledProps, inputId?: string) {
  if (!props.label) {
    return null;
  }

  return (
    <FieldLabel
      htmlFor={inputId}
      additionalLabelInfo={props.additionalLabelInfo}
      size={props.size}
      noMargin={props.noMargin}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
    >
      {props.label}
    </FieldLabel>
  );
}

function maybeRenderBelowInput(props: StyledProps) {
  return (
    <FieldDescription
      size={props.size}
      message={props.message}
      shouldDisableBottomTextMargin={props.shouldDisableBottomTextMargin}
      errorMessage={props.hasError ? props.errorMessage : undefined}
    />
  );
}
