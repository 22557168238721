import {lighten} from 'polished';

import {alphas, greysDark, paletteDark} from '../../styles/colorStyles';
import {darkAddOnPillSemanticStyles} from '../semantic/addOnPillSemanticStyles';
import {darkAnnotationSemanticStyles} from '../semantic/annotationSemanticStyles';
import {darkAppFrameSemanticStyles} from '../semantic/appFrameSemanticStyles';
import {darkAvatarSemanticStyles} from '../semantic/avatarSemanticStyles';
import {darkBannerSemanticStyles} from '../semantic/bannerSemanticStyles';
import {darkBetaFeaturePillSemanticStyles} from '../semantic/betaFeaturePillSemanticStyles';
import {darkButtonSemanticStyles} from '../semantic/buttonSemanticStyles';
import {darkCellSemanticStyles} from '../semantic/cellSemanticStyles';
import {darkCheckboxSemanticStyles} from '../semantic/checkboxSemanticStyles';
import {darkCommentComposerSemanticStyles} from '../semantic/commentComposerSemanticStyles';
import {darkInlineBannerSemanticStyles} from '../semantic/inlineBannerSemanticStyles';
import {darkLeftBarSemanticStyles} from '../semantic/leftBarSemanticStyles';
import {darkLegacyPillSemanticStyles} from '../semantic/legacyPillSemanticStyles';
import {darkMessageSemanticStyles} from '../semantic/messageSemanticStyles';
import {darkNewFeaturePillSemanticStyles} from '../semantic/newFeaturePillSemanticStyles';
import {darkNotificationIndicatorSemanticStyles} from '../semantic/notificationIndicatorSemanticStyles';
import {sharedSearchBarSemanticStyles} from '../semantic/searchBarSemanticStyles';
import {darkSeparatorSemanticStyles} from '../semantic/separatorSemanticStyles';
import {darkSettingsSemanticStyles} from '../semantic/settingsSemanticStyles';
import {darkTagSemanticStyles} from '../semantic/tagSemanticStyles';
import {darkTooltipSemanticStyles, upsellTooltipSemanticStyles} from '../semantic/tooltipSemanticStyles';
import {sharedTopBarSemanticStyles} from '../semantic/topBarSemanticStyles';
import {darkVerticalSwitcherSemanticStyles} from '../semantic/verticalSwitcherSemanticStyles';
import {themeConstants} from '../themeConstants';
import {SemanticStyles} from '../themeTypes';
import {DARK_APP_FOREGROUND_COLOR} from './darkColorHelpers';

export const darkSemanticStyles: SemanticStyles = {
  brand: themeConstants.black,

  appBackground: greysDark.white,
  foreground: DARK_APP_FOREGROUND_COLOR,

  text: greysDark.shade80,
  composerText: greysDark.black,
  composerPlaceholder: greysDark.shade70,

  interactiveContainer: paletteDark.blue.shade40,
  interactiveText: paletteDark.blue.shade50,
  // TODO: Get a hover state color from design, since we don't have
  // a shade60 we can lighten these links to.
  interactiveTextHover: lighten(0.06, paletteDark.blue.shade50),
  interactiveIcon: paletteDark.blue.shade50,
  interactiveBorders: paletteDark.blue.shade50,

  invertedText: greysDark.black,
  invertedSubtext: alphas.white50,
  invertedIcon: alphas.white50,

  modalBackdrop: alphas.black60,

  colorScheme: 'dark',

  urlTooltipMinorText: alphas.black60,
  inputBackground: greysDark.white,
  windowsButtonHover: alphas.white20,

  highlightText: greysDark.shade20,

  scrollbarTrack: alphas.transparent,
  scrollbarThumb: alphas.white30,
  scrollbarThumbHover: alphas.white40,

  refinedP2ResultsIndicator: paletteDark.blue.shade50,

  cell: darkCellSemanticStyles,
  separator: darkSeparatorSemanticStyles,
  topBar: sharedTopBarSemanticStyles,
  searchBar: sharedSearchBarSemanticStyles,
  tag: darkTagSemanticStyles,
  avatar: darkAvatarSemanticStyles,
  message: darkMessageSemanticStyles,
  banner: darkBannerSemanticStyles,
  inlineBanner: darkInlineBannerSemanticStyles,
  button: darkButtonSemanticStyles,
  annotation: darkAnnotationSemanticStyles,
  commentComposer: darkCommentComposerSemanticStyles,
  leftBar: darkLeftBarSemanticStyles,
  settings: darkSettingsSemanticStyles,
  checkbox: darkCheckboxSemanticStyles,
  appFrame: darkAppFrameSemanticStyles,
  tooltip: darkTooltipSemanticStyles,
  upsellTooltip: upsellTooltipSemanticStyles,

  verticalSwitcher: darkVerticalSwitcherSemanticStyles,

  betaFeaturePill: darkBetaFeaturePillSemanticStyles,
  newFeaturePill: darkNewFeaturePillSemanticStyles,
  addOnPill: darkAddOnPillSemanticStyles,
  legacyPill: darkLegacyPillSemanticStyles,
  notificationIndicator: darkNotificationIndicatorSemanticStyles,
};
