import React, {FC, useRef} from 'react';
import styled from 'styled-components';

import {VisualSizesEnum} from '../../styles/commonStyles';
import {Input, InputInheritableProps, InputProps, InputTypesEnum} from '../inputs/input';
import {useKeyboardNavigation} from '../keyboardNavigation/keyboardNavigation';
import {BoxedInputChildProps, boxedInputColorReaders, inputContentStyles} from './boxedInputConstants';

/*
 * Props.
 */

export const BoxedInputElementTypesEnum = InputTypesEnum;
export type BoxedInputElementInheritableProps = InputInheritableProps;
export type BoxedInputElementProps = InputProps & BoxedInputChildProps;

/*
 * Style.
 */

interface InputStyleProps {
  $size: VisualSizesEnum;
}

const StyledInput = styled(Input)<InputStyleProps>`
  flex: 1;
  width: 0;
  overflow: hidden;

  ${(p) => inputContentStyles[p.$size]};

  &::placeholder {
    color: ${(p) => boxedInputColorReaders.textPlaceholder(p)};
  }
`;

/*
 * Component.
 */

export const BoxedInputElement: FC<BoxedInputElementProps> = (props) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const {inputRef} = props;
  const combinedRef = inputRef || ref;
  useKeyboardNavigation({ref: combinedRef});
  return <StyledInput $size={props.size} {...props} inputRef={combinedRef} />;
};
