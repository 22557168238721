import {transparentize} from 'polished';

import {alphas, greys, greysDark} from '../../styles/colorStyles';

export const lightButtonSemanticStyles = {
  foreground: greys.white,
  chevronShadow: alphas.black30,
  border: alphas.black30,
  invertedBorder: transparentize(0.85, greysDark.black),
};

export type ButtonSemanticStyles = typeof lightButtonSemanticStyles;

export const darkButtonSemanticStyles: ButtonSemanticStyles = {
  foreground: alphas.transparent,
  chevronShadow: alphas.white10,
  border: transparentize(0.85, greysDark.black),
  invertedBorder: alphas.black30,
};
