import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import {identity, isNumber, isString} from 'lodash';

import {shouldDisableSafeMode} from './safeTypesHelpers';

/*
 * Types.
 */

type EnumLike = {[key: string]: string | number};
interface SafeEnumLike extends EnumLike {
  FALLBACK_UNKNOWN: 'FALLBACK_UNKNOWN';
}

function isSafeEnumLike(object: EnumLike): object is SafeEnumLike {
  return Boolean(object && object.FALLBACK_UNKNOWN && object.FALLBACK_UNKNOWN === 'FALLBACK_UNKNOWN');
}

type EnumKeyOf<T extends EnumLike> = string & keyof T;
type EnumValueOf<T extends EnumLike> = T[keyof T];

function makeBaseValidator<T extends EnumLike>(isEnumValue: (value: unknown) => value is EnumValueOf<T>) {
  return (value: unknown, context: t.Context): E.Either<t.Errors, EnumValueOf<T>> => {
    // Reuse the type guard, since the runtime and encoded values are the same.
    if (isEnumValue(value)) {
      return t.success(value);
    }

    // For backward compatibility, also decode numbers that were encoded as strings.
    const numericValue = isString(value) ? Number(value) : NaN;
    if (isEnumValue(numericValue)) {
      return t.success(numericValue);
    }

    return t.failure(value, context);
  };
}
export class EnumType<T extends EnumLike> extends t.Type<EnumValueOf<T>, string | number, unknown> {
  constructor(enumObject: T) {
    const isEnumValue = makeEnumTypeGuard(enumObject);

    const validate = makeBaseValidator(isEnumValue);

    // The runtime and encoded values are the same.
    const encode = identity;

    super('Enum', isEnumValue, validate, encode);
  }
}

// Needs to be exported
export class SafeEnumType<T extends EnumLike> extends t.Type<
  EnumValueOf<T>,
  string | number | undefined,
  unknown
> {
  constructor(enumObject: T, fallback: EnumValueOf<T>) {
    const isEnumValue = makeEnumTypeGuard(enumObject);
    const baseValidation = makeBaseValidator(isEnumValue);

    const validate = (value: unknown, context: t.Context): E.Either<t.Errors, EnumValueOf<T>> => {
      const result = baseValidation(value, context);
      const isStrict = shouldDisableSafeMode(context);
      if (E.isRight(result) || isStrict) {
        return result;
      }

      // console.error(`[io-ts-enum] Invalid enum: ${value}, expected`);
      // Fallback to unknown if the value is not recognized
      return t.success(fallback);
    };

    // When encoding, for safety, we transform the fallback value to undefined.
    const encode = (a: EnumValueOf<T>) => {
      if (a === enumObject.FALLBACK_UNKNOWN) {
        return undefined;
      }
      return a;
    };

    super('SafeEnum', isEnumValue, validate, encode);
  }
}

/*
 * Helpers.
 */

/** Returns the keys of an enum. */
export function collectEnumKeys<T extends EnumLike>(enumObject: T): ReadonlyArray<EnumKeyOf<T>> {
  return Object.freeze(
    Object.keys(enumObject)
      // Filter out reverse mappings for numeric members.
      .filter((key): key is EnumKeyOf<T> => {
        const numberKey = Number(key);
        return isNaN(numberKey) || enumObject[enumObject[key]] !== numberKey;
      }),
  );
}

/** Returns the values of an enum. */
export function collectEnumValues<T extends EnumLike>(enumObject: T): ReadonlyArray<EnumValueOf<T>> {
  return Object.freeze(collectEnumKeys(enumObject).map((key) => enumObject[key]));
}

/** Build a type guard for the specified Enum. */
export function makeEnumTypeGuard<T extends EnumLike>(enumObject: T) {
  const enumValues = new Set<string | number>(collectEnumValues(enumObject));

  return (value: unknown): value is EnumValueOf<T> =>
    (isString(value) || isNumber(value)) && enumValues.has(value);
}

/**
 * Build a runtime type for the specified Enum.
 * @deprecated Please use `makeSafeEnumRuntimeType` when parsing unknown data from the backend.
 * Otherwise use `makeStrictEnumRuntimeType` to convey that it is okay to reject an unrecognized value.
 * @param enumObject The typescript enum used to create the runtime io-ts type.
 */
export function makeEnumRuntimeType<T extends EnumLike>(enumObject: T) {
  return new EnumType(enumObject);
}

/**
 * Build a runtime type for the specified Enum.
 * @param enumObject The typescript enum used to create the runtime io-ts type.
 */
export function makeStrictEnumRuntimeType<T extends EnumLike>(enumObject: T) {
  return new EnumType(enumObject);
}

/**
 * Build a runtime type for the specified Enum. Autmatically falls back to `FALLBACK_UNKNOWN` if parsing fails.
 * Warning: Parsing any invalid enum value will still fail inside a type created with`makeStrictModeType` or a `t.union`.
 * @param enumObject The enum used to create the type.
 */
export function makeSafeEnumRuntimeType<T extends SafeEnumLike>(enumObject: T): SafeEnumType<T>;
/**
 * Build a runtime type for the specified Enum. Autmatically falls back to the second parameter if parsing fails.
 * Warning: Parsing any invalid enum value will still fail inside a type created with`makeStrictModeType` or a `t.union`.
 * @param enumObject The enum used to create the type.
 * @param fallback One value of the enum to use as a fallback if parsing fails.
 */
export function makeSafeEnumRuntimeType<T extends EnumLike>(
  enumObject: T,
  fallback: EnumValueOf<T>,
): SafeEnumType<T>;
export function makeSafeEnumRuntimeType<T extends EnumLike>(enumObject: T, fallback?: EnumValueOf<T>) {
  if (isSafeEnumLike(enumObject)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return new SafeEnumType(enumObject, enumObject.FALLBACK_UNKNOWN as EnumValueOf<T>);
  }
  if (!fallback) {
    throw new Error("This should not happen (makeSafeEnumRuntimeType's signature prevents it))");
  }
  return new SafeEnumType(enumObject, fallback);
}
