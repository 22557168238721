import React, {FC, PropsWithChildren, ReactNode} from 'react';
import styled, {css} from 'styled-components';

import {buildHoverParentClassName} from '../../../../../core/src/helpers/browser/hoverHelpers';
import {
  buildInteractionIdProps,
  InteractionComponentProps,
} from '../../../../../core/src/helpers/interaction/interactionHelpers';
import {InteractionTypesEnum} from '../../../../../core/src/helpers/interaction/interactionTypes';
import {FrontEvent, Renderer, RendererOf} from '../../../../../core/src/helpers/react/reactHelpers';
import {useLegacyInteractionContext} from '../../../interactionTracking/interactionContext';
import {useInteractionTracking} from '../../../interactionTracking/interactionTrackingContext';
import {
  InteractiveListItem,
  InteractiveListItemChildrenProps,
  InteractiveListItemPublicProps,
} from '../../interactiveList/interactiveListItem';
import {RepositionPlacement} from '../../reposition/repositionHelpers';
import {TooltipCoordinator} from '../../tooltips/tooltipCoordinator';
import {DropdownItemCheckmark} from './dropdownItemCheckmark';

/*
 * Constants.
 */

export const DROPDOWN_ITEM_DISABLED_OPACITY = 0.5;

/*
 * Props.
 */

export interface DropdownItemRightContentProps {
  isHighlighted?: boolean;
}

export interface DropdownItemProps extends InteractiveListItemPublicProps, InteractionComponentProps {
  checkmarkLabel?: ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  shouldHideCheckmark?: boolean;
  renderRightContent?: RendererOf<DropdownItemRightContentProps>;
  renderTooltip?: Renderer;
  tooltipPersistent?: boolean;
  tooltipPlacement?: RepositionPlacement;
  tooltipMaxWidth?: number;
  tooltipBorderRadius?: number;
  className?: string;
  shouldReserveRightIcon?: boolean;
  isNotInteractive?: boolean;
}

/*
 * Style.
 */

interface StyleProps {
  $isDisabled?: boolean;
  $isHighlighted?: boolean;
  $shouldReserveRightIcon?: boolean;
}

const StyledDropdownItemDiv = styled.div<StyleProps>`
  display: grid;
  grid-template-columns: 12px auto auto 1fr auto ${(p) => (p.$shouldReserveRightIcon ? '24px' : 'auto')} 12px;
  grid-template-areas: '. left-icon content secondary-content badges right-icon end-padding';
  justify-items: start;
  padding: 5px 0;

  ${(p) => conditionalStyle(p)};
`;

function conditionalStyle(props: StyleProps) {
  // Disabled state.
  if (props.$isDisabled) {
    return css`
      opacity: ${DROPDOWN_ITEM_DISABLED_OPACITY};
    `;
  }

  // Highlighted state.
  if (props.$isHighlighted) {
    return css`
      background-color: ${(p) => p.theme.alphas.gray10};
    `;
  }

  return '';
}

/*
 * Component.
 */

export const DropdownItem: FC<PropsWithChildren<DropdownItemProps>> = (props) => {
  const interactionContext = useLegacyInteractionContext();
  const {trackComponentInteraction} = useInteractionTracking();

  const onSelect = (event: FrontEvent) => {
    if (props.isDisabled) {
      event.preventDefault();
      return;
    }

    if (props.onSelect) {
      props.onSelect(event);
    }

    trackComponentInteraction(InteractionTypesEnum.CLICK, interactionContext, props, event);
  };

  function renderDropdownItemDiv(
    itemProps: InteractiveListItemChildrenProps['itemProps'],
    isHighlighted: boolean,
  ) {
    return (
      <StyledDropdownItemDiv
        {...itemProps}
        className={buildHoverParentClassName(isHighlighted, props.className)}
        $isDisabled={props.isDisabled}
        $isHighlighted={isHighlighted}
        $shouldReserveRightIcon={props.shouldReserveRightIcon}
        {...buildInteractionIdProps(props.interactionId)}
      >
        {props.children}
        {renderRightIcon(props, isHighlighted)}
      </StyledDropdownItemDiv>
    );
  }

  if (props.isNotInteractive) {
    return <>{maybeWrapInTooltip(props, renderDropdownItemDiv(null, false))}</>;
  }

  return (
    <InteractiveListItem {...props} onSelect={onSelect}>
      {({itemProps, isHighlighted}) =>
        maybeWrapInTooltip(props, renderDropdownItemDiv(itemProps, isHighlighted))
      }
    </InteractiveListItem>
  );
};

function maybeWrapInTooltip(props: DropdownItemProps, children: ReactNode) {
  if (!props.renderTooltip) {
    return children;
  }

  return (
    <TooltipCoordinator
      placement={props.tooltipPlacement}
      persistent={props.tooltipPersistent}
      render={props.renderTooltip}
      maxWidth={props.tooltipMaxWidth}
      borderRadius={props.tooltipBorderRadius}
    >
      {children}
    </TooltipCoordinator>
  );
}

function renderRightIcon(props: DropdownItemProps, isHighlighted: boolean) {
  // If we have a generic render prop, use it
  if (props.renderRightContent) {
    return props.renderRightContent({isHighlighted});
  }

  // If we have a checked value, we need to render a checkmark.
  if (props.isChecked !== undefined && !props.shouldHideCheckmark) {
    return <DropdownItemCheckmark label={props.checkmarkLabel} isChecked={props.isChecked} />;
  }

  // Otherwise, nothing to render.
  return null;
}
