import {darken} from 'polished';

import {PaletteColorsEnum} from '../../../../core/src/models/api/paletteModel';
import {blendColors} from '../../../../core/src/models/helpers/colorHelpers';
import {alphas, greys, greysDark, palette, paletteDark} from '../../styles/colorStyles';

export const lightMessageSemanticStyles = {
  background: greys.white,
  border: greys.shade30,
  header: greys.shade90,
  commentText: greys.shade80,
  readCommentBackground: blendColors(greys.shade20, alphas.gray10),

  commentReactionBackground: palette[PaletteColorsEnum.BLUE].shade30,
  commentReactionHoverBackground: darken(0.05, palette[PaletteColorsEnum.BLUE].shade30),

  commentHighlightedBackground: palette[PaletteColorsEnum.BLUE].shade20,
  highlightedBackground: palette[PaletteColorsEnum.BLUE].shade20,
  highlightedHoverBackground: darken(0.05, palette[PaletteColorsEnum.BLUE].shade20),

  attachmentButton: greys.shade50,
  attachmentButtonHover: palette[PaletteColorsEnum.BLUE].shade40,
  attachmentBorder: alphas.black40,
};

export type MessageSemanticStyles = typeof lightMessageSemanticStyles;

export const darkMessageSemanticStyles: MessageSemanticStyles = {
  background: greysDark.shade40,
  border: alphas.white20,
  header: greysDark.shade90,
  commentText: greysDark.shade90,
  readCommentBackground: greysDark.shade20,

  commentReactionBackground: paletteDark[PaletteColorsEnum.BLUE].shade20,
  commentReactionHoverBackground: paletteDark[PaletteColorsEnum.BLUE].shade30,

  commentHighlightedBackground: paletteDark[PaletteColorsEnum.BLUE].shade20,
  highlightedBackground: paletteDark[PaletteColorsEnum.BLUE].shade20,
  highlightedHoverBackground: paletteDark[PaletteColorsEnum.BLUE].shade30,

  attachmentButton: greysDark.shade60,
  attachmentButtonHover: greysDark.shade70,
  attachmentBorder: greysDark.shade50,
};
