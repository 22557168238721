import {isPlainObject, isString, isUndefined} from 'lodash';
import log from 'loglevel';

import {isDevelopment, isProduction} from '../../helpers/environment/envHelpers';
import {datadogLogger} from './datadogLogger';
import {isDatadogStatus, LogLevelsEnum, LogMethod} from './loggerCommon';

/*
 * Constants.
 */

const isDatadogEnabledInDevelopment = false;

const shouldEnableDevLogs = isDevelopment() && !isDatadogEnabledInDevelopment;
export const defaultLogLevel = shouldEnableDevLogs ? LogLevelsEnum.DEBUG : LogLevelsEnum.INFO;

/*
 * Types.
 */

export interface DefaultLogger extends Logger {
  getLogger(name: string): Logger;
  getLoggers(): {[name: string]: Logger};
}

export interface Logger {
  debug: LogMethod;
  info: LogMethod;
  warn: LogMethod;
  error: LogMethod;

  setDefaultLevel(level: LogLevelsEnum): void;
  setLevel(level: LogLevelsEnum, persist?: boolean): void;
}

export interface LoggerGlobalContext {
  region?: string;
  cell?: string;
  companyId: number;
  userId: number;
}

/*
 * State.
 */

let isDatadogInitialized = false;
// Simple workaround a race condition that can occur if we call
// `registerDatadogContext` before datadog is initialized.
// Seems to mainly impact mobile as we boot offline which is much faster.
let onDatadogInitialized: (() => void) | null = null;

/*
 * Setup.
 */

const originalMethodFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName) => {
  const originalMethod = originalMethodFactory(methodName, logLevel, loggerName);

  if (!isDatadogStatus(methodName)) {
    return originalMethod;
  }

  return (...args) => {
    originalMethod(...args);
    if (!isDatadogInitialized) {
      return;
    }

    const message = args.shift();
    if (!isString(message)) {
      return;
    }

    const context = args.shift() || {};
    if (!isPlainObject(context)) {
      return;
    }

    datadogLogger.log(message, context, methodName, loggerName);
  };
};
log.setDefaultLevel(defaultLogLevel);

/*
 * API.
 */

// Export the default loggers.
export const logger: DefaultLogger = log;

interface DatadogLoggerSetupParams {
  clientTokenDev: string;
  clientTokenProd: string;
}

export function setupDesktopDatadogLogger({clientTokenDev, clientTokenProd}: DatadogLoggerSetupParams) {
  if (isDatadogInitialized) {
    return;
  }

  const isDatadogEnabled = isDevelopment() ? isDatadogEnabledInDevelopment : true;
  if (!isDatadogEnabled) {
    return;
  }

  datadogLogger.initDesktop({
    clientToken: isProduction() ? clientTokenProd : clientTokenDev,
    // Because we have Bugsnag for that.
    forwardErrorsToLogs: false,
  });

  datadogLogger.setClientAttributes();

  isDatadogInitialized = true;
  onDatadogInitialized?.();
  onDatadogInitialized = null;
}

export async function setupMobileDatadogLoggerAsync({
  clientTokenDev,
  clientTokenProd,
}: DatadogLoggerSetupParams) {
  if (isDatadogInitialized) {
    return;
  }

  const isDatadogEnabled = isDevelopment() ? isDatadogEnabledInDevelopment : true;
  if (!isDatadogEnabled) {
    return;
  }

  await datadogLogger.initMobileAsync({
    clientToken: isProduction() ? clientTokenProd : clientTokenDev,
    // Because we have Bugsnag for that.
    forwardErrorsToLogs: false,
  });

  datadogLogger.setClientAttributes();

  isDatadogInitialized = true;
  onDatadogInitialized?.();
  onDatadogInitialized = null;
}

export function registerDatadogContext(context: LoggerGlobalContext) {
  const {region, cell, companyId, userId} = context;
  const setup = () => {
    if (!isUndefined(region)) {
      datadogLogger.addLoggerGlobalContext('region', region);
    }

    if (!isUndefined(cell)) {
      datadogLogger.addLoggerGlobalContext('cell', cell);
    }

    datadogLogger.addLoggerGlobalContext('company_id', companyId);
    datadogLogger.addLoggerGlobalContext('user_id', userId);
  };

  if (!isDatadogInitialized) {
    // Wait for datadog to be fully initialized.
    onDatadogInitialized = setup;
    return;
  }

  setup();
}
