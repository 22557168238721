import React, {forwardRef, ReactNode, Ref} from 'react';
import styled, {css, DefaultTheme} from 'styled-components';

import {KeyboardActionsEnum} from '../../../../core/src/helpers/browser/keyboardHelpers';
import {InteractionComponentProps} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {Renderer} from '../../../../core/src/helpers/react/reactHelpers';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {UserThemeConsumer} from '../../theme/userThemeConsumer';
import {Icon, IconName} from '../icon/icon';
import {KeyboardShortcuts} from '../keyboard/keyboardShortcuts';
import {TooltipCoordinator, TooltipCoordinatorProps} from '../tooltips/tooltipCoordinator';
import {TooltipShortcutFormatter} from '../tooltips/tooltipShortcutFormatter';
import {VisuallyHidden} from '../visuallyHidden/visuallyHidden';
import {ChromelessButton, ChromelessButtonProps} from './chromelessButton';

/*
 * Props.
 */

export interface IconButtonProps
  extends ChromelessButtonProps,
    InteractionComponentProps,
    Pick<TooltipCoordinatorProps, 'placement'> {
  /** The icon to display. */
  iconName: IconName;
  /** The primary icon color. */
  color?: string;
  /** The active icon color. */
  activeColor?: string;
  /** The hover icon color. */
  hoverColor?: string;
  /** The hover+active icon color. */
  hoverActiveColor?: string;
  /** Optional background color */
  backgroundColor?: string;
  /** The background color on hover. */
  backgroundHoverColor?: string;
  /** Label to use for accessibility and in the tooltip. */
  label: ReactNode;
  /** Margin/offset for the label */
  labelMargin?: number;
  /** Keyboard shortcut for this button. */
  keyboardAction?: KeyboardActionsEnum;
  /** Render a tooltip under the button. */
  renderTooltip?: Renderer;
  /** Render the tooltip as multiline. */
  isTooltipMultiline?: boolean;
  /** Whether the tooltip is disabled. This enables adding a label but not adding a tooltip. */
  isTooltipDisabled?: boolean;
  /** Creates a rounded background border */
  isRounded?: boolean;
  /** The size variant to render. */
  size?: VisualSizesEnum;
  /** Custom icon size. */
  iconSize?: number;
  isIconInline?: boolean;
}

/*
 * Style.
 */

export const iconButtonPaddingPx = 5;

interface WrapperStyleProps {
  $backgroundColor?: string;
  $backgroundHoverColor?: string;
  $isRounded?: boolean;
  $isDisabled?: boolean;
  $hasHover?: boolean;
  $size?: VisualSizesEnum;
}
const StyledWrapperButton = styled(ChromelessButton)<WrapperStyleProps>`
  padding: ${iconButtonPaddingPx}px;
  ${(p) => maybeAddBackgroundColor(p)};
  ${(p) => addBorderRadius(p)};
  ${(p) => maybeAddDisabledStyle(p)};
  ${(p) => maybeAddHoverStyle(p)};
`;

function maybeAddBackgroundColor(props: WrapperStyleProps) {
  if (!props.$backgroundColor) {
    return undefined;
  }

  return css`
    background-color: ${props.$backgroundColor};
  `;
}

function addBorderRadius(props: WrapperStyleProps) {
  if (props.$isRounded) {
    return css`
      border-radius: 50%;
    `;
  }

  return css`
    border-radius: ${props.$size === VisualSizesEnum.SMALL ? '6px' : '8px'};
  `;
}

function maybeAddDisabledStyle(props: WrapperStyleProps) {
  if (!props.$isDisabled) {
    return undefined;
  }

  return css`
    background-color: ${(p) => p.theme.greys.shade30};
  `;
}

function maybeAddHoverStyle(props: WrapperStyleProps) {
  if (props.$isDisabled) {
    return undefined;
  }

  // Permanent hover state, set the color.
  if (props.$hasHover) {
    return css`
      background-color: ${(p) => props.$backgroundHoverColor || p.theme.alphas.gray10};
    `;
  }

  // Otherwise, normal hover logic.
  return css`
    &:hover {
      background-color: ${(p) => props.$backgroundHoverColor || p.theme.alphas.gray10};
    }
  `;
}

const StyledIcon = styled(Icon)<WrapperStyleProps>`
  ${(p) =>
    p.$isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

/*
 * Component.
 */

// TODO: Add tooltip.
export const IconButton = forwardRef<HTMLDivElement, IconButtonProps>((props, ref) => {
  // If no keyboard action was specified, just render the button.
  const {keyboardAction, onClick, isDisabled} = props;
  if (!keyboardAction || !onClick || isDisabled) {
    return renderButton(props, ref);
  }

  // Otherwise, handle the keyboard shortcut.
  const keyHandlers = {[keyboardAction]: onClick};
  return <KeyboardShortcuts handlers={keyHandlers}>{renderButton(props, ref)}</KeyboardShortcuts>;
});

function renderButton(props: IconButtonProps, ref?: Ref<HTMLDivElement>) {
  return (
    <UserThemeConsumer>
      {(theme) => (
        <TooltipCoordinator
          render={() => renderButtonTooltip(props)}
          isMultiline={props.isTooltipMultiline}
          placement={props.placement}
          customMargin={props.labelMargin}
        >
          <StyledWrapperButton
            $backgroundColor={props.backgroundColor}
            $backgroundHoverColor={props.backgroundHoverColor}
            $isRounded={props.isRounded}
            $isDisabled={props.isDisabled}
            $hasHover={props.hasHover}
            $size={props.size}
            ref={ref}
            tabIndex={props.tabIndex}
            className={props.className}
            hasHover={props.hasHover}
            isActive={props.isActive}
            isDisabled={props.isDisabled}
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
            shouldPreventMouseDown={props.shouldPreventMouseDown}
            isOpen={props.isOpen}
            interactionId={props.interactionId}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onMouseDown={props.onMouseDown}
          >
            <StyledIcon
              name={props.iconName}
              color={getColor(props, theme)}
              hoverColor={getHoverColor(props, theme)}
              hasHover={props.hasHover}
              size={props.iconSize}
              $isDisabled={props.isDisabled}
              isInline={props.isIconInline}
            />
            <VisuallyHidden>{props.label}</VisuallyHidden>
          </StyledWrapperButton>
        </TooltipCoordinator>
      )}
    </UserThemeConsumer>
  );
}

function renderButtonTooltip(props: IconButtonProps) {
  const {hasHover, renderTooltip, label, keyboardAction, isTooltipDisabled} = props;

  // If the button is open or tooltip disabled, hide the tooltip.
  if (hasHover || isTooltipDisabled) {
    return null;
  }

  // The render method takes priority.
  if (renderTooltip) {
    return renderTooltip();
  }

  // If we have a keyboard action, add the shortcut to the tooltip.
  if (label && keyboardAction) {
    return (
      <>
        {label} <TooltipShortcutFormatter action={keyboardAction} />
      </>
    );
  }

  // Otherwise, just render the description, if any.
  return label || null;
}

function getColor(props: IconButtonProps, theme: DefaultTheme) {
  // Disabled state.
  if (props.isDisabled) {
    return theme.greys.shade60;
  }

  // Active state.
  if (props.isActive) {
    return props.activeColor || theme.palette.blue.shade40;
  }

  // Default state.
  return props.color || theme.greys.shade60;
}

function getHoverColor(props: IconButtonProps, theme: DefaultTheme) {
  // Disabled state.
  if (props.isDisabled) {
    return theme.greys.shade60;
  }

  // Active state.
  if (props.isActive) {
    return props.hoverActiveColor || theme.palette.blue.shade50;
  }

  // Default state.
  return props.hoverColor || theme.greys.shade70;
}
