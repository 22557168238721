export type SeparatorSemanticStyles = {
  border: string;
};

export const lightSeparatorSemanticStyles: SeparatorSemanticStyles = {
  border: '#e6e6e6',
};

export const darkSeparatorSemanticStyles: SeparatorSemanticStyles = {
  border: '#e6e6e6', // TODO: Maybe this should be a different color in dark mode?
};
