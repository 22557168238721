import React from 'react';
import styled, {css} from 'styled-components';

import {greys} from '../../../../libs/shared/ui/src/styles/colorStyles';
import {fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {CheckmarkCircle} from '../common/icon/checkmarkCircle';
import {CrossCircle} from '../common/icon/crossCircle';
import {Helpfilled} from '../common/icon/helpfilled';
import {responseToButtonText} from './calendarInviteHelpers';
import {
  CalendarButtonContainerProps,
  CalendarButtonProps,
  CalendarColorsEnum,
  CalendarInviteResponsesEnum,
} from './calendarType';

/*
 * Style.
 */

const StyledResponseButtonDiv = styled.div<CalendarButtonProps>`
  height: 30px;
  border-radius: 100px;
  border: 1px solid ${greys.shade30};
  margin-right: 8px;
  padding: 7px 14px;
  font-weight: ${fontWeights.semibold};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => !props.selected && greys.shade30};
  }
  ${(props) => responseButtonStyle(props)};
`;

const StyledIconSpan = styled.span<CalendarButtonProps>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  fill: ${(props) => responseIconFillColor(props)};
  background-color: ${(props) => responseIconBackgroundColor(props)};
`;

const responseButtonStyle = (props: CalendarButtonProps) => {
  const {responseOption, selected} = props;
  if (responseOption === CalendarInviteResponsesEnum.ACCEPTED && selected) {
    return css`
      color: ${greys.white};
      background-color: ${CalendarColorsEnum.GREEN};
    `;
  }
  if (responseOption === CalendarInviteResponsesEnum.TENTATIVE && selected) {
    return css`
      color: ${greys.white};
      background-color: ${CalendarColorsEnum.YELLOW};
    `;
  }
  if (responseOption === CalendarInviteResponsesEnum.DECLINED && selected) {
    return css`
      color: ${greys.white};
      background-color: ${CalendarColorsEnum.MAGENTA};
    `;
  }
  return css`
    color: #001b38;
    background-color: ${greys.white};
  `;
};

const responseIconBackgroundColor = (props: CalendarButtonProps) => {
  const {responseOption, selected} = props;
  if (!selected) {
    return greys.white;
  }
  if (responseOption === CalendarInviteResponsesEnum.ACCEPTED) {
    return CalendarColorsEnum.GREEN;
  }
  if (responseOption === CalendarInviteResponsesEnum.TENTATIVE) {
    return CalendarColorsEnum.YELLOW;
  }
  if (responseOption === CalendarInviteResponsesEnum.DECLINED) {
    return CalendarColorsEnum.MAGENTA;
  }
  return greys.white;
};

const responseIconFillColor = (props: CalendarButtonProps) => {
  const {responseOption, selected} = props;
  if (selected) {
    return greys.white;
  }
  if (responseOption === CalendarInviteResponsesEnum.ACCEPTED) {
    return CalendarColorsEnum.GREEN;
  }
  if (responseOption === CalendarInviteResponsesEnum.TENTATIVE) {
    return CalendarColorsEnum.YELLOW;
  }
  if (responseOption === CalendarInviteResponsesEnum.DECLINED) {
    return CalendarColorsEnum.MAGENTA;
  }
  return greys.white;
};

const getIcon = (responseOption: CalendarInviteResponsesEnum) => {
  if (responseOption === CalendarInviteResponsesEnum.ACCEPTED) {
    return <CheckmarkCircle />;
  }
  if (responseOption === CalendarInviteResponsesEnum.TENTATIVE) {
    return <Helpfilled />;
  }
  if (responseOption === CalendarInviteResponsesEnum.DECLINED) {
    return <CrossCircle />;
  }
  return null;
};

/*
 * Component.
 */

export const ResponseButton = (props: CalendarButtonContainerProps) => {
  const {responseOption, selected, updateResponse} = props;
  return (
    <StyledResponseButtonDiv
      onClick={() => updateResponse(responseOption)}
      responseOption={responseOption}
      selected={selected}
    >
      <StyledIconSpan responseOption={responseOption} selected={selected}>
        {getIcon(responseOption)}
      </StyledIconSpan>
      {responseToButtonText[responseOption]}
    </StyledResponseButtonDiv>
  );
};
