import {chain, first, isString, last, memoize} from 'lodash';

import {isSafari} from '../environment/platformHelpers';
import {PredicateOf} from '../react/reactHelpers';
import {sanitizeCssUrl, stringIsCssUrlString} from '../utilities/stringHelpers';
import {buildFrontWindow, FrontWindow, globalDocument, globalWindow} from './browserHelpers';

const ancestorDepthLimit = 100;

/**
 * Find an ancestor that matches the specified predicate.
 * @param element The element to start from.
 * @param highestAncestor The highest-level element to search in.
 * @param predicate The predicate to test if the element is a match.
 */
export function findAncestor(element: Element, highestAncestor: Element, predicate: PredicateOf<Element>) {
  let count = 0;
  let currentElement: Element | null = element;

  while (currentElement && count < ancestorDepthLimit) {
    // If we've reached the highest ancestor, stop here.
    if (currentElement === highestAncestor) {
      return null;
    }

    // Check if the current node matches the predicate.
    if (predicate(currentElement)) {
      return currentElement;
    }

    // Move up one level.
    currentElement = currentElement.parentElement;
    count++;
  }

  return null;
}

/**
 * Find an ancestor with the specified tag name.
 * @param element The element to start from.
 * @param highestAncestor The highest-level element to search in.
 * @param tagName The tag name to find.
 */
export function findAncestorByTag<K extends keyof HTMLElementTagNameMap>(
  element: Element,
  highestAncestor: Element,
  tagName: K,
): HTMLElementTagNameMap[K];
export function findAncestorByTag(element: Element, highestAncestor: Element, tagName: string): HTMLElement;
export function findAncestorByTag(element: Element, highestAncestor: Element, tagName: string) {
  const normalizedTagName = tagName.toUpperCase();
  return findAncestor(element, highestAncestor, (n) => n.tagName === normalizedTagName);
}

/**
 * Find an ancestor with the specified tag name.
 * @param element The element to start from.
 * @param highestAncestor The highest-level element to search in.
 * @param tagName The tag name to find.
 */
export function findAncestorByTags<K extends keyof HTMLElementTagNameMap>(
  element: Element,
  highestAncestor: Element,
  tagNames: ReadonlyArray<K>,
): HTMLElementTagNameMap[K];
export function findAncestorByTags(
  element: Element,
  highestAncestor: Element,
  tagNames: ReadonlyArray<string>,
): HTMLElement;
export function findAncestorByTags(
  element: Element,
  highestAncestor: Element,
  tagNames: ReadonlyArray<string>,
) {
  const normalizedTagNames = tagNames.map((tagName) => tagName.toUpperCase());
  return findAncestor(element, highestAncestor, (n) => normalizedTagNames.includes(n.tagName));
}

interface Point {
  left: number;
  top: number;
}

export function findRelativeOffset(element: HTMLElement, ancestor: HTMLElement): Point {
  const {offsetParent} = element;

  // If we've reached the end of the recursion, return our offset.
  if (!isHtmlElement(offsetParent) || offsetParent === ancestor) {
    return {
      left: element.offsetLeft,
      top: element.offsetTop,
    };
  }

  // Otherwise, compute the parent's offset, and sum both.
  const parentOffset = findRelativeOffset(offsetParent, ancestor);
  return {
    left: element.offsetLeft + parentOffset.left,
    top: element.offsetTop + parentOffset.top,
  };
}

export function runInInvisibleContainer<T>(
  containerDocument: Document,
  worker: (element: HTMLElement) => T,
): T {
  // Create the container and element, and add them to the DOM.
  const {container, element} = createInvisibleContainer(containerDocument);
  const {body} = containerDocument;
  body.appendChild(container);

  // Run the worker.
  try {
    const result = worker(element);
    body.removeChild(container);
    return result;
  } catch (error) {
    body.removeChild(container);
    throw error;
  }
}

export function findOverflowingScrollParent(element: Element | null): Element | null {
  if (!element) {
    return null;
  }

  if (element.scrollHeight > element.clientHeight) {
    return element;
  }

  return findOverflowingScrollParent(element.parentElement);
}

export async function runInInvisibleContainerAsync<T>(
  worker: (element: HTMLElement) => Promise<T>,
): Promise<T> {
  // Create the container and element, and add them to the DOM.
  const {container, element} = createInvisibleContainer(globalDocument);
  const {body} = globalDocument;
  body.appendChild(container);

  // Run the worker.
  try {
    const result = await worker(element);
    body.removeChild(container);
    return result;
  } catch (error) {
    body.removeChild(container);
    throw error;
  }
}

function createInvisibleContainer(containerDocument: Document) {
  // Create the container.
  const container = containerDocument.createElement('div');
  container.setAttribute('aria-hidden', 'true');
  container.setAttribute(
    'style',
    `
    height: 1px;
    width: 1px;
    position: absolute;
    left: -100000px;
    opacity: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    `,
  );

  // Create the element that we'll provide to the worker.
  const element = containerDocument.createElement('div');
  container.appendChild(element);

  return {container, element};
}

function runInContainerInIframe(
  containerWindow: FrontWindow,
  worker: (newContainerWindow: FrontWindow, span: HTMLSpanElement) => Promise<void>,
) {
  const iframe = containerWindow.document.createElement('iframe');
  iframe.setAttribute('sandbox', 'allow-same-origin');
  containerWindow.document.body.appendChild(iframe);
  try {
    if (!iframe.contentWindow) {
      return Promise.reject();
    }

    return runInContainerInWindow(buildFrontWindow(iframe.contentWindow), worker);
  } finally {
    containerWindow.document.body.removeChild(iframe);
  }
}

function runInContainerInWindow(
  containerWindow: FrontWindow,
  worker: (newContainerWindow: FrontWindow, span: HTMLSpanElement) => Promise<void>,
) {
  const span = containerWindow.document.createElement('span');
  containerWindow.document.body.appendChild(span);

  try {
    return worker(containerWindow, span);
  } finally {
    containerWindow.document.body.removeChild(span);
  }
}

function runInContainer(
  options: {inIframe: boolean; containerWindow: FrontWindow},
  worker: (newContainerWindow: FrontWindow, span: HTMLSpanElement) => Promise<void>,
) {
  return options.inIframe
    ? runInContainerInIframe(options.containerWindow, worker)
    : runInContainerInWindow(options.containerWindow, worker);
}

export function selectNodeContents(node: Node) {
  const document = node.ownerDocument;
  const window = document && document.defaultView;

  if (!document || !window) {
    return;
  }

  const selection = window.getSelection();

  if (!selection) {
    return;
  }

  const range = document.createRange();
  range.selectNodeContents(node);
  selection.removeAllRanges();
  selection.addRange(range);
}

/** Copies the text to the clipboard. Should be called from a permitted user event such as a click or key press. */
export async function copyToClipboardAsync(
  containerWindow: FrontWindow,
  textOrPromise: string | Promise<string>,
): Promise<void> {
  // Newer Safari
  if (window.ClipboardItem && isSafari()) {
    return navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': (isString(textOrPromise) ? Promise.resolve(textOrPromise) : textOrPromise).then(
          (text) => new Blob([text], {type: 'text/plain'}),
        ),
      }),
    ]);
  }

  // Firefox/Chrome and older Safari
  const text = isString(textOrPromise) ? textOrPromise : await textOrPromise;

  // Using an iframe around the dummy element is required for the copy
  // to work on Safari, but it doesn't work on Chrome. So we try it both
  // ways.
  const parameterizedCopyWithExecCommand = (options: {inIframe: boolean}) =>
    runInContainer(
      {inIframe: options.inIframe, containerWindow},
      (newContainerWindow: FrontWindow, span: HTMLSpanElement) => {
        span.setAttribute('style', 'user-select: auto');
        span.textContent = text;

        const selection = newContainerWindow.getSelection();
        if (!selection) {
          return Promise.reject();
        }

        try {
          const range = newContainerWindow.document.createRange();
          selection.removeAllRanges();
          range.selectNode(span);
          selection.addRange(range);

          return newContainerWindow.document.execCommand('copy') ? Promise.resolve() : Promise.reject();
        } finally {
          selection.removeAllRanges();
        }
      },
    );

  const copyWithExecCommand = () =>
    parameterizedCopyWithExecCommand({inIframe: false}).catch(() =>
      parameterizedCopyWithExecCommand({inIframe: true}),
    );

  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text).catch(copyWithExecCommand);
  }

  return copyWithExecCommand();
}

export function copySelectionToClipboard(containerWindow: FrontWindow) {
  containerWindow.document.execCommand('copy');
}

export type InputLike = (HTMLElement & {isContentEditable: true}) | HTMLInputElement | HTMLTextAreaElement;

/** Check whether the specified HTML element is editable. */
export function isInputLike(element: Element | EventTarget | null): element is InputLike {
  if (!isHtmlElement(element)) {
    return false;
  }

  return element.isContentEditable || element.tagName === 'INPUT' || element.tagName === 'TEXTAREA';
}

/** Check whether the given event target is a node. */
export function isNode(target: EventTarget | null): target is Node {
  // Use duck typing to make this work for an object from any frame/window or from a DOMParser result.
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/instanceof#instanceof_and_multiple_contexts_e.g._frames_or_windows
  return target !== null && 'nodeType' in target && 'appendChild' in target;
}

export function isTextNode(node: Node | null): node is Text {
  return node !== null && node.nodeType === Node.TEXT_NODE;
}

/** Check whether the given DOM node is an element. */
export function isElement(target: EventTarget | null): target is Element {
  return isNode(target) && target.nodeType === Node.ELEMENT_NODE;
}

export function isElementWithTagName<K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  node: Node | EventTarget | null,
): node is HTMLElementTagNameMap[K] {
  return isElement(node) && node.tagName.toLowerCase() === tagName;
}

/** Check whether the specified element is an instance of HTMLElement in its frame. */
export function isHtmlElement(element: Element | EventTarget | null): element is HTMLElement {
  // Use duck typing to make this work for an object from any frame/window or from a DOMParser result.
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/instanceof#instanceof_and_multiple_contexts_e.g._frames_or_windows
  return isHtmlOrSvgElement(element) && 'lang' in element && 'offsetLeft' in element;
}

/** Check whether the specified element is an instance of HTMLInputElement in its frame. */
export function isHtmlInputElement(element: Element | EventTarget | null): element is HTMLInputElement {
  return isHtmlElement(element) && element.tagName === 'INPUT';
}

/** Check whether the specified element is an instance of HTMLAnchorElement */
export function isHtmlAnchorElement(element: Element | EventTarget | null): element is HTMLAnchorElement {
  return isHtmlElement(element) && element.tagName === 'A';
}

export function isHtmlIframeElement(element: Element | EventTarget | null): element is HTMLIFrameElement {
  return isHtmlElement(element) && element.tagName === 'IFRAME';
}

/** Check whether the specified element is an instance of HTMLElement or SVGElement in its frame. */
export function isHtmlOrSvgElement(
  element: Element | EventTarget | null,
): element is HTMLOrSVGElement & Element {
  // Use duck typing to make this work for an object from any frame/window or from a DOMParser result.
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/instanceof#instanceof_and_multiple_contexts_e.g._frames_or_windows
  return isElement(element) && 'tabIndex' in element && 'blur' in element;
}

export function isTableCellElement(node: Node | null): node is HTMLTableCellElement {
  return isElement(node) && (node.tagName === 'TH' || node.tagName === 'TD');
}

export function isTableElement(node: Node | null): node is HTMLTableElement {
  return isElement(node) && node.tagName === 'TABLE';
}

export function isTableRowElement(node: Node | null): node is HTMLTableRowElement {
  return isElement(node) && node.tagName === 'TR';
}

/** Gets the window for the given object. */
export function getWindow(object: Element | EventTarget | null): FrontWindow | undefined {
  if (!object || !('ownerDocument' in object)) {
    return undefined;
  }

  // This is null on a Node if it is the document itself, but it shouldn't be null on an Element.
  const element = object;
  const {ownerDocument} = element;
  if (!ownerDocument) {
    return undefined;
  }

  // This is null if the document does not have a "browsing context", such as a document from DOMParser.
  const {defaultView} = ownerDocument;
  if (!defaultView) {
    return undefined;
  }

  return buildFrontWindow(defaultView);
}

/** Run code with the window for the given DOM element, if it could be found. */
export function runOnWindow<T>(
  object: Element | EventTarget | null,
  worker: (currentWindow: FrontWindow) => T,
) {
  const currentWindow = getWindow(object);
  if (!currentWindow) {
    throw new Error('A window could not be found for this object');
  }

  return worker(currentWindow);
}

/** Check whether the specified element has focus when its owning document has focus. */
export function hasFocus(element: Element) {
  return Boolean(element.ownerDocument && element.ownerDocument.activeElement === element);
}

/** Check whether the specified element contains focus when its owning document has focus. */
export function containsFocus(element: Element) {
  return Boolean(element.ownerDocument && element.contains(element.ownerDocument.activeElement));
}

/** Check whether the specified element contains the text selection. */
export function containsSelection(object: Element | EventTarget | null) {
  return runOnWindow(object, (currentWindow) => {
    const selection = currentWindow.getSelection();
    if (!selection) {
      return false;
    }

    return isHtmlElement(object) && selection.containsNode(object, true) && !selection.isCollapsed;
  });
}

/*
 * Ranges.
 */

function findTextNodes(node: Node): ReadonlyArray<Text> {
  // If this is a text node, no need to go deeper.
  if (isTextNode(node)) {
    return [node];
  }

  // Otherwise, iterate on the children.
  return chain(node.childNodes)
    .map((n) => findTextNodes(n))
    .flatten()
    .value();
}

export function buildRange(node: Node, startOffset: number, endOffset: number) {
  const {ownerDocument} = node;
  if (!ownerDocument) {
    throw new Error('The provided node is not part of a document.');
  }

  const range = ownerDocument.createRange();
  range.selectNodeContents(node);

  const textNodes = findTextNodes(node);
  let characterCount = 0;

  textNodes.forEach((textNode) => {
    const endCharacterCount = characterCount + textNode.length;

    // Check if we can set the start.
    if (startOffset >= characterCount && startOffset < endCharacterCount) {
      range.setStart(textNode, startOffset - characterCount);
    }

    // Check if we can set the end.
    if (endOffset >= characterCount && endOffset < endCharacterCount) {
      range.setEnd(textNode, endOffset - characterCount);
    }

    characterCount = endCharacterCount;
  });

  return range;
}

/* eslint no-param-reassign: 0 */
export function stripBackgroundAndCSSImages(currentNode: Element) {
  // If this is a <style> element, make sure that none of its child nodes (there should be only one) have
  // CSS "url(...)" values stripped out.
  if (currentNode.tagName && currentNode.tagName.toLowerCase() === 'style') {
    currentNode.childNodes.forEach((node) => {
      if (node && node.textContent) {
        node.textContent = sanitizeCssUrl(node.textContent);
      }
    });
  }

  // Regardless of the current node's type, if it has a "style" attribute, make sure all the CSS properties
  // with "url(...)" are sanitized.
  const styleAttribute = currentNode.attributes ? currentNode.attributes.getNamedItem('style') : undefined;

  if (styleAttribute && stringIsCssUrlString(styleAttribute.value)) {
    styleAttribute.value = sanitizeCssUrl(styleAttribute.value);
  }

  // Regardless of the current node's type, if it has a (now deprecated) "background" attribute, make sure it
  // is sanitized by clearing its value.
  const backgroundAttribute = currentNode.attributes
    ? currentNode.attributes.getNamedItem('background')
    : undefined;

  if (backgroundAttribute) {
    backgroundAttribute.value = '';
  }
}

export const computeScrollbarWidth = memoize(() => {
  const testNode = globalDocument.createElement('div');
  testNode.setAttribute(
    'style',
    `width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;`,
  );

  // Appending a test element with scroll overflow and computing the difference between the actual width and the "perceived" width.
  globalDocument.body.appendChild(testNode);
  const diff = testNode.offsetWidth - testNode.clientWidth;
  testNode.remove();

  return diff;
});

export function computeHasVisibleScrollbars() {
  // If the difference is greater than zero, then a scrollbar is shown by the OS
  return Boolean(computeScrollbarWidth());
}

export function getWindowForElement(element: Element) {
  const document = element.ownerDocument;
  const window = document && document.defaultView;
  return window;
}

// This is essentially (elementHeight - topAndBottomPadding) / lineHeight
export function getNumberOfLinesOfTextForElement(element: HTMLElement): number | null {
  const document: Document | null = element.ownerDocument;
  if (!document || !document.defaultView) {
    return null;
  }

  const computedStyle = document.defaultView.getComputedStyle(element, null);
  const lineHeight = getPropertyAsNumber(computedStyle, 'line-height');
  if (!lineHeight) {
    return null;
  }

  const paddingTop = getPropertyAsNumber(computedStyle, 'padding-top') || 0;
  const paddingBottom = getPropertyAsNumber(computedStyle, 'padding-bottom') || 0;

  const elementHeight = element.offsetHeight;
  const contentHeight = elementHeight - paddingTop - paddingBottom;
  const linesOfText = Math.round(contentHeight / lineHeight);

  return linesOfText;
}

export function getWidthOfElement(node: Element) {
  const elementWindow = getWindow(node) || globalWindow;
  const styles = elementWindow.getComputedStyle(node);
  const width = getPropertyAsNumber(styles, 'width') || 0;

  return width;
}

export function getWidthWithMarginOfElement(node: Element) {
  const elementWindow = getWindow(node) || globalWindow;
  const styles = elementWindow.getComputedStyle(node);
  const width = getPropertyAsNumber(styles, 'width') || 0;
  const marginLeft = getPropertyAsNumber(styles, 'margin-left') || 0;
  const marginRight = getPropertyAsNumber(styles, 'margin-right') || 0;

  return width + marginLeft + marginRight;
}

function getPropertyAsNumber(computedStyle: CSSStyleDeclaration, propertyName: string): number | null {
  const valueAsString: string = computedStyle.getPropertyValue(propertyName);
  const valueMatch = valueAsString.match(/(.*)px$/); // ComputedStyle calculates everything in pixels. e.g. '12.3px'.
  if (!valueMatch) {
    return null;
  }

  const value = Number(valueMatch[1]);
  return isNaN(value) ? null : value;
}

const supportCssFeature = memoize((property: string, value: string) => CSS.supports(property, value));

export const computeSupportsCssAspectRatio = () => supportCssFeature('aspect-ratio', '1 / 1');

/** Replaces the given node with its children. */
export function unwrapNode(node: Node) {
  const {parentNode} = node;
  if (!parentNode) {
    return;
  }

  node.childNodes.forEach((childNode) => {
    parentNode.insertBefore(childNode, node);
  });
  parentNode.removeChild(node);
}

export function getFilesFromInput(options: {accept?: string; multiple?: boolean}) {
  const {accept, multiple} = options;
  return new Promise<ReadonlyArray<File>>((resolve) => {
    const input = globalDocument.createElement('input');
    input.type = 'file';
    input.accept = accept || '';
    input.multiple = multiple ?? false;

    input.onchange = () => {
      resolve(Array.from(input.files ?? []));
    };

    input.click();
  });
}

export function readFileAsDataURL(file: File) {
  return new Promise<string>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      resolve(event.target?.result?.toString() ?? '');
    };

    fileReader.readAsDataURL(file);
  });
}

// Convenience function to iterate over a DOM tree.
export function forEachElementInTree(node: HTMLElement, callback: (node: HTMLElement) => void) {
  const treeWalker = node.ownerDocument.createTreeWalker(node, NodeFilter.SHOW_ELEMENT);
  let currentNode: Node | null = node;
  while (currentNode) {
    if (isHtmlElement(currentNode)) {
      callback(currentNode);
    }
    currentNode = treeWalker.nextNode();
  }
}

export function isSelectionBackwards(sel: Selection): boolean {
  if (!sel.focusNode || !sel.anchorNode) {
    return false;
  }

  const position = sel.anchorNode?.compareDocumentPosition(sel.focusNode);
  return (!position && sel.anchorOffset > sel.focusOffset) || position === Node.DOCUMENT_POSITION_PRECEDING;
}

export function getRectFromSelection(selection: Selection): DOMRect | null {
  const range = selection.getRangeAt(0);
  const selectionRects = Array.from(selection.getRangeAt(0).getClientRects());
  // Can happen when selection is just a caret on an empty line.
  const isEmpty = selectionRects.length === 0;

  if (isEmpty) {
    const possibleContainer = range.commonAncestorContainer;
    const container = isElement(possibleContainer) ? possibleContainer : possibleContainer.parentElement;
    if (!container) {
      return null;
    }

    return container.getBoundingClientRect();
  }

  const isBackwards = isSelectionBackwards(selection);
  return (isBackwards ? first(selectionRects) : last(selectionRects)) || null;
}

/**
 * Places a <span> element sized according to the given rect, useful to debug DOMRect/selection related logic.
 */
// @ts-expect-error not exported on purpose to avoid mis-use.
function drawRectOnScreenDoNotUseInProductionFacingCode(rect: DOMRect) {
  const rectId = 'mycoolrect';
  let rectElement = globalDocument.querySelector<HTMLSpanElement>(`#${rectId}`);

  if (!rectElement) {
    const el = globalDocument.createElement('span');
    el.id = rectId;
    el.style.position = 'absolute';
    el.style.zIndex = '99999';
    el.style.pointerEvents = 'none';
    globalDocument.body.appendChild(el);
    rectElement = el;
  }

  rectElement.style.top = `${rect.top}px`;
  rectElement.style.left = `${rect.left}px`;
  rectElement.style.width = `${Math.max(rect.width, 20)}px`;
  rectElement.style.height = `${rect.height}px`;
  rectElement.style.backgroundColor = 'rgba(255,0,0,.4)';
}

export function expandRangeToWhitespace(range: Range) {
  const result = range.cloneRange();

  const startText = result.startContainer.textContent;
  const endText = result.endContainer.textContent;

  // Adjust the start position to the beginning of the nearest word
  if (startText) {
    const {startOffset} = result;
    const startMatch = startText.slice(0, startOffset).match(/[^\s]*$/);
    if (startMatch) {
      result.setStart(result.startContainer, startOffset - startMatch[0].length);
    }
  }

  // Adjust the end position to the end of the nearest word
  if (endText) {
    const {endOffset} = result;
    const endMatch = endText.slice(endOffset).match(/^[^\s]*/);
    if (endMatch) {
      result.setEnd(result.endContainer, endOffset + endMatch[0].length);
    }
  }

  return result;
}

export function computeHtmlInRange(range: Range) {
  const fragment = range.cloneContents();
  const container = globalDocument.createElement('div');
  container.appendChild(fragment);

  return container.innerHTML;
}
