import {palette, paletteDark} from '../../styles/colorStyles';

export const lightLegacyPillSemanticStyles = {
  backgroundColor: palette.red.shade20,
  color: palette.red.shade40,
};

export type LegacyPillSemanticStyles = typeof lightLegacyPillSemanticStyles;

export const darkLegacyPillSemanticStyles: LegacyPillSemanticStyles = {
  backgroundColor: paletteDark.red.shade20,
  color: palette.red.shade40,
};
