import {SideObject} from '@floating-ui/react-dom';
import React, {FC, ReactNode, RefObject} from 'react';

import {RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {RepositionPlacement} from '../reposition/repositionHelpers';
import {Tooltip} from './tooltip';
import {TooltipCoordinatorRenderProps} from './tooltipCoordinatorTypes';

export interface TooltipCoordinatorTooltipRendererProps {
  render: RendererOf<TooltipCoordinatorRenderProps> | false | undefined;
  plainTooltip?: ReactNode;
  onRender: (() => void) | undefined;
  placement: RepositionPlacement | undefined;
  isMultiline: boolean | undefined;
  customMargin: number | string | undefined;
  maxWidth: number | undefined;
  minWidth: number | undefined;
  preventOverflowPadding: Partial<SideObject> | undefined;
  persistent: boolean | undefined;
  tooltipColor: string | undefined;
  borderRadius: number | undefined;
  isBoxShadowDisabled: boolean | undefined;
  tooltipZIndex: number | undefined;
  isVisible: boolean;
  hide: () => void;
  anchorRef: RefObject<HTMLElement>;
}

export const TooltipCoordinatorTooltipRenderer: FC<TooltipCoordinatorTooltipRendererProps> = (props) => {
  if (!props.isVisible) {
    return null;
  }

  const tooltipContent =
    props.plainTooltip ??
    (props.render &&
      props.render({
        anchor: props.anchorRef,
        onRequestTooltipClose: () => {
          props.hide();
        },
      }));

  if (!tooltipContent) {
    return null;
  }

  if (props.onRender) {
    props.onRender();
  }

  return (
    <Tooltip
      anchor={props.anchorRef}
      placement={props.placement}
      isMultiline={props.isMultiline}
      customMargin={props.customMargin}
      maxWidth={props.maxWidth}
      minWidth={props.minWidth}
      preventOverflowPadding={props.preventOverflowPadding}
      persistent={props.persistent}
      color={props.tooltipColor}
      borderRadius={props.borderRadius}
      isBoxShadowDisabled={props.isBoxShadowDisabled}
      zIndex={props.tooltipZIndex}
      shouldRemoveWrapper={Boolean(props.plainTooltip)}
    >
      {tooltipContent}
    </Tooltip>
  );
};
