import React, {FC, ReactNode} from 'react';
import styled, {css} from 'styled-components';

import {ellipsis} from '../../styles/mixins';
import {BoxedInputChildProps, BoxedInputChildStyleProps, inputContentStyles} from './boxedInputConstants';

/*
 * Props.
 */

export interface BoxedInputStaticProps extends BoxedInputChildProps {
  value?: ReactNode;
  noMarginLeft?: boolean;
  noMarginRight?: boolean;
  className?: string;
}

/*
 * Style.
 */

const StyledStaticDiv = styled.div<BoxedInputChildStyleProps>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  color: inherit;
  cursor: inherit;
  -webkit-user-select: none;
  user-select: none;

  ${(p) => inputContentStyles[p.$size]};
  ${(p) =>
    p.$noMarginLeft &&
    css`
      margin-left: 0;
    `};
  ${(p) =>
    p.$noMarginRight &&
    css`
      margin-right: 0;
    `};
  ${ellipsis()};
`;

/*
 * Component.
 */

/** Static text content to use within a BoxedInput. */
export const BoxedInputStatic: FC<BoxedInputStaticProps> = (props) => (
  <StyledStaticDiv
    $size={props.size}
    $noMarginLeft={props.noMarginLeft}
    $noMarginRight={props.noMarginRight}
    className={props.className}
  >
    {props.value}
  </StyledStaticDiv>
);
