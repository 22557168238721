import {transparentize} from 'polished';

import {blendColors} from '../../../../core/src/models/helpers/colorHelpers';
import {ButtonVariantsEnum} from '../../components/buttons/buttonConstants';
import {alphas, greys, greysDark, palette, paletteDark} from '../../styles/colorStyles';

export const lightLeftBarSemanticStyles = {
  background: greys.white,
  separator: greys.shade30,
  itemSeparator: greys.shade30,
  header: greys.shade70,
  headerHover: greys.shade80,
  headerMore: greys.shade70,
  headerMoreHover: greys.shade90,

  item: greys.shade80,
  itemBold: greys.shade90,
  itemSelected: greys.shade90,
  icon: greys.shade70,
  iconHover: greys.shade80,
  itemBackgroundHover: alphas.gray10,
  itemBackgroundSelected: alphas.gray20,
  itemPrivateLock: alphas.black50,
  itemAdd: palette.blue.shade40,
  itemAddHover: palette.blue.shade50,
  itemAddDismiss: greys.shade60,
  itemAddDismissHover: greys.shade80,
  itemDot: greys.shade40,
  itemDotHover: greys.shade50,
  itemCheckBox: greys.shade60,

  itemIcon: greys.shade50,
  itemIconHover: greys.shade60,
  itemIconSelected: greys.white,
  itemIconBackground: greys.white,
  itemIconBackgroundHover: alphas.gray10,

  itemMoreIcon: greys.shade70,
  itemMoreIconHover: greys.shade80,
  itemMoreIconSelected: greys.white,
  itemMoreIconBackground: greys.white,
  itemMoreIconBackgroundHover: alphas.gray20,

  p1BannerBackground: palette.pink.shade10,
  p1BannerIcon: palette.pink.shade40,
  p1RarelyViewedBadgeText: greys.white,
  p1RarelyViewedBadgeBackground: palette.pink.shade40,
  p1HelpTipSubtitle: greys.shade80,
  p1HelpTipClose: greys.shade70,
  p1HelpTipCloseHover: greys.shade80,
  p1HelpTipCloseBackgroundHover: alphas.gray10,
  p1BannerLinkVariant: ButtonVariantsEnum.LINK,
};

export type LeftBarSemanticStyles = typeof lightLeftBarSemanticStyles;

export const darkLeftBarSemanticStyles: LeftBarSemanticStyles = {
  background: blendColors(palette.blue.shade40, transparentize(0.15, greys.shade90)),
  separator: blendColors(palette.blue.shade40, transparentize(0.15, greys.shade90)),
  itemSeparator: alphas.white20,
  header: alphas.white50,
  headerHover: alphas.white60,
  headerMore: alphas.white40,
  headerMoreHover: alphas.white60,

  item: alphas.white60,
  itemBold: greys.white,
  itemSelected: greys.white,
  icon: alphas.white40,
  iconHover: alphas.white60,
  itemBackgroundHover: alphas.white10,
  itemBackgroundSelected: alphas.white20,
  itemPrivateLock: alphas.white40,
  itemAdd: alphas.white50,
  itemAddHover: greys.white,
  itemAddDismiss: alphas.white30,
  itemAddDismissHover: alphas.white50,
  itemDot: alphas.white20,
  itemDotHover: alphas.white30,
  itemCheckBox: alphas.white40,

  itemIcon: greys.shade50,
  itemIconHover: greys.shade30,
  itemIconSelected: greys.white,
  itemIconBackground: alphas.white20,
  itemIconBackgroundHover: alphas.white30,

  itemMoreIcon: alphas.white40,
  itemMoreIconHover: alphas.white50,
  itemMoreIconSelected: greys.white,
  itemMoreIconBackground: alphas.white20,
  itemMoreIconBackgroundHover: alphas.white30,

  p1BannerBackground: palette.pink.shade50,
  p1BannerIcon: greys.white,
  p1RarelyViewedBadgeBackground: palette.pink.shade10,
  p1RarelyViewedBadgeText: palette.pink.shade50,
  p1HelpTipSubtitle: transparentize(0.05, greys.white),
  p1HelpTipClose: transparentize(0.2, greys.white),
  p1HelpTipCloseHover: transparentize(0.05, greys.white),
  p1HelpTipCloseBackgroundHover: transparentize(0.85, greys.white),
  p1BannerLinkVariant: ButtonVariantsEnum.DARK_MODE_LINK,
};

export const expandedLeftBarSemanticStyles = {
  background: greysDark.shade20,
  item: greysDark.shade80,
  itemBold: greysDark.shade90,
  p1BannerBackground: paletteDark.pink.shade40,
};
