import React, {FC, PropsWithChildren, ReactNode, useEffect, useRef} from 'react';
import {css, StyleSheetManager as DefaultStyleSheetManager} from 'styled-components';

import {VisualSizesEnum} from './commonStyles';

/*
 * Visual size constants and helpers.
 */

export type VisualSizeConstants<T> = {readonly [K in VisualSizesEnum]: T};

/** Make a map of visual size to any type of constant. */
export function makeSizeConstants<T>(smallVariant: T, ...variants: Array<T>): VisualSizeConstants<T> {
  return {
    [VisualSizesEnum.SMALL]: smallVariant,
    [VisualSizesEnum.MEDIUM]: variants[0] || smallVariant,
    [VisualSizesEnum.LARGE]: variants[1] || variants[0] || smallVariant,
    [VisualSizesEnum.EXTRA_LARGE]: variants[2] || variants[1] || variants[0] || smallVariant,
  };
}

/*
 * Grow to fit available height.
 */

export const growToFitHeightContainerStyle = css`
  display: flex;
  flex-direction: column;
`;

export const growToFitHeightStyle = css`
  flex: 1 0 auto;
`;

/*
 * CSS.
 */

const genericFontFamilies = ['serif', 'sans-serif', 'cursive', 'fantasy', 'monospace'];

export function isGenericFontFamily(family: string): boolean {
  return genericFontFamilies.includes(family.toLowerCase());
}

/*
 * Style Sheet Manager.
 */

export interface StyleSheetManagerProps {
  document: Document;
  children: ReactNode;
}

export const StyleSheetManager: FC<PropsWithChildren<StyleSheetManagerProps>> = (props) => {
  // Create a temporary div that exists for the lifespan of this component that holds style.
  const styleContainer = useRef<HTMLDivElement | null>(null);
  if (!styleContainer.current) {
    styleContainer.current = props.document.createElement('div');
    props.document.body.appendChild(styleContainer.current);
  }

  useEffect(
    () => () => {
      if (styleContainer.current) {
        styleContainer.current.remove();
      }
    },
    [],
  );

  // Render the styles in the style container.
  return (
    <DefaultStyleSheetManager target={styleContainer.current}>{props.children}</DefaultStyleSheetManager>
  );
};
