import React, {FC, PropsWithChildren} from 'react';

import {RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {useUserTheme} from '../../theme/userThemeProvider';
import {Icon} from '../icon/icon';

export interface TooltipCoordinatorAnchorRendererProps {
  isVisible: boolean;
  iconColor: string | undefined;
  iconHoverColor: string | undefined;
  renderTarget: RendererOf<boolean> | undefined;
}

export const TooltipCoordinatorAnchorRenderer: FC<
  PropsWithChildren<TooltipCoordinatorAnchorRendererProps>
> = ({isVisible, iconColor, iconHoverColor, renderTarget, children}) => {
  const defaultIconColor = useDefaultIconColor(isVisible);

  if (renderTarget) {
    return renderTarget(isVisible);
  }

  if (children) {
    return children;
  }

  if (isVisible) {
    return <Icon name="infoFilledSmall" color={iconHoverColor ?? iconColor ?? defaultIconColor} />;
  }

  return <Icon name="infoSmall" color={iconColor ?? defaultIconColor} />;
};

function useDefaultIconColor(isVisible: boolean) {
  const theme = useUserTheme();
  return isVisible ? theme.greys.shade80 : theme.greys.shade70;
}
