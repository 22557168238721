import {palette, paletteDark} from '../../styles/colorStyles';

export const lightNotificationIndicatorSemanticStyles = {
  backgroundColor: palette.red.shade40,
};

export type NotificationIndicatorSemanticStyles = typeof lightNotificationIndicatorSemanticStyles;

export const darkNotificationIndicatorSemanticStyles: NotificationIndicatorSemanticStyles = {
  backgroundColor: paletteDark.red.shade40,
};
