import {autoUpdate, limitShift, shift, SideObject, size, useFloating} from '@floating-ui/react-dom';
import React, {CSSProperties, FC, PropsWithChildren, useMemo} from 'react';
import styled from 'styled-components';

import {useResizeEffect} from '../../hooks/sizeHooks';
import {Anchor, buildReferenceObject, RepositionPlacement, useParsePlacement} from './repositionHelpers';

/*
 * Constants.
 */

const defaultPreventOverflowPadding = 10;

/*
 * Props.
 */

export interface RepositionProps {
  /** Ref to the anchor, or bounds provider. */
  anchor: Anchor;
  /** Position of the element relative to the anchor. */
  placement?: RepositionPlacement;
  preventOverflowPadding?: Partial<SideObject>;
  /** Whether the popover is allowed to leave its anchor. */
  shouldUntether?: boolean;
  /** Whether we should skip the max size modifier. The modifier causes issues
   *  when the height of the dropdown increases since it is constrained. */
  shouldSkipApplyMaxSize?: boolean;
  isInline?: boolean;
}

/*
 * Styles.
 */

const StyledWrapperDiv = styled.div`
  // If a popover overlaps P0, turn off Electron's window dragging to keep buttons clickable on Windows.
  -webkit-app-region: no-drag;
`;

/*
 * Component.
 */

/** Position content next to an existing element. */
export const Reposition: FC<PropsWithChildren<RepositionProps>> = ({
  anchor,
  placement,
  preventOverflowPadding,
  shouldUntether,
  shouldSkipApplyMaxSize,
  isInline,
  children,
}) => {
  const referenceObject = buildReferenceObject(anchor);
  const {parsedPlacement, flipMiddleware, boundary} = useParsePlacement(placement || 'bottom-start', false);
  const padding: Partial<SideObject> = {
    top: preventOverflowPadding?.top ?? defaultPreventOverflowPadding,
    left: preventOverflowPadding?.left ?? defaultPreventOverflowPadding,
    bottom: preventOverflowPadding?.bottom ?? defaultPreventOverflowPadding,
    right: preventOverflowPadding?.right ?? defaultPreventOverflowPadding,
  };

  const {
    refs,
    floatingStyles,
    placement: finalPlacement,
    update,
  } = useFloating({
    placement: parsedPlacement,
    transform: false,
    elements: {
      reference: referenceObject,
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      flipMiddleware,
      shift({
        crossAxis: true,
        limiter: shouldUntether ? undefined : limitShift(),
        padding,
        boundary,
      }),
      !shouldSkipApplyMaxSize &&
        size({
          padding,
          boundary,
          apply: ({availableHeight, availableWidth, elements}) => {
            // eslint-disable-next-line no-param-reassign
            elements.floating.style.maxHeight = `${availableHeight}px`;
            // eslint-disable-next-line no-param-reassign
            elements.floating.style.maxWidth = `${availableWidth}px`;
          },
        }),
    ],
  });

  const styles = useMemo(
    () =>
      ({
        ...floatingStyles,
        display: 'flex',
        zIndex: isInline ? 99 : undefined,
      }) satisfies CSSProperties,
    [floatingStyles, isInline],
  );

  useResizeEffect(refs.floating, () => update());
  return (
    <StyledWrapperDiv ref={refs.setFloating} style={styles} data-placement={finalPlacement}>
      {children}
    </StyledWrapperDiv>
  );
};
