import {en} from 'make-plural';
import React, {FC, ReactNode} from 'react';

import {useEnsureI18nContext} from './i18nContext';
import {replacePluralValueReact} from './plural';
import {MessageReactNode} from './trans';

/*
 * Props.
 */

interface SelectOrdinalProps {
  id: string;
  value: number;
  one: MessageReactNode;
  two: MessageReactNode;
  few: MessageReactNode;
  other: MessageReactNode;
}

/*
 * Component.
 */

export const SelectOrdinal: FC<SelectOrdinalProps> = (props) => {
  useEnsureI18nContext();

  return <>{replacePluralValueReact(props.value, findOrdinalMessage(props))}</>;
};

/*
 * Helpers.
 */

function findOrdinalMessage(props: SelectOrdinalProps): ReactNode {
  const key = en(props.value, true);
  return props[key];
}
