import {toReadonly} from '../../../core/src/helpers/typescript/typeHelpers';
import {makeSizeConstants} from './styleHelpers';

/*
 * Font collections.
 */

/** Main font collections. */
export const fonts = toReadonly({
  system: `
    system-ui,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji'
  `,
  monospace: `
    monaco,
    Consolas,
    "Lucida Console",
    monospace
  `,
  brand: `
    'Roobert', Helvetica, Arial, sans-serif
  `,
});

/*
 * Font sizes.
 */

/** Main font-size map. */
export const fontSizes = toReadonly({
  hardToRead: '9px',
  veryTiny: '10px',
  tiny: '11px',
  verySmall: '12px',
  small: '13px',
  medium: '14px',
  large: '16px',
  veryLarge: '18px',
  huge: '24px',
} as const);

/** Secondary text font-size map. */
export const visualFontSizes = makeSizeConstants(fontSizes.small, fontSizes.medium, fontSizes.large);
export const secondaryFontSizes = makeSizeConstants('12px', '13px', '15px');
export const tertiaryFontSizes = makeSizeConstants(
  fontSizes.verySmall,
  fontSizes.verySmall,
  fontSizes.medium,
);

/*
 * Font weights.
 */

export const fontWeights = toReadonly({
  thin: 100,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  black: 800,
} as const);
