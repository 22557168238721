import {noop} from 'lodash';
import {RefObject, useEffect, useId, useState} from 'react';
import {css} from 'styled-components';

import {
  KEYBOARD_ITEM_DATA_ATTRIBUTE,
  KEYBOARD_ITEM_INTERSECTING_DATA_ATTRIBUTE,
} from './helpers/keyboardNavigationTypes';
import {useHasKeyboardNavigationProvider, useKeyboardNavigationContext} from './keyboardNavigationContext';

/** Classname added to the provided ref if it's highlighted. */
export const highlightedClassName = 'highlighted';

/**
 * Use this helper function to add highlighted styles in your component. :hover
 * pseudo-classes are not required, because mouseover will highlight the item.
 */
export function buildHighlightedStyles(styles: string | ReturnType<typeof css>) {
  return css`
    &.${highlightedClassName} {
      ${styles}
    }
  `;
}

interface UseKeyboardNavigationParams {
  /** Ref to the DOM element which should be keyboard navigable. */
  ref: RefObject<HTMLElement>;

  /**
   * Callback fired when the item is confirmed with the keyboard. For most users
   * this will be when they press enter while the item is highlighted. This callback
   * is only needed if you need to perform some action only when the item is keyboard
   * highlighted (e.g. setting focus after keyboard confirmation.)
   */
  onKeyboardSelect?: () => void;
}

/**
 * Main KeyboardNavigation API hook. Designates a DOM element as a keyboard navigable
 * item. If the element is within a <KeyboardNavigationCoordinator>, keyboard navigation
 * will be enabled.
 *
 * Usage:
 *
 * const Component = () => {
 *   const ref = useRef();
 *   useKeyboardNavigation({ref});
 *   return <div ref={ref} />;
 * };
 */
export function useKeyboardNavigation({ref, onKeyboardSelect}: UseKeyboardNavigationParams) {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const id = useId();

  useCleanupOnUnmount(id);
  useSetIdDataAttribute(id, ref);
  useRegisterHighlightChangeHandler(id, ref, setIsHighlighted);
  useRegisterSelectHandler(id, ref, onKeyboardSelect);
  useFocusHandler(id, ref);
  useIntersectionObserver(ref);
  useHighlightedClassName(ref, isHighlighted);

  return {
    isHighlighted,
  };
}

/** Adds the highlighted classname to the DOM node. */
function useHighlightedClassName(ref: RefObject<HTMLElement>, isHighlighted: boolean) {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    // Do not use this attribute to apply styles, use buildHighlightedStyles and
    // the highlightedClassName instead.
    ref.current.classList.toggle(highlightedClassName, isHighlighted);
  }, [isHighlighted, ref]);
}

/**
 * Sets up intersection observers for the keyboard navigation item, and adds a
 * data attribute to the DOM node if it's visible.
 */
function useIntersectionObserver(ref: RefObject<HTMLElement>) {
  const {watchForIntersection} = useKeyboardNavigationContext();
  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return noop;
    }

    const unsubscribe = watchForIntersection(element, (isIntersecting) => {
      if (isIntersecting) {
        // Do not use this attribute to apply styles, use buildHighlightedStyles and
        // the highlightedClassName instead.
        element.setAttribute(KEYBOARD_ITEM_INTERSECTING_DATA_ATTRIBUTE, 'true');
        return;
      }

      element.removeAttribute(KEYBOARD_ITEM_INTERSECTING_DATA_ATTRIBUTE);
    });
    return () => {
      unsubscribe();
    };
  }, [watchForIntersection, ref]);
}

/**
 * Connects standard browser focus to the KeyboardNavigation system,
 * so the highlighted item is correctly updated when focus lands on the item.
 */
function useFocusHandler(id: string, ref: RefObject<HTMLElement>) {
  const {onFocus} = useKeyboardNavigationContext();
  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return noop;
    }

    const onElementFocus = () => {
      onFocus(id);
    };

    element.addEventListener('focus', onElementFocus);
    return () => {
      element.removeEventListener('focus', onElementFocus);
    };
  }, [id, onFocus, ref]);
}

function isHtmlInputElement(element: HTMLElement): element is HTMLInputElement {
  return element.tagName === 'INPUT';
}

/**
 * Whether a synthetic click event should be triggered on the element. Clicks
 * are dispatched to elements which are clickable (buttons, certain inputs, and
 * links.)
 */
function shouldTriggerSyntheticClick(element: HTMLElement | null) {
  if (!element) {
    return false;
  }

  if (element.tagName === 'BUTTON' || element.tagName === 'A') {
    return true;
  }

  if (element.role === 'button') {
    return true;
  }

  if (isHtmlInputElement(element)) {
    return ['button', 'submit', 'radio', 'checkbox'].includes(element.type);
  }

  return false;
}

function maybeTriggerSyntheticClick(element: HTMLElement | null) {
  if (!element || !shouldTriggerSyntheticClick(element)) {
    return;
  }

  element.click();
}

/** Handler which is called when the user hits enter while the item is highlighted. */
function useRegisterSelectHandler(id: string, ref: RefObject<HTMLElement>, onKeyboardSelect?: () => void) {
  const {addSelectionListener} = useKeyboardNavigationContext();
  useEffect(() => {
    const unsubscribe = addSelectionListener(id, () => {
      maybeTriggerSyntheticClick(ref.current);
      onKeyboardSelect?.();
    });

    return () => {
      unsubscribe();
    };
  }, [id, addSelectionListener, onKeyboardSelect, ref]);
}

/** Handler which is called when the highlighted item is changed. */
function useRegisterHighlightChangeHandler(
  id: string,
  ref: RefObject<HTMLElement>,
  setIsHighlighted: (isHighlighted: boolean) => void,
) {
  const {addHighlightChangeListener} = useKeyboardNavigationContext();
  useEffect(() => {
    const unsubscribe = addHighlightChangeListener(id, (isIdHighlighted, isMouseHover) => {
      setIsHighlighted(isIdHighlighted);

      // If this item is highlighted due to a keyboard event, fire synthetic mouse events.
      const element = ref.current;
      if (!element || isMouseHover) {
        return;
      }

      if (isIdHighlighted) {
        triggerSyntheticMouseEnterEvents(element);
        return;
      }

      triggerSyntheticMouseLeaveEvents(element);
    });

    return () => {
      unsubscribe();
    };
  }, [id, setIsHighlighted, ref, addHighlightChangeListener]);
}

/**
 * Dispatches mouse events when the item becomes highlighted. Follows the
 * order defined by https://w3c.github.io/uievents/#events-mouseevent-event-order
 */
function triggerSyntheticMouseEnterEvents(element: HTMLElement) {
  element.dispatchEvent(
    new MouseEvent('mousemove', {
      bubbles: true,
      cancelable: true,
    }),
  );

  element.dispatchEvent(
    new MouseEvent('mouseover', {
      bubbles: true,
      cancelable: true,
    }),
  );

  element.dispatchEvent(
    new MouseEvent('mouseenter', {
      bubbles: true,
      cancelable: true,
    }),
  );
}

/**
 * Dispatches mouse leave events when the item is no longer highlighted. Follows
 * the order defined by https://w3c.github.io/uievents/#events-mouseevent-event-order
 */
function triggerSyntheticMouseLeaveEvents(element: HTMLElement) {
  element.dispatchEvent(
    new MouseEvent('mouseout', {
      bubbles: true,
      cancelable: true,
    }),
  );

  element.dispatchEvent(
    new MouseEvent('mouseleave', {
      bubbles: true,
      cancelable: true,
    }),
  );
}

/** Ensures the keyboard navigation items's id is always set on the DOM node. */
function useSetIdDataAttribute(id: string, ref: RefObject<HTMLElement>) {
  const hasKeyboardNavigationProvider = useHasKeyboardNavigationProvider();
  useEffect(() => {
    if (!ref.current || !hasKeyboardNavigationProvider) {
      return;
    }

    ref.current.setAttribute(KEYBOARD_ITEM_DATA_ATTRIBUTE, id);
  }, [id, ref, hasKeyboardNavigationProvider]);
}

/** Allows the KeyboardNavigationCoordinator to perform some cleanup on unmount. */
function useCleanupOnUnmount(id: string) {
  const {onItemUnmount} = useKeyboardNavigationContext();
  useEffect(
    () => () => {
      onItemUnmount(id);
    },
    [id, onItemUnmount],
  );
}
