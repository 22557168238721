import * as t from 'io-ts';
import {Any, appendContext, UnionType} from 'io-ts';

export class StrictModeType<A, O = A, I = unknown> extends t.Type<A, O, I> {
  constructor(type: t.Type<A, O, I>) {
    super(
      `StrictMode${type.name}`,
      type.is,
      (value, context) => type.validate(value, appendContext(context, type.name, type, value)),
      type.encode,
    );
  }
}

/**
 * Wraps any type to disable the default behavior of makeSafeArrayType.
 * Mainly useful to invalidate cache that is outdated: it's safer to throw away the cache that cannot be decoded
 * rather than dismissing only the items we cannot parse. We also don't want it reported as it's not possible to avoid it.
 * @param type Any io-ts type.
 * @returns the io=ts type wrapped in a StrictMode context.
 */
export function makeStrictModeType<A, O = A, I = unknown>(type: t.Type<A, O, I>) {
  return new StrictModeType(type);
}

function isStrictModeType<I, A>(type: t.Decoder<I, A>): type is StrictModeType<A, A, I> {
  return type instanceof StrictModeType;
}

function findStrictModeEnabled(context: t.Context): boolean {
  return Boolean(context.map((entry) => entry.type).find(isStrictModeType));
}

function isUnionType<I, A, O>(type: t.Decoder<I, A>): type is UnionType<Array<Any>> {
  return type instanceof UnionType;
}

function findUnionType(context: t.Context): boolean {
  return Boolean(context.map((entry) => entry.type).find(isUnionType));
}

export function shouldDisableSafeMode(context: t.Context) {
  const hasStrictModeEnabled = findStrictModeEnabled(context);
  const isInUnion = findUnionType(context);
  return hasStrictModeEnabled || isInUnion;
}
