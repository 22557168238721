import {isString} from 'lodash';
import React, {memo} from 'react';
import styled, {css} from 'styled-components';

import {buildCleanReactInnerHtml} from '../../../../core/src/helpers/typescript/cleanHtml';
import {useSvgContent} from '../svg/useSvgContent';
import {buildIconAttributes, buildSvgAssetMap} from './iconHelpers';

/* eslint global-require: 0 */
const importedIcons = buildSvgAssetMap({
  arrowsTiny: require('../../../../assets/src/icon12/arrows.svg'),
  autoContactFilledTiny: require('../../../../assets/src/icon12/autoContactFilled.svg'),
  bellTiny: require('../../../../assets/src/icon12/bellFilled.svg'),
  checkmarkBoxTiny: require('../../../../assets/src/icon12/checkmarkBox.svg'),
  chevronDownTiny: require('../../../../assets/src/icon12/chevronDown.svg'),
  chevronExpandTiny: require('../../../../assets/src/icon12/chevronExpand.svg'),
  chevronRightTiny: require('../../../../assets/src/icon12/chevronRight.svg'),
  clockTiny: require('../../../../assets/src/icon12/clock.svg'),
  closeTiny: require('../../../../assets/src/icon12/close.svg'),
  copyTiny: require('../../../../assets/src/icon12/copy.svg'),
  diamondFilledTiny: require('../../../../assets/src/icon12/diamondFilled.svg'),
  downloadTiny: require('../../../../assets/src/icon12/download.svg'),
  draftFilledTiny: require('../../../../assets/src/icon12/draftFilled.svg'),
  editTiny: require('../../../../assets/src/icon12/edit.svg'),
  exitTiny: require('../../../../assets/src/icon12/exit.svg'),
  externalLinkTiny: require('../../../../assets/src/icon12/externalLink.svg'),
  gearFilledTiny: require('../../../../assets/src/icon12/gearFilled.svg'),
  lightbulbTiny: require('../../../../assets/src/icon12/lightbulb.svg'),
  lightningTiny: require('../../../../assets/src/icon12/lightning.svg'),
  linkedConversationsTiny: require('../../../../assets/src/icon12/linkedConversations.svg'),
  linkedConversationsFilledTiny: require('../../../../assets/src/icon12/linkedConversationsFilled.svg'),
  linkTiny: require('../../../../assets/src/icon12/link.svg'),
  lockFilledNewTiny: require('../../../../assets/src/icon12/lockFilledNew.svg'),
  lockFilledTiny: require('../../../../assets/src/icon12/lockFilled.svg'),
  mutedStatusTiny: require('../../../../assets/src/icon12/mutedStatus.svg'),
  newTiny: require('../../../../assets/src/icon12/new.svg'),
  oooTiny: require('../../../../assets/src/icon12/OOO.svg'),
  pinFilledTiny: require('../../../../assets/src/icon12/pinFilled.svg'),
  plusTiny: require('../../../../assets/src/icon12/plus.svg'),
  questionMarkTiny: require('../../../../assets/src/icon12/questionMark.svg'),
  replyTiny: require('../../../../assets/src/icon12/reply.svg'),
  searchTiny: require('../../../../assets/src/icon12/search.svg'),
  sharedFilledTiny: require('../../../../assets/src/icon12/sharedFilled.svg'),
  sparklesAiFilledTiny: require('../../../../assets/src/icon12/sparklesAIFilled.svg'),
  starTiny: require('../../../../assets/src/icon12/star.svg'),
  subscribeTiny: require('../../../../assets/src/icon12/subscribe.svg'),
  unassignTiny: require('../../../../assets/src/icon12/unassign.svg'),
  unsubscribeTiny: require('../../../../assets/src/icon12/unsubscribe.svg'),
  warningFilledTiny: require('../../../../assets/src/icon12/warningFilled.svg'),

  archiveActivity: require('../../../../assets/src/icon14/archiveActivity.svg'),
  archiveActivityFilled: require('../../../../assets/src/icon14/archiveActivityFilled.svg'),
  assignActivity: require('../../../../assets/src/icon14/assignActivity.svg'),
  assignActivityFilled: require('../../../../assets/src/icon14/assignActivityFilled.svg'),
  callAnsweredActivity: require('../../../../assets/src/icon14/callAnsweredActivity.svg'),
  callEndedActivity: require('../../../../assets/src/icon14/callEndedActivity.svg'),
  chatEndedActivity: require('../../../../assets/src/icon14/chatEndedActivity.svg'),
  checkmarkYesActivity: require('../../../../assets/src/icon14/checkmarkYes.svg'),
  chatDeflected: require('../../../../assets/src/icon14/chatDeflected.svg'),
  gearActivity: require('../../../../assets/src/icon14/gearActivity.svg'),
  groupActivity: require('../../../../assets/src/icon14/groupActivity.svg'),
  inboxActivity: require('../../../../assets/src/icon14/inboxActivity.svg'),
  inboxInboundFilled: require('../../../../assets/src/icon14/inboxInboundFilled.svg'),
  integrationActivity: require('../../../../assets/src/icon14/integrationActivity.svg'),
  inviteActivity: require('../../../../assets/src/icon14/inviteActivity.svg'),
  mergeActivity: require('../../../../assets/src/icon14/mergeActivity.svg'),
  mergeActivityFilled: require('../../../../assets/src/icon14/mergeActivityFilled.svg'),
  messageActivity: require('../../../../assets/src/icon14/message.svg'),
  moveActivity: require('../../../../assets/src/icon14/moveActivity.svg'),
  questionmarkMaybeActivity: require('../../../../assets/src/icon14/questionmarkMaybe.svg'),
  lightningActivity: require('../../../../assets/src/icon14/lightningActivity.svg'),
  linkedConversationsFilledMedium: require('../../../../assets/src/icon14/linkedConversationsFilled.svg'),
  removeActivity: require('../../../../assets/src/icon14/removeActivity.svg'),
  removeIntegrationActivity: require('../../../../assets/src/icon14/removeIntegrationActivity.svg'),
  snoozeActivity: require('../../../../assets/src/icon14/snoozeActivity.svg'),
  snoozeActivityFilled: require('../../../../assets/src/icon14/snoozeActivityfilled.svg'),
  splitActivity: require('../../../../assets/src/icon14/splitActivity.svg'),
  splitActivityFilled: require('../../../../assets/src/icon14/splitActivityFilled.svg'),
  squareFilled: require('../../../../assets/src/icon14/squareFilled.svg'),
  tagActivity: require('../../../../assets/src/icon14/tagActivity.svg'),
  tagActivityFilled: require('../../../../assets/src/icon14/tagActivityFilled.svg'),
  trashActivity: require('../../../../assets/src/icon14/trashActivity.svg'),
  unarchiveActivityFilled: require('../../../../assets/src/icon14/unarchiveActivityFilled.svg'),
  unassignActivity: require('../../../../assets/src/icon14/unassignActivity.svg'),
  unassignActivityFilled: require('../../../../assets/src/icon14/unassignActivityFilled.svg'),
  unlinkedConversationsActivity: require('../../../../assets/src/icon14/unlinkedConversationsActivity.svg'),
  untagActivity: require('../../../../assets/src/icon14/untagActivity.svg'),
  untagActivityFilled: require('../../../../assets/src/icon14/untagActivityFilled.svg'),
  warningActivity: require('../../../../assets/src/icon14/warningActivity.svg'),
  xNoActivity: require('../../../../assets/src/icon14/xNo.svg'),

  addContactSmall: require('../../../../assets/src/icon16/addContact.svg'),
  addLinkSmall: require('../../../../assets/src/icon16/addLink.svg'),
  addReactionSmall: require('../../../../assets/src/icon16/addReaction.svg'),
  addSmall: require('../../../../assets/src/icon16/add.svg'),
  advancedNoticeSmall: require('../../../../assets/src/icon16/advancedNotice.svg'),
  aiSummarySmall: require('../../../../assets/src/icon16/aiSummary.svg'),
  analyticsFilledSmall: require('../../../../assets/src/icon16/analyticsFilled.svg'),
  analyticsSmall: require('../../../../assets/src/icon16/analytics.svg'),
  archiveFilledSmall: require('../../../../assets/src/icon16/archiveFilled.svg'),
  archiveSmall: require('../../../../assets/src/icon16/archive.svg'),
  arrowNestedSmall: require('../../../../assets/src/icon16/arrowNested.svg'),
  arrowDownSmall: require('../../../../assets/src/icon16/arrowDown.svg'),
  arrowDownThickSmall: require('../../../../assets/src/icon16/arrowDownThick.svg'),
  arrowDownAngleSmall: require('../../../../assets/src/icon16/arrowDownAngle.svg'),
  arrowUpSmall: require('../../../../assets/src/icon16/arrowUp.svg'),
  arrowUpThickSmall: require('../../../../assets/src/icon16/arrowUpThick.svg'),
  arrowUpAngleSmall: require('../../../../assets/src/icon16/arrowUpAngle.svg'),
  arrowSmall: require('../../../../assets/src/icon16/arrow.svg'),
  arrowThickSmall: require('../../../../assets/src/icon16/arrowThick.svg'),
  articleSmall: require('../../../../assets/src/icon16/article.svg'),
  articleFilledSmall: require('../../../../assets/src/icon16/articleFilled.svg'),
  assignAll: require('../../../../assets/src/icon16/assignAll.svg'),
  assignFilledSmall: require('../../../../assets/src/icon16/assignFilled.svg'),
  assignSmall: require('../../../../assets/src/icon16/assign.svg'),
  attachmentSmall: require('../../../../assets/src/icon16/attachment.svg'),
  auditLogSmall: require('../../../../assets/src/icon16/auditLog.svg'),
  auditLogFilledSmall: require('../../../../assets/src/icon16/auditLogFilled.svg'),
  autoContactSmall: require('../../../../assets/src/icon16/autoContact.svg'),
  autoContactFilledSmall: require('../../../../assets/src/icon16/autoContactFilled.svg'),
  bellCrossedSmall: require('../../../../assets/src/icon16/bellCrossed.svg'),
  bellSmall: require('../../../../assets/src/icon16/bell.svg'),
  briefcaseFilledSmall: require('../../../../assets/src/icon16/briefcaseFilled.svg'),
  briefcaseOutlineSmall: require('../../../../assets/src/icon16/briefcaseOutline.svg'),
  buildingFilledSmall: require('../../../../assets/src/icon16/buildingFilled.svg'),
  squareFilledSmall: require('../../../../assets/src/icon16/squareFilled.svg'),
  calendarFilledSmall: require('../../../../assets/src/icon16/calendarFilled.svg'),
  calendarSmall: require('../../../../assets/src/icon16/calendar.svg'),
  callInboundFilledSmall: require('../../../../assets/src/icon16/callInboundFilled.svg'),
  callMissedSmall: require('../../../../assets/src/icon16/callMissed.svg'),
  caseStudySmall: require('../../../../assets/src/icon16/caseStudy.svg'),
  changeStatusSmall: require('../../../../assets/src/icon16/changeStatus.svg'),
  changeStatusFilledSmall: require('../../../../assets/src/icon16/changeStatusFilled.svg'),
  channelCallOutlineSmall: require('../../../../assets/src/icon16/channelCallOutline.svg'),
  channelCallFilledSmall: require('../../../../assets/src/icon16/channelCallFilled.svg'),
  channelChatSmall: require('../../../../assets/src/icon16/channelChat.svg'),
  channelCustomOutlineSmall: require('../../../../assets/src/icon16/channelCustomOutline.svg'), // Not in Figma.
  channelCustomSmall: require('../../../../assets/src/icon16/channelCustom.svg'), // Not in Figma.
  channelEmailOutlineSmall: require('../../../../assets/src/icon16/channelEmailOutlineSmall.svg'),
  channelEmailSmall: require('../../../../assets/src/icon16/channelEmail.svg'),
  channelGooglePlayOutlineSmall: require('../../../../assets/src/icon16/channelGooglePlayOutline.svg'),
  channelGooglePlaySmall: require('../../../../assets/src/icon16/channelGooglePlay.svg'),
  channelsSmall: require('../../../../assets/src/icon16/channels.svg'),
  channelsFilledSmall: require('../../../../assets/src/icon16/channelsFilled.svg'),
  channelChatOutlineSmall: require('../../../../assets/src/icon16/channelChatOutline.svg'),
  chatbotSmall: require('../../../../assets/src/icon16/chatbotSmall.svg'),
  chatbotFilledSmall: require('../../../../assets/src/icon16/chatbotFilledSmall.svg'),
  checkmarkBoxSmall: require('../../../../assets/src/icon16/checkmarkBox.svg'),
  checkmarkCircleSmall: require('../../../../assets/src/icon16/checkmarkCircle.svg'),
  checkmarkCircleLarge: require('../../../../assets/src/icons/checkmarkCircle.svg'),
  checkmarkCircleOutlineSmall: require('../../../../assets/src/icon16/checkmarkCircleOutline.svg'),
  checkmarkSelectedSmall: require('../../../../assets/src/icon16/checkmarkSelected.svg'),
  chevronCollapseSmall: require('../../../../assets/src/icon16/chevronCollapse.svg'),
  chevronDownSmall: require('../../../../assets/src/icon16/chevronDown.svg'),
  chevronExpandSmall: require('../../../../assets/src/icon16/chevronExpand.svg'),
  chevronLeftSmall: require('../../../../assets/src/icon16/chevronLeft.svg'),
  chevronRightSmall: require('../../../../assets/src/icon16/chevronRight.svg'),
  chevronUpSmall: require('../../../../assets/src/icon16/chevronUp.svg'),
  chevronExpandSettings: require('../../../../assets/src/icon16/chevronExpandSettings.svg'),
  clearSmall: require('../../../../assets/src/icon16/clear.svg'),
  closeSmall: require('../../../../assets/src/icon16/close.svg'),
  codeSmall: require('../../../../assets/src/icon16/code.svg'),
  codeFilledSmall: require('../../../../assets/src/icon16/codeFilled.svg'),
  collapseSmall: require('../../../../assets/src/icon16/collapse.svg'),
  composeSmall: require('../../../../assets/src/icon16/compose.svg'),
  contactsSmall: require('../../../../assets/src/icon16/contacts.svg'),
  contactsFilledSmall: require('../../../../assets/src/icon16/contactsFilled.svg'),
  contentSmall: require('../../../../assets/src/icon16/content.svg'),
  copySmall: require('../../../../assets/src/icon16/copy.svg'),
  creditcardSmall: require('../../../../assets/src/icon16/creditcard.svg'),
  creditcardFilledSmall: require('../../../../assets/src/icon16/creditcardFilled.svg'),
  crossCircleSmall: require('../../../../assets/src/icon16/crossCircle.svg'),
  csatSmall: require('../../../../assets/src/icon16/csat.svg'),
  csatFilledSmall: require('../../../../assets/src/icon16/csatFilled.svg'),
  crownSmall: require('../../../../assets/src/icon16/crown.svg'),
  customFieldsActivity: require('../../../../assets/src/icon14/customFieldsActivity.svg'),
  customFieldsSmall: require('../../../../assets/src/icon16/customFields.svg'),
  customFieldsFilledSmall: require('../../../../assets/src/icon16/customFieldsFilled.svg'),
  customFieldsFilled: require('../../../../assets/src/icon20/customFieldsFilled.svg'),
  customViewsFilledSmall: require('../../../../assets/src/icon16/customViewsFilled.svg'),
  customViewsSmall: require('../../../../assets/src/icon16/customViews.svg'),
  customerPortalSmall: require('../../../../assets/src/icon16/customerPortal.svg'),
  delegationFilledSmall: require('../../../../assets/src/icon16/delegationFilled.svg'),
  dateSmall: require('../../../../assets/src/icon16/date.svg'),
  dateFilledSmall: require('../../../../assets/src/icon16/dateFilled.svg'),
  diamondSmall: require('../../../../assets/src/icon16/diamond.svg'),
  diamondFilledSmall: require('../../../../assets/src/icon16/diamondFilled.svg'),
  discussionFilledSmall: require('../../../../assets/src/icon16/discussionFilled.svg'),
  discussionSmall: require('../../../../assets/src/icon16/discussion.svg'),
  donotdisturbSmall: require('../../../../assets/src/icon16/donotdisturb.svg'),
  downloadLineSmall: require('../../../../assets/src/icon16/downloadLine.svg'),
  downloadSmall: require('../../../../assets/src/icon16/download.svg'),
  downloadFilledSmall: require('../../../../assets/src/icon16/downloadFilled.svg'),
  draftFilledSmall: require('../../../../assets/src/icon16/draftFilled.svg'),
  draftSmall: require('../../../../assets/src/icon16/draft.svg'),
  dropOffSmall: require('../../../../assets/src/icon16/dropOff.svg'),
  duplicateCopiesSmall: require('../../../../assets/src/icon16/duplicateCopies.svg'),
  dynamicVariableSmall: require('../../../../assets/src/icon16/dynamicVariable.svg'),
  dynamicVariableFilledSmall: require('../../../../assets/src/icon16/dynamicVariableFilled.svg'),
  editSmall: require('../../../../assets/src/icon16/edit.svg'),
  emojiSmall: require('../../../../assets/src/icon16/emoji.svg'),
  exitFilledSmall: require('../../../../assets/src/icon16/exitFilled.svg'), // Not in Figma.
  exitSmall: require('../../../../assets/src/icon16/exit.svg'), // Not in Figma.
  expandSmall: require('../../../../assets/src/icon16/expand.svg'),
  exportSmall: require('../../../../assets/src/icon16/export.svg'),
  externalLinkSmall: require('../../../../assets/src/icon16/externalLink.svg'),
  eyeSmall: require('../../../../assets/src/icon16/eye.svg'),
  eyeFilledSmall: require('../../../../assets/src/icon16/eyeFilled.svg'),
  eyeCrossedSmall: require('../../../../assets/src/icon16/eyeCrossed.svg'),
  eyeCrossedFilledSmall: require('../../../../assets/src/icon16/eyeCrossedFilled.svg'),
  facebookSmall: require('../../../../assets/src/icon16/facebook.svg'),
  featherSmall: require('../../../../assets/src/icon16/feather.svg'),
  featherFilledSmall: require('../../../../assets/src/icon16/featherFilled.svg'),
  filtersSmall: require('../../../../assets/src/icon16/filters.svg'),
  filtersFilledSmall: require('../../../../assets/src/icon16/filtersFilled.svg'),
  firstPageSmall: require('../../../../assets/src/icon16/firstPage.svg'),
  flagSmall: require('../../../../assets/src/icon16/emojiFlags.svg'),
  folderSmall: require('../../../../assets/src/icon16/folder.svg'),
  formattingBlockquoteSmall: require('../../../../assets/src/icon16/formattingQuote.svg'),
  formattingBoldSmall: require('../../../../assets/src/icon16/formattingBold.svg'),
  formattingColorSmall: require('../../../../assets/src/icon16/formattingColor.svg'),
  formattingHighlightSmall: require('../../../../assets/src/icon16/formattingHighlight.svg'),
  formattingItalicSmall: require('../../../../assets/src/icon16/formattingItalics.svg'),
  formattingLinkSmall: require('../../../../assets/src/icon16/formattingLink.svg'),
  formattingNumberedListSmall: require('../../../../assets/src/icon16/formattingNumberedList.svg'),
  formattingRemoveSmall: require('../../../../assets/src/icon16/formattingRemove.svg'),
  formattingStrikethroughSmall: require('../../../../assets/src/icon16/formattingStrikethrough.svg'),
  formattingUnderlineSmall: require('../../../../assets/src/icon16/formattingUnderline.svg'),
  forwardFilled: require('../../../../assets/src/icon16/forwardFilled.svg'),
  forwardSmall: require('../../../../assets/src/icon16/forward.svg'),
  forwardAsNewSmall: require('../../../../assets/src/icon16/forwardAsNew.svg'),
  gearFilledSmall: require('../../../../assets/src/icon16/gearFilled.svg'),
  gearSmall: require('../../../../assets/src/icon16/gear.svg'),
  gifSmall: require('../../../../assets/src/icon16/gif.svg'),
  giftSmall: require('../../../../assets/src/icon16/gift.svg'),
  globeSmall: require('../../../../assets/src/icon16/globe.svg'),
  globeFilledSmall: require('../../../../assets/src/icon16/globeFilled.svg'),
  glossaryLineSmall: require('../../../../assets/src/icon16/glossaryLine.svg'),
  graduationCapFilledSmall: require('../../../../assets/src/icon16/graduationCapFilled.svg'),
  grabHandleSmall: require('../../../../assets/src/icon16/grabhandle.svg'),
  groupSmall: require('../../../../assets/src/icon16/group.svg'),
  groupOutlineSmall: require('../../../../assets/src/icon16/groupOutline.svg'),
  helpSmall: require('../../../../assets/src/icon16/help.svg'),
  helpFilledSmall: require('../../../../assets/src/icon16/helpFilled.svg'),
  helpFullsizeFilledSmall: require('../../../../assets/src/icon16/helpFullsizeFilled.svg'),
  homeSmall: require('../../../../assets/src/icon16/home.svg'),
  homeFilledSmall: require('../../../../assets/src/icon16/homeFilled.svg'),
  imageSmall: require('../../../../assets/src/icon16/image.svg'),
  inboxFilledSmall: require('../../../../assets/src/icon16/inboxFilled.svg'),
  inboxSmall: require('../../../../assets/src/icon16/inbox.svg'),
  inboxListSmall: require('../../../../assets/src/icon16/inboxList.svg'),
  inboxListFilledSmall: require('../../../../assets/src/icon16/inboxListFilled.svg'),
  inboxDeliverabilitySmall: require('../../../../assets/src/icon16/inboxDeliverability.svg'),
  inboxDeliverabilityFilledSmall: require('../../../../assets/src/icon16/inboxDeliverabilityFilled.svg'),
  infoFilledSmall: require('../../../../assets/src/icon16/infoFilled.svg'),
  infoSmall: require('../../../../assets/src/icon16/info.svg'),
  integrationsFilledSmall: require('../../../../assets/src/icon16/integrationsFilled.svg'),
  integrationsSmall: require('../../../../assets/src/icon16/integrations.svg'),
  intercomSmall: require('../../../../assets/src/icon16/intercom.svg'),
  karlitoSmall: require('../../../../assets/src/icon16/karlito.svg'),
  knowledgeBaseFilledSmall: require('../../../../assets/src/icon16/knowledgeBaseFilled.svg'),
  knowledgeBaseSmall: require('../../../../assets/src/icon16/knowledgeBase.svg'),
  languageSmall: require('../../../../assets/src/icon16/language.svg'),
  lastPageSmall: require('../../../../assets/src/icon16/lastPage.svg'),
  lightbulbSmall: require('../../../../assets/src/icon16/lightbulb.svg'),
  lightningSmall: require('../../../../assets/src/icon16/lightning.svg'),
  lightningFilledSmall: require('../../../../assets/src/icon16/lightningFilled.svg'),
  linkSmall: require('../../../../assets/src/icon16/link.svg'),
  listSmall: require('../../../../assets/src/icon16/list.svg'),
  listFilledSmall: require('../../../../assets/src/icon16/listFilled.svg'),
  locationSmall: require('../../../../assets/src/icon16/location.svg'),
  lockSmall: require('../../../../assets/src/icon16/lock.svg'),
  lockOutlineSmall: require('../../../../assets/src/icon16/lockOutline.svg'),
  meetingBufferSmall: require('../../../../assets/src/icon16/meetingBuffer.svg'),
  meetingBuildingSmall: require('../../../../assets/src/icon16/meetingBuilding.svg'),
  meetingRoomSmall: require('../../../../assets/src/icon16/meetingRoom.svg'),
  mentionFilledSmall: require('../../../../assets/src/icon16/mentionFilled.svg'),
  mentionSmall: require('../../../../assets/src/icon16/mention.svg'),
  mergeSmall: require('../../../../assets/src/icon16/merge.svg'),
  mergeFilledSmall: require('../../../../assets/src/icon16/mergeFilled.svg'),
  messageSmall: require('../../../../assets/src/icon16/message.svg'),
  messageTemplateFilledSmall: require('../../../../assets/src/icon16/messageTemplateFilled.svg'),
  messageTemplateSmall: require('../../../../assets/src/icon16/messageTemplate.svg'),
  minusSmall: require('../../../../assets/src/icon16/minus.svg'),
  moonFilledSmall: require('../../../../assets/src/icon16/moonFilled.svg'),
  moreVerticalSmall: require('../../../../assets/src/icon16/moreVertical.svg'),
  moveRightSmall: require('../../../../assets/src/icon16/moveRight.svg'),
  moveRightFilledSmall: require('../../../../assets/src/icon16/moveRightFilled.svg'),
  moveLeftSmall: require('../../../../assets/src/icon16/moveLeft.svg'),
  moveLeftFilledSmall: require('../../../../assets/src/icon16/moveLeftFilled.svg'),
  muteSmall: require('../../../../assets/src/icon16/mute.svg'),
  newAccountSmall: require('../../../../assets/src/icon16/newAccount.svg'),
  newGroupSmall: require('../../../../assets/src/icon16/newGroup.svg'),
  newSmall: require('../../../../assets/src/icon16/new.svg'),
  noneSmall: require('../../../../assets/src/icon16/none.svg'),
  noTagSmall: require('../../../../assets/src/icon16/noTag.svg'),
  notAssignSmall: require('../../../../assets/src/icon16/notAssign.svg'),
  noteSmall: require('../../../../assets/src/icon16/note.svg'),
  notesSmall: require('../../../../assets/src/icon16/notes.svg'),
  notificationSmall: require('../../../../assets/src/icon16/notification.svg'),
  notificationStrokeSmall: require('../../../../assets/src/icon16/notificationStroke.svg'),
  numbersSmall: require('../../../../assets/src/icon16/numbers.svg'),
  offlineFilledSmall: require('../../../../assets/src/icon16/offlineFilled.svg'),
  ongoingRequestSmall: require('../../../../assets/src/icon16/ongoingRequest.svg'),
  openSmall: require('../../../../assets/src/icon16/open.svg'),
  optionalGuestSmall: require('../../../../assets/src/icon16/optionalGuest.svg'),
  optionalGuestFilledSmall: require('../../../../assets/src/icon16/optionalGuestFilled.svg'),
  outboxFilledSmall: require('../../../../assets/src/icon16/outboxFilled.svg'),
  outboxSmall: require('../../../../assets/src/icon16/outbox.svg'),
  palmtreeSmall: require('../../../../assets/src/icon16/palmtree.svg'),
  palmtreeFilledSmall: require('../../../../assets/src/icon16/palmtreeFilled.svg'),
  participantSmall: require('../../../../assets/src/icon16/participant.svg'),
  participantFilledSmall: require('../../../../assets/src/icon16/participantFilled.svg'),
  phoneSmall: require('../../../../assets/src/icon16/phone.svg'),
  phishingSmall: require('../../../../assets/src/icon16/phishing.svg'),
  pinOutlineSmall: require('../../../../assets/src/icon16/pinOutline.svg'),
  pinSmall: require('../../../../assets/src/icon16/pin.svg'),
  pinFilledSmall: require('../../../../assets/src/icon16/pinFilled.svg'),
  plusCircleFilledSmall: require('../../../../assets/src/icon16/plusCircleFilled.svg'),
  plusCircleSmall: require('../../../../assets/src/icon16/plusCircle.svg'),
  plusSmall: require('../../../../assets/src/icon16/plus.svg'),
  popoutSmall: require('../../../../assets/src/icon16/popout.svg'),
  preferencesSmall: require('../../../../assets/src/icon16/preferences.svg'),
  preferencesFilledSmall: require('../../../../assets/src/icon16/preferencesFilled.svg'),
  printSmall: require('../../../../assets/src/icon16/print.svg'),
  questionmarkMaybe: require('../../../../assets/src/icon16/questionmarkMaybe.svg'),
  questionmarkMaybeSmall: require('../../../../assets/src/icon16/questionmarkMaybe.svg'),
  replaceAllSmall: require('../../../../assets/src/icon16/replaceAll.svg'),
  removeSmall: require('../../../../assets/src/icon16/remove.svg'), // Not in Figma.
  readSmall: require('../../../../assets/src/icon16/read.svg'),
  sparklesAiSmall: require('../../../../assets/src/icon16/sparklesAI.svg'),
  sparklesAiFilledSmall: require('../../../../assets/src/icon16/sparklesAIFilled.svg'),
  lightThemeSmall: require('../../../../assets/src/icon16/lightTheme.svg'),
  linkedConversationsSmall: require('../../../../assets/src/icon16/linkedConversations.svg'),
  linkedConversationsFilledSmall: require('../../../../assets/src/icon16/linkedConversationsFilled.svg'),
  repeatCircleSmall: require('../../../../assets/src/icon16/repeatCircle.svg'),
  repeatSmall: require('../../../../assets/src/icon16/repeat.svg'),
  replySmall: require('../../../../assets/src/icon16/reply.svg'),
  replyFilledSmall: require('../../../../assets/src/icon16/replyFilled.svg'),
  repliedSmall: require('../../../../assets/src/icon16/replied.svg'),
  requiredGuestSmall: require('../../../../assets/src/icon16/requiredGuest.svg'),
  resendAsNewSmall: require('../../../../assets/src/icon16/resendAsNew.svg'),
  retrySmall: require('../../../../assets/src/icon16/retry.svg'),
  retweetSmall: require('../../../../assets/src/icon16/retweet.svg'),
  reviewedSmall: require('../../../../assets/src/icon16/reviewed.svg'),
  ruleTest: require('../../../../assets/src/icon16/ruleTest.svg'),
  savedFlowSmall: require('../../../../assets/src/icon16/savedFlowSmall.svg'),
  savedFlowOutlineSmall: require('../../../../assets/src/icon16/savedFlowOutlineSmall.svg'),
  savedViewSmall: require('../../../../assets/src/icon16/savedViews.svg'),
  savedViewFilledSmall: require('../../../../assets/src/icon16/savedViewsFilled.svg'),
  scheduleSmall: require('../../../../assets/src/icon16/schedule.svg'),
  scheduleFilledSmall: require('../../../../assets/src/icon16/scheduleFilled.svg'),
  searchSmall: require('../../../../assets/src/icon16/search.svg'),
  sendFilledSmall: require('../../../../assets/src/icon16/sendFilled.svg'),
  sendSmall: require('../../../../assets/src/icon16/send.svg'),
  sharedFilledSmall: require('../../../../assets/src/icon16/sharedFilled.svg'),
  sharedOutlineSmall: require('../../../../assets/src/icon16/sharedOutline.svg'),
  shiftsSmall: require('../../../../assets/src/icon16/shifts.svg'),
  slaSmall: require('../../../../assets/src/icon16/sla.svg'),
  slaFilledSmall: require('../../../../assets/src/icon16/slaFilled.svg'),
  snoozeFilledSmall: require('../../../../assets/src/icon16/snoozeFilled.svg'),
  snoozeSmall: require('../../../../assets/src/icon16/snooze.svg'),
  sortSmall: require('../../../../assets/src/icon16/sort.svg'),
  spamFilledSmall: require('../../../../assets/src/icon16/spamFilled.svg'),
  spamSmall: require('../../../../assets/src/icon16/spam.svg'),
  splitSmall: require('../../../../assets/src/icon16/split.svg'),
  splitFilledSmall: require('../../../../assets/src/icon16/splitFilled.svg'),
  stageSmall: require('../../../../assets/src/icon16/stage.svg'),
  stageFilledSmall: require('../../../../assets/src/icon16/stageFilled.svg'),
  starFilledSmall: require('../../../../assets/src/icon16/starFilled.svg'),
  starSmall: require('../../../../assets/src/icon16/star.svg'),
  subscribeSmall: require('../../../../assets/src/icon16/subscribe.svg'),
  subscribeFilledSmall: require('../../../../assets/src/icon16/subscribeFilled.svg'),
  tableColumnSmall: require('../../../../assets/src/icon16/tableColumn.svg'),
  tableGridSmall: require('../../../../assets/src/icon16/tableGrid.svg'),
  tableGridFilledSmall: require('../../../../assets/src/icon16/tableGridFilled.svg'),
  tagFilledSmall: require('../../../../assets/src/icon16/tagFilled.svg'),
  tagIsNotFilledSmall: require('../../../../assets/src/icon16/tagIsNotFilled.svg'),
  tagSmall: require('../../../../assets/src/icon16/tag.svg'),
  tagsSmall: require('../../../../assets/src/icon16/tags.svg'),
  tagsFilledSmall: require('../../../../assets/src/icon16/tagsFilled.svg'),
  teammatesFilledSmall: require('../../../../assets/src/icon16/teammatesFilledP1.svg'),
  teammateSmall: require('../../../../assets/src/icon16/teammate.svg'), // Not in Figma.
  teammatesSmall: require('../../../../assets/src/icon16/teammates.svg'),
  teammateRoleSmall: require('../../../../assets/src/icon16/teammateRole.svg'),
  teammateRoleFilledSmall: require('../../../../assets/src/icon16/teammateRoleFilled.svg'),
  templateSmall: require('../../../../assets/src/icon16/template.svg'),
  thumbsdownSmall: require('../../../../assets/src/icon16/thumbsdown.svg'),
  thumbsupSmall: require('../../../../assets/src/icon16/thumbsup.svg'),
  timeSmall: require('../../../../assets/src/icon16/time.svg'),
  timeFilledSmall: require('../../../../assets/src/icon16/timeFilled.svg'),
  ticketSmall: require('../../../../assets/src/icon16/ticketSmall.svg'),
  ticketFilled: require('../../../../assets/src/icon16/ticketFilled.svg'),
  togglesSmall: require('../../../../assets/src/icon16/toggles.svg'),
  togglesFilledSmall: require('../../../../assets/src/icon16/togglesFilled.svg'),
  topicWebSmall: require('../../../../assets/src/icon16/topicWeb.svg'), // Not in Figma.
  trashFilledSmall: require('../../../../assets/src/icon16/trashFilled.svg'),
  trashSmall: require('../../../../assets/src/icon16/trash.svg'),
  twitterSmall: require('../../../../assets/src/icon16/twitter.svg'),
  unassignedSmall: require('../../../../assets/src/icon16/unassigned.svg'),
  unassignedFilledSmall: require('../../../../assets/src/icon16/unassignedFilled.svg'),
  unassignFilledSmall: require('../../../../assets/src/icon16/unassignFilled.svg'), // Not in Figma.
  unlinkedConversationsSmall: require('../../../../assets/src/icon16/unlinkedConversations.svg'),
  unlinkedConversationsFilledSmall: require('../../../../assets/src/icon16/unlinkedConversationsFilled.svg'),
  unpinOutlineSmall: require('../../../../assets/src/icon16/unpinOutline.svg'),
  unreadSmall: require('../../../../assets/src/icon16/unread.svg'), // Not in Figma.
  variableSmall: require('../../../../assets/src/icon16/variable.svg'),
  videoSmall: require('../../../../assets/src/icon16/video.svg'),
  voicemailSmall: require('../../../../assets/src/icon16/voicemail.svg'),
  warningFilledSmall: require('../../../../assets/src/icon16/warningFilled.svg'),
  webinarLibrary: require('../../../../assets/src/icon16/webinarLibrary.svg'),
  workspaceSmall: require('../../../../assets/src/icon16/workspace.svg'),
  workspaceFilledSmall: require('../../../../assets/src/icon16/workspaceFilled.svg'),
  newMessageSmall: require('../../../../assets/src/icon16/newMessage.svg'),
  visitorSourceUrlSmall: require('../../../../assets/src/icon16/visitorSourceUrl.svg'),
  whatsappSmall: require('../../../../assets/src/icon16/whatsapp.svg'),

  analytics: require('../../../../assets/src/icon20/analytics.svg'),
  archive: require('../../../../assets/src/icon20/archive.svg'),
  archiveFilled: require('../../../../assets/src/icon20/archiveFilled.svg'),
  arrowBack: require('../../../../assets/src/icon20/arrowBack.svg'),
  arrowCurvedRight: require('../../../../assets/src/icon20/arrowCurvedRight.svg'),
  arrowForward: require('../../../../assets/src/icon20/arrowForward.svg'),
  arrowNested: require('../../../../assets/src/icon20/arrowNested.svg'),
  arrowNext: require('../../../../assets/src/icon20/arrowNext.svg'),
  article: require('../../../../assets/src/icon20/article.svg'),
  articleFilled: require('../../../../assets/src/icon20/articleFilled.svg'),
  assign: require('../../../../assets/src/icon20/assign.svg'),
  assignFilled: require('../../../../assets/src/icon20/assignFilled.svg'),
  attachment: require('../../../../assets/src/icon20/attachment.svg'),
  auditTrail: require('../../../../assets/src/icon20/auditTrail.svg'),
  bell: require('../../../../assets/src/icon20/bell.svg'),
  blocks: require('../../../../assets/src/icon20/blocks.svg'),
  briefcaseFilled: require('../../../../assets/src/icon20/briefcaseFilled.svg'),
  briefcaseOutline: require('../../../../assets/src/icon20/briefcaseOutline.svg'),
  buildingFilled: require('../../../../assets/src/icon20/buildingFilled.svg'),
  buildingFilledCompany: require('../../../../assets/src/icon20/buildingFilledCompany.svg'),
  businessHoursFilled: require('../../../../assets/src/icon20/businesshoursFilled.svg'),
  calendar: require('../../../../assets/src/icon20/calendar.svg'),
  calendarFilled: require('../../../../assets/src/icon20/calendarFilled.svg'),
  calendarInvite: require('../../../../assets/src/icon20/calendarInvite.svg'),
  calendars: require('../../../../assets/src/icon20/calendars.svg'),
  callOutlined: require('../../../../assets/src/icon20/callOutlined.svg'),
  channelCallFilled: require('../../../../assets/src/icon20/channelCallFilled.svg'),
  channelChat: require('../../../../assets/src/icon20/channelChat.svg'),
  channelEmail: require('../../../../assets/src/icon20/channelEmail.svg'),
  channels: require('../../../../assets/src/icon20/channels.svg'),
  channelsFilled: require('../../../../assets/src/icon20/channelsFilled.svg'),
  changeStatus: require('../../../../assets/src/icon20/changeStatus.svg'),
  changeStatusFilled: require('../../../../assets/src/icon20/changeStatusFilled.svg'),
  chat: require('../../../../assets/src/icon20/chat.svg'),
  chatFilled: require('../../../../assets/src/icon20/chatFilled.svg'),
  chatSendFilled: require('../../../../assets/src/icon20/chatSendFilled.svg'),
  checkmark: require('../../../../assets/src/icon20/checkmark.svg'),
  chevron: require('../../../../assets/src/icon20/chevron.svg'),
  chevronRight: require('../../../../assets/src/icon20/chevronRight.svg'),
  clock: require('../../../../assets/src/icon20/clock.svg'),
  close: require('../../../../assets/src/icon20/close.svg'),
  code: require('../../../../assets/src/icon20/code.svg'),
  collapse: require('../../../../assets/src/icon20/collapse.svg'),
  compose: require('../../../../assets/src/icon20/compose.svg'),
  composeFilled: require('../../../../assets/src/icon20/composeFilled.svg'),
  contacts: require('../../../../assets/src/icon20/contacts.svg'),
  contactsCutout: require('../../../../assets/src/icon20/contactsCutout.svg'),
  contactsFilled: require('../../../../assets/src/icon20/contactsFilled.svg'),
  content: require('../../../../assets/src/icon20/content.svg'),
  copy: require('../../../../assets/src/icon20/copy.svg'),
  csatFilled: require('../../../../assets/src/icon20/csatFilled.svg'),
  customViews: require('../../../../assets/src/icon20/customViews.svg'),
  customViewsFilled: require('../../../../assets/src/icon20/customViewsFilled.svg'),
  date: require('../../../../assets/src/icon20/date.svg'),
  dateFilled: require('../../../../assets/src/icon20/dateFilled.svg'),
  developers: require('../../../../assets/src/icon20/developers.svg'),
  download: require('../../../../assets/src/icon20/download.svg'),
  draft: require('../../../../assets/src/icon20/draft.svg'),
  dropdownNew: require('../../../../assets/src/icon20/dropdownNew.svg'),
  dropdownNewInbox: require('../../../../assets/src/icon20/dropdownNewInbox.svg'),
  dropdownNewLink: require('../../../../assets/src/icon20/dropdownNewLink.svg'),
  dropdownNewTag: require('../../../../assets/src/icon20/dropdownNewTag.svg'),
  dropdownNewView: require('../../../../assets/src/icon20/dropdownNewView.svg'),
  dropdownUnassign: require('../../../../assets/src/icon20/dropdownUnassign.svg'),
  dynamicVariable: require('../../../../assets/src/icon20/dynamicVariable.svg'),
  dynamicVariableFilled: require('../../../../assets/src/icon20/dynamicVariableFilled.svg'),
  edit: require('../../../../assets/src/icon20/edit.svg'),
  emoji: require('../../../../assets/src/icon20/emoji.svg'),
  emojiActivity: require('../../../../assets/src/icon20/emojiActivity.svg'),
  emojiFlags: require('../../../../assets/src/icon20/emojiFlags.svg'),
  emojiFood: require('../../../../assets/src/icon20/emojiFood.svg'),
  emojiNature: require('../../../../assets/src/icon20/emojiNature.svg'),
  emojiObjects: require('../../../../assets/src/icon20/emojiObjects.svg'),
  emojiPeople: require('../../../../assets/src/icon20/emojiPeople.svg'),
  emojiSymbols: require('../../../../assets/src/icon20/emojiSymbols.svg'),
  emojiTravel: require('../../../../assets/src/icon20/emojiTravel.svg'),
  endCallOutlined: require('../../../../assets/src/icon20/endCallOutlined.svg'),
  eye: require('../../../../assets/src/icon20/eye.svg'),
  exit: require('../../../../assets/src/icon20/exit.svg'),
  expand: require('../../../../assets/src/icon20/expand.svg'),
  export: require('../../../../assets/src/icon20/export.svg'),
  externalLink: require('../../../../assets/src/icon20/externalLink.svg'),
  feather: require('../../../../assets/src/icon20/feather.svg'),
  folder: require('../../../../assets/src/icon20/folder.svg'),
  folderOutline: require('../../../../assets/src/icon20/folderOutline.svg'),
  folderLarge: require('../../../../assets/src/icons/folder.svg'),
  formatting: require('../../../../assets/src/icon20/formatting.svg'),
  formattingBlockquote: require('../../../../assets/src/icon20/formattingQuote.svg'),
  formattingBold: require('../../../../assets/src/icon20/formattingBold.svg'),
  formattingColor: require('../../../../assets/src/icon20/formattingColor.svg'),
  formattingHighlight: require('../../../../assets/src/icon20/formattingHighlight.svg'),
  formattingImage: require('../../../../assets/src/icon20/formattingImage.svg'),
  formattingItalic: require('../../../../assets/src/icon20/formattingItalics.svg'),
  formattingLink: require('../../../../assets/src/icon20/formattingLink.svg'),
  formattingList: require('../../../../assets/src/icon20/formattingBullets.svg'),
  formattingListSmall: require('../../../../assets/src/icon16/formattingBullets.svg'),
  formattingRemove: require('../../../../assets/src/icon20/formattingRemove.svg'),
  formattingStrikethrough: require('../../../../assets/src/icon20/formattingStrikethrough.svg'),
  formattingUnderline: require('../../../../assets/src/icon20/formattingUnderline.svg'),
  gear: require('../../../../assets/src/icon20/gear.svg'),
  gearFilled: require('../../../../assets/src/icon20/gearFilled.svg'),
  goToConversation: require('../../../../assets/src/icon20/goToConversation.svg'),
  gif: require('../../../../assets/src/icon20/gif.svg'),
  graduationCapOutlined: require('../../../../assets/src/icon20/graduationCapOutlined.svg'),
  group: require('../../../../assets/src/icon20/group.svg'),
  guide: require('../../../../assets/src/icon20/guide.svg'),
  help: require('../../../../assets/src/icon20/help.svg'),
  helpFilled: require('../../../../assets/src/icon20/helpFilled.svg'),
  helpIndicator: require('../../../../assets/src/icon20/helpIndicator.svg'),
  history: require('../../../../assets/src/icon20/history.svg'),
  homeFilled: require('../../../../assets/src/icon20/homeFilled.svg'),
  horizontalRule: require('../../../../assets/src/icon20/horizontalRule.svg'),
  imagesEmpty: require('../../../../assets/src/icon20/imagesEmpty.svg'),
  inbox: require('../../../../assets/src/icon20/inbox.svg'),
  inboxFilled: require('../../../../assets/src/icon20/inboxFilled.svg'),
  inflowInbox: require('../../../../assets/src/icon20/inflowInbox.svg'),
  info: require('../../../../assets/src/icon20/info.svg'),
  infoFilled: require('../../../../assets/src/icon20/infoFilled.svg'),
  integration: require('../../../../assets/src/icon20/integration.svg'),
  integrationsFilled: require('../../../../assets/src/icon20/integrationsFilled.svg'),
  keyboard: require('../../../../assets/src/icon20/keyboard.svg'),
  knowledgeBase: require('../../../../assets/src/icon20/knowledgeBase.svg'),
  knowledgeBaseFilled: require('../../../../assets/src/icon20/knowledgeBaseFilled.svg'),
  lightbulb: require('../../../../assets/src/icon20/lightbulb.svg'),
  lightning: require('../../../../assets/src/icon20/lightning.svg'),
  lightningFilled: require('../../../../assets/src/icon20/lightningFilled.svg'),
  like: require('../../../../assets/src/icon20/like.svg'),
  likeFilled: require('../../../../assets/src/icon20/likeFilled.svg'),
  link: require('../../../../assets/src/icon20/link.svg'),
  linkedConversationsFilled: require('../../../../assets/src/icon20/linkedConversationsFilled.svg'),
  list: require('../../../../assets/src/icon20/list.svg'),
  listFilled: require('../../../../assets/src/icon20/listFilled.svg'),
  lock: require('../../../../assets/src/icon20/lock.svg'),
  lockFilled: require('../../../../assets/src/icon20/lockFilled.svg'),
  mention: require('../../../../assets/src/icon20/mention.svg'),
  menu: require('../../../../assets/src/icon20/menu.svg'),
  merge: require('../../../../assets/src/icon20/merge.svg'),
  message: require('../../../../assets/src/icon20/message.svg'),
  messageTemplate: require('../../../../assets/src/icon20/messageTemplate.svg'),
  messageTemplateFilled: require('../../../../assets/src/icon20/messageTemplateFilled.svg'),
  messageTemplateLarge: require('../../../../assets/src/icons/messageTemplate.svg'),
  more: require('../../../../assets/src/icon20/more.svg'),
  moreSmall: require('../../../../assets/src/icon16/moreHorizontal.svg'),
  moreVertical: require('../../../../assets/src/icon20/moreVertical.svg'),
  moveActivityFilled: require('../../../../assets/src/icon20/moveActivityFilled.svg'),
  moveRightFilled: require('../../../../assets/src/icon20/moveRightFilled.svg'),
  mute: require('../../../../assets/src/icon20/mute.svg'),
  muteFilled: require('../../../../assets/src/icon20/muteFilled.svg'),
  new: require('../../../../assets/src/icon20/new.svg'),
  newContact: require('../../../../assets/src/icon20/newContact.svg'),
  newEvent: require('../../../../assets/src/icon20/newevent.svg'),
  noAttachment: require('../../../../assets/src/icon20/noAttachment.svg'),
  none: require('../../../../assets/src/icon20/none.svg'),
  numbers: require('../../../../assets/src/icon20/numbers.svg'),
  openPanel: require('../../../../assets/src/icon20/openPanel.svg'),
  outbox: require('../../../../assets/src/icon20/outbox.svg'),
  palmTree: require('../../../../assets/src/icon20/palmtree.svg'),
  personFilled: require('../../../../assets/src/icon20/personFilled.svg'),
  phone: require('../../../../assets/src/icon20/phone.svg'),
  planet: require('../../../../assets/src/icon20/planet.svg'),
  plusCircle: require('../../../../assets/src/icon20/plusCircle.svg'),
  popin: require('../../../../assets/src/icon20/popin.svg'),
  popout: require('../../../../assets/src/icon20/popout.svg'),
  questionMark: require('../../../../assets/src/icon20/questionMark.svg'),
  reminder: require('../../../../assets/src/icon20/reminder.svg'),
  removeIntegration: require('../../../../assets/src/icon20/removeIntegration.svg'),
  reopen: require('../../../../assets/src/icon20/reopen.svg'),
  repeatCircle: require('../../../../assets/src/icon20/repeatCircle.svg'),
  reply: require('../../../../assets/src/icon20/reply.svg'),
  replyFilled: require('../../../../assets/src/icon20/replyFilled.svg'),
  replyAll: require('../../../../assets/src/icon20/replyAll.svg'),
  resnooze: require('../../../../assets/src/icon20/resnooze.svg'),
  restore: require('../../../../assets/src/icon20/restore.svg'),
  retweet: require('../../../../assets/src/icon20/retweet.svg'),
  retweeted: require('../../../../assets/src/icon20/retweeted.svg'),
  infoHistory: require('../../../../assets/src/icon20/infoHistory.svg'),
  save: require('../../../../assets/src/icon20/save.svg'),
  schedule: require('../../../../assets/src/icon20/schedule.svg'),
  scheduleFilled: require('../../../../assets/src/icon20/scheduleFilled.svg'),
  section: require('../../../../assets/src/icon20/section.svg'),
  sectionFilled: require('../../../../assets/src/icon20/sectionFilled.svg'),
  send: require('../../../../assets/src/icon20/send.svg'),
  sendFilled: require('../../../../assets/src/icon20/sendFilled.svg'),
  sequences: require('../../../../assets/src/icon20/sequences.svg'),
  settings: require('../../../../assets/src/icon20/settings.svg'),
  sidePane: require('../../../../assets/src/icon20/sidePane.svg'),
  sla: require('../../../../assets/src/icon20/sla.svg'),
  slaFilled: require('../../../../assets/src/icon20/slaFilled.svg'),
  snooze: require('../../../../assets/src/icon20/snooze.svg'),
  snoozeFilled: require('../../../../assets/src/icon20/snoozeFilled.svg'),
  sortDown: require('../../../../assets/src/icon20/sortDown.svg'),
  sortDownUnreplied: require('../../../../assets/src/icon20/sortDownUnreplied.svg'),
  sortUp: require('../../../../assets/src/icon20/sortUp.svg'),
  spam: require('../../../../assets/src/icon20/spam.svg'),
  spamFilled: require('../../../../assets/src/icon20/spamFilled.svg'),
  sparklesAi: require('../../../../assets/src/icon20/sparklesAI.svg'),
  sparklesAiFilled: require('../../../../assets/src/icon20/sparklesAIFilled.svg'),
  stageFilled: require('../../../../assets/src/icon20/stageFilled.svg'),
  star: require('../../../../assets/src/icon20/star.svg'),
  starFilled: require('../../../../assets/src/icon20/starFilled.svg'),
  savedFlow: require('../../../../assets/src/icon20/savedFlow.svg'),
  subscribed: require('../../../../assets/src/icon20/subscribed.svg'),
  table: require('../../../../assets/src/icon20/table.svg'),
  tableColumn: require('../../../../assets/src/icon20/tableColumn.svg'),
  tableGrid: require('../../../../assets/src/icon20/tableGrid.svg'),
  tableGridFilled: require('../../../../assets/src/icon20/tableGridFilled.svg'),
  tag: require('../../../../assets/src/icon20/tag.svg'),
  tagFilled: require('../../../../assets/src/icon20/tagFilled.svg'),
  tags: require('../../../../assets/src/icon20/tags.svg'),
  teammateFilled: require('../../../../assets/src/icon20/teammateFilled.svg'),
  teammateRole: require('../../../../assets/src/icon20/teammateRole.svg'),
  teammates: require('../../../../assets/src/icon20/teammates.svg'),
  teammatesFilled: require('../../../../assets/src/icon20/teammatesFilled.svg'),
  template: require('../../../../assets/src/icon20/template.svg'),
  thumbsup: require('../../../../assets/src/icon20/thumbsup.svg'),
  toggles: require('../../../../assets/src/icon20/toggles.svg'),
  togglesFilled: require('../../../../assets/src/icon20/togglesFilled.svg'),
  trash: require('../../../../assets/src/icon20/trash.svg'),
  trashFilled: require('../../../../assets/src/icon20/trashFilled.svg'),
  twitterFilled: require('../../../../assets/src/icon20/twitterFilled.svg'),
  unassign: require('../../../../assets/src/icon20/unassign.svg'),
  unlinkedConversationsFilled: require('../../../../assets/src/icon20/unlinkedConversationsFilled.svg'),
  untag: require('../../../../assets/src/icon20/untag.svg'),
  variable: require('../../../../assets/src/icon20/variable.svg'),
  video: require('../../../../assets/src/icon20/video.svg'),
  visitorSourceUrl: require('../../../../assets/src/icon20/visitorSourceUrl.svg'),
  warningFilledTriangle: require('../../../../assets/src/icon20/warningFilledTriangle.svg'),
  savedViewFilled: require('../../../../assets/src/icon20/savedViewsFilled.svg'),

  crossCircle: require('../../../../assets/src/icons/crossCircle.svg'),
  downloadCircle: require('../../../../assets/src/icons/download.svg'),
  externalLinkCircle: require('../../../../assets/src/icons/externalLinkCircle.svg'),
  loaderRetry: require('../../../../assets/src/icons/loaderRetry.svg'),
  loaderRetrySmall: require('../../../../assets/src/icons/loaderRetrySmall.svg'),
  calendarSelected: require('../../../../assets/src/icons/calendarSelected.svg'),
  replyArrow: require('../../../../assets/src/icons/replyArrow.svg'),

  // Non-standard icons (other than 16 and 20).
  // To be moved to instant export.
  northEastTriangle: require('../../../../assets/src/shapes/northEastTriangle.svg'),

  // Windows controls icons.
  windowsMinimize: require('../../../../assets/src/icons/windowsMinimize.svg'),
  windowsMaximize: require('../../../../assets/src/icons/windowsMaximize.svg'),
  windowsUnmaximize: require('../../../../assets/src/icons/windowsUnmaximize.svg'),
  windowsClose: require('../../../../assets/src/icons/windowsClose.svg'),
});

export type IconName = keyof typeof importedIcons;
export function isIconName(s: any): s is IconName {
  return isString(s) && s in importedIcons;
}

// All the possible shapes available.
export const icons = importedIcons;

/*
 * Props.
 */

export interface IconProps {
  size?: number;
  name: IconName;
  color?: string;
  hoverColor?: string;
  isDisabled?: boolean;
  hasHover?: boolean;
  className?: string;
  isInline?: boolean;
}

/*
 * Component.
 */

interface IconStyleProps {
  $color?: string;
  $hoverColor?: string;
  $isDisabled?: boolean;
  $hasHover?: boolean;
}

const StyledIconSvg = styled.svg<IconStyleProps>`
  ${(p) => maybeAddColor(p)};
  ${(p) => maybeAddHoverStyle(p)};
  display: block;
  flex-shrink: 0;
`;

function maybeAddColor(props: IconStyleProps) {
  if (!props.$color) {
    return undefined;
  }

  return css`
    color: ${props.$color};
  `;
}

function maybeAddHoverStyle(props: IconStyleProps) {
  if (!props.$hoverColor || props.$isDisabled) {
    return undefined;
  }

  if (props.$hasHover) {
    return css`
      color: ${props.$hoverColor};
    `;
  }

  return css`
    div:hover > && {
      color: ${props.$hoverColor};
    }
  `;
}

/** Use this component to display an icon available in the map above in this file. */
export const Icon = memo<IconProps>((props) => {
  const {size, name, className, isInline} = props;
  const {content, attributes} = icons[name];

  const contentId = `icon-${name}`;
  const hasSvgContent = useSvgContent(contentId, () => content);

  const {viewBox, stroke, width, height} = buildIconAttributes(attributes, size);

  if (hasSvgContent && !isInline) {
    return (
      <StyledIconSvg
        $color={props.color}
        $hoverColor={props.hoverColor}
        $isDisabled={props.isDisabled}
        $hasHover={props.hasHover}
        className={className}
        width={width}
        height={height}
        fill="currentColor"
        stroke={stroke}
        viewBox={viewBox}
        data-testid={name}
      >
        <use href={`#${contentId}`} />
      </StyledIconSvg>
    );
  }

  return (
    <StyledIconSvg
      $color={props.color}
      $hoverColor={props.hoverColor}
      $isDisabled={props.isDisabled}
      $hasHover={props.hasHover}
      className={className}
      width={width}
      height={height}
      fill="currentColor"
      stroke={stroke}
      viewBox={viewBox}
      data-testid={name}
      dangerouslySetInnerHTML={buildCleanReactInnerHtml(content)}
    />
  );
});
