/**
 * Attribute identifying keyboard navigation items.
 *
 * Do not use this attribute to apply styles, use buildHighlightedStyles and
 * the highlightedClassName instead.
 */
export const KEYBOARD_ITEM_DATA_ATTRIBUTE = 'data-keyboard-item';
export const KEYBOARD_ITEM_SELECTOR = `[${KEYBOARD_ITEM_DATA_ATTRIBUTE}]`;

/**
 * Attribute set when an item is in the viewable area of its scroll parent. If there's
 * no scroll parent, this attribute is always set.
 *
 * Do not use this attribute to apply styles, use buildHighlightedStyles and
 * the highlightedClassName instead.
 */
export const KEYBOARD_ITEM_INTERSECTING_DATA_ATTRIBUTE = 'data-keyboard-item-intersecting';
export const KEYBOARD_ITEM_INTERSECTING_SELECTOR = `[${KEYBOARD_ITEM_INTERSECTING_DATA_ATTRIBUTE}]`;

/** Defines directions users can navigate in with the arrow keys. */
export enum DirectionsEnum {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

export type VerticalDirection = DirectionsEnum.UP | DirectionsEnum.DOWN;
