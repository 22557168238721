import {lightBrandPalette} from '../../styles/brandColors';
import {greys} from '../../styles/colorStyles';

export const lightTooltipSemanticStyles = {
  backgroundColor: greys.shade90,
  textColor: greys.white,
  listTitleColor: greys.shade60,
  textBoxBackgroundColor: greys.shade80,
  parentTagColor: greys.shade50,
};

export type TooltipSemanticStyles = typeof lightTooltipSemanticStyles;

export const darkTooltipSemanticStyles: TooltipSemanticStyles = {
  backgroundColor: greys.shade10,
  textColor: greys.shade90,
  listTitleColor: greys.shade60,
  textBoxBackgroundColor: greys.shade30, // This has not been defined by design
  parentTagColor: greys.shade70,
};

export const upsellTooltipSemanticStyles = {
  backgroundColor: lightBrandPalette.violets.shade100,
  textColor: lightBrandPalette.violets.shade800,
  titleColor: lightBrandPalette.violets.shade900,
  titleIconColor: lightBrandPalette.violets.shade700,
};

export type UpsellTooltipSemanticStyles = typeof upsellTooltipSemanticStyles;
