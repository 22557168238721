import {CancelToken} from 'axios';
import * as t from 'io-ts';

import {ApiType} from './apiType';
import {deleteAsync, fetchApiAsync, HttpSendOptions, patchApiAsync, postApiAsync} from './http';
import {BaseUrlsEnum} from './httpConstants';

export async function deleteSchedulingApiAsync<T extends t.Mixed>(
  path: string,
  cancelToken?: CancelToken,
): Promise<t.TypeOf<T>> {
  return deleteAsync({
    baseURL: BaseUrlsEnum.SCHEDULING_API_BASE_URL,
    path,
    cancelToken,
  });
}

export async function fetchSchedulingApiAsync<T extends t.Mixed>(
  apiType: ApiType<T>,
  path: string,
  cancelToken?: CancelToken,
): Promise<t.TypeOf<T>> {
  return fetchApiAsync(apiType, {
    baseURL: BaseUrlsEnum.SCHEDULING_API_BASE_URL,
    path,
    cancelToken,
  });
}

export async function postSchedulingApiAsync<T extends t.Mixed>(
  apiType: ApiType<T>,
  options: HttpSendOptions,
): Promise<t.TypeOf<T>> {
  return postApiAsync(apiType, {...options, baseURL: BaseUrlsEnum.SCHEDULING_API_BASE_URL});
}

export async function patchSchedulingApiAsync<T extends t.Mixed>(
  apiType: ApiType<T>,
  options: HttpSendOptions,
): Promise<t.TypeOf<T>> {
  return patchApiAsync(apiType, {...options, baseURL: BaseUrlsEnum.SCHEDULING_API_BASE_URL});
}
