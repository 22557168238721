import {darkBrandPalette, lightBrandPalette} from '../styles/brandColors';
import {alphas, greys, palette} from '../styles/colorStyles';
import {AddOnPillSemanticStyles} from './semantic/addOnPillSemanticStyles';
import {AnnotationSemanticStyles} from './semantic/annotationSemanticStyles';
import {AppFrameSemanticStyles} from './semantic/appFrameSemanticStyles';
import {AvatarSemanticStyles} from './semantic/avatarSemanticStyles';
import {BannerSemanticStyles} from './semantic/bannerSemanticStyles';
import {BetaFeaturePillSemanticStyles} from './semantic/betaFeaturePillSemanticStyles';
import {ButtonSemanticStyles} from './semantic/buttonSemanticStyles';
import {CellSemanticStyles} from './semantic/cellSemanticStyles';
import {CheckboxSemanticStyles} from './semantic/checkboxSemanticStyles';
import {CommentComposerSemanticStyles} from './semantic/commentComposerSemanticStyles';
import {InlineBannerSemanticStyles} from './semantic/inlineBannerSemanticStyles';
import {LeftBarSemanticStyles} from './semantic/leftBarSemanticStyles';
import {LegacyPillSemanticStyles} from './semantic/legacyPillSemanticStyles';
import {MessageSemanticStyles} from './semantic/messageSemanticStyles';
import {NewFeaturePillSemanticStyles} from './semantic/newFeaturePillSemanticStyles';
import {NotificationIndicatorSemanticStyles} from './semantic/notificationIndicatorSemanticStyles';
import {SearchBarSemanticStyles} from './semantic/searchBarSemanticStyles';
import {SeparatorSemanticStyles} from './semantic/separatorSemanticStyles';
import {SettingsSemanticStyles} from './semantic/settingsSemanticStyles';
import {TagSemanticStyles} from './semantic/tagSemanticStyles';
import {TooltipSemanticStyles, UpsellTooltipSemanticStyles} from './semantic/tooltipSemanticStyles';
import {TopBarSemanticStyles} from './semantic/topBarSemanticStyles';
import {VerticalSwitcherSemanticStyles} from './semantic/verticalSwitcherSemanticStyles';
import {themeConstants} from './themeConstants';

export enum ThemeTypesEnum {
  LIGHT = 'light',
  DARK = 'dark',
  EXPANDED_DARK = 'expandedDark',
  DARK_SIDEBAR = 'darkSidebar',
}

export interface ThemeType {
  type: ThemeTypesEnum;
}

/*
 * Semantic styles implemented as a part of the dark mode expansion. These
 * use the new dark palette in dark mode.
 */
export interface SemanticStyles {
  brand: string;

  appBackground: string;
  foreground: string;

  text: string;
  composerText: string;
  composerPlaceholder: string;

  interactiveContainer: string;
  interactiveText: string;
  interactiveTextHover: string;
  interactiveIcon: string;
  interactiveBorders: string;

  invertedText: string;
  invertedSubtext: string;
  invertedIcon: string;

  urlTooltipMinorText: string;
  modalBackdrop: string;

  colorScheme: string;

  inputBackground: string;
  windowsButtonHover: string;

  highlightText: string;

  scrollbarTrack: string;
  scrollbarThumb: string;
  scrollbarThumbHover: string;

  refinedP2ResultsIndicator: string;

  cell: CellSemanticStyles;
  separator: SeparatorSemanticStyles;
  topBar: TopBarSemanticStyles;
  searchBar: SearchBarSemanticStyles;
  tag: TagSemanticStyles;
  avatar: AvatarSemanticStyles;
  message: MessageSemanticStyles;
  banner: BannerSemanticStyles;
  inlineBanner: InlineBannerSemanticStyles;
  button: ButtonSemanticStyles;
  annotation: AnnotationSemanticStyles;
  commentComposer: CommentComposerSemanticStyles;
  leftBar: LeftBarSemanticStyles;
  settings: SettingsSemanticStyles;
  checkbox: CheckboxSemanticStyles;
  appFrame: AppFrameSemanticStyles;
  tooltip: TooltipSemanticStyles;
  upsellTooltip: UpsellTooltipSemanticStyles;

  verticalSwitcher: VerticalSwitcherSemanticStyles;

  // Pill.
  betaFeaturePill: BetaFeaturePillSemanticStyles;
  newFeaturePill: NewFeaturePillSemanticStyles;
  addOnPill: AddOnPillSemanticStyles;
  legacyPill: LegacyPillSemanticStyles;

  notificationIndicator: NotificationIndicatorSemanticStyles;
}

export interface ColorStyles {
  greys: typeof greys;
  alphas: typeof alphas;
  palette: typeof palette;
  brandPalette: typeof lightBrandPalette | typeof darkBrandPalette;
}

export interface ColorConstants {
  constants: typeof themeConstants;
}

export type UserTheme = Readonly<SemanticStyles & ColorStyles & ThemeType & ColorConstants>;
