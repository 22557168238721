import {lightBrandPalette} from '../../styles/brandColors';
import {alphas, greys, palette} from '../../styles/colorStyles';
import {ColorStyles} from '../themeTypes';

// TODO: Move color styles into this file
export const lightColors: ColorStyles = {
  greys,
  alphas,
  palette,
  brandPalette: lightBrandPalette,
};
