import {themeConstants} from '../themeConstants';
import {ThemeTypesEnum, UserTheme} from '../themeTypes';
import {lightColors} from './lightColors';
import {lightSemanticStyles} from './lightSemanticStyles';

export const lightTheme: UserTheme = {
  ...lightSemanticStyles,
  ...lightColors,
  constants: themeConstants,
  type: ThemeTypesEnum.LIGHT,
};
