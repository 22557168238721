import * as t from 'io-ts';

import {makeEnumTypeGuard} from '../../models/api/types/enumTypes';

/*
 * Types.
 */

export enum LogLevelsEnum {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

const RLogMessageContextValue: t.Type<LogMessageContextValue> = t.recursion('LogMessageContextValue', () =>
  t.union([
    t.string,
    t.number,
    t.boolean,
    t.undefined,
    t.null,
    RLogMessageContext,
    t.readonlyArray(RLogMessageContextValue),
  ]),
);

export type LogMessageContextValue =
  | string
  | number
  | boolean
  | LogMessageContext
  | LogMessageContextArray
  | undefined
  | null;
interface LogMessageContextArray extends ReadonlyArray<LogMessageContextValue> {}

export interface LogMessageContext extends t.TypeOf<typeof RLogMessageContext> {}
export const RLogMessageContext = t.record(t.string, RLogMessageContextValue);

export type LogMethod = (message: string, context?: LogMessageContext) => void;

export interface DatadogUserConfiguration {
  clientToken: string;
  sampleRate?: number;
  site?: string;
  env?: string;
  forwardErrorsToLogs?: boolean;
}

export interface DatadogLogger {
  initMobileAsync: (userConfiguration: DatadogUserConfiguration) => Promise<void>;
  initDesktop: (userConfiguration: DatadogUserConfiguration) => void;
  log: (
    message: string,
    context: LogMessageContext | undefined,
    methodName: LogLevelsEnum,
    loggerName?: string | symbol,
  ) => void;
  setClientAttributes: () => void;
  setLoggerGlobalContext: (newContext: LogMessageContext) => void;
  addLoggerGlobalContext: (key: string, value: LogMessageContextValue) => void;
}

/*
 * Helpers.
 */

export const isDatadogStatus = makeEnumTypeGuard(LogLevelsEnum);
