import {DARK_APP_FOREGROUND_COLOR} from '../dark/darkColorHelpers';
import {LIGHT_APP_FOREGROUND_COLOR} from '../light/lightColorHelpers';

export const lightAppFrameSemanticStyles = {
  backgroundColor: LIGHT_APP_FOREGROUND_COLOR,
};

export type AppFrameSemanticStyles = typeof lightAppFrameSemanticStyles;

export const darkAppFrameSemanticStyles: AppFrameSemanticStyles = {
  // Most UIs assume the app frame has a white background. Because of this, we only
  // set a dark background color if an expanded dark theme is provided.
  backgroundColor: LIGHT_APP_FOREGROUND_COLOR,
};

export const expandedDarkAppFrameSemanticStyles: AppFrameSemanticStyles = {
  backgroundColor: DARK_APP_FOREGROUND_COLOR,
};
