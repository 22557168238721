import styled from 'styled-components';

import {CalendarDataProps, CalendarInviteResponsesEnum} from './calendarType';

const responseHeaderStyle = (props: CalendarDataProps) => {
  const {hasError, response} = props;
  if (hasError) {
    return '#f6c19a, #f6c19a';
  }
  if (response === CalendarInviteResponsesEnum.ACCEPTED) {
    return '#e6f8f0 0%, #c1e8cf 50%, #52b96b 100%';
  }
  if (response === CalendarInviteResponsesEnum.DECLINED) {
    return '#fc1a01 0%, #ffbbb6 50%, #fef1ef 100%';
  }
  if (response === CalendarInviteResponsesEnum.TENTATIVE) {
    return '#fbf4d7 0%, #efc84f 50%, #fffadd 100%';
  }
  return '#f6c19a, #f6c19a';
};

export const GradientHeaderBarDiv = styled.div<CalendarDataProps>`
  border-radius: 16px 16px 0 0;
  height: 16px;
  background: linear-gradient(to left, ${(props) => responseHeaderStyle(props)});
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: 0.2s fadeIn ease-in-out forwards;
`;
