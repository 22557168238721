import {SideObject} from '@floating-ui/react-dom';
import {isNumber} from 'lodash';
import React, {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';

import {KeyboardActionsEnum} from '../../../../core/src/helpers/browser/keyboardHelpers';
import {buildInteractionIdProps} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {LegacyInteractionParent} from '../../interactionTracking/interactionParent';
import {shadows} from '../../styles/colorStyles';
import {KeyboardShortcuts} from '../keyboard/keyboardShortcuts';
import {RepositionPopover} from '../popovers/repositionPopover';
import {Anchor} from '../reposition/repositionHelpers';

/*
 * Props.
 */

// Limit the possible placements for dropdowns. We don't want dropdowns to be centered.
export type DropdownPlacement =
  | 'bottom-start'
  | 'bottom-end'
  | 'top-start'
  | 'top-end'
  | 'right-start'
  | 'left-start'
  // Questionable, but used by chatbot flow builder.
  | 'left';

export interface DropdownInheritableProps {
  anchor: Anchor;
  onRequestClose?: () => void;
  placement?: DropdownPlacement;
}

export interface DropdownProps extends DropdownInheritableProps {
  isExclusive?: boolean;
  isKeyboardExclusive?: boolean;
  hasBackground?: boolean;
  preventOverflowPadding?: Partial<SideObject>;
  shouldUntether?: boolean;
  shouldSkipApplyMaxSize?: boolean;
  isInline?: boolean;
  shouldHideSideMargin?: boolean;

  width?: number | string;
  height?: number;
  maxWidth?: number;
  minWidth?: number;
  maxHeight?: number;
  interactionId?: string;
  className?: string;
}

/*
 * Constants.
 */

const defaultPlacement: DropdownPlacement = 'bottom-start';

/*
 * Style.
 */

interface DropdownStyleProps {
  $width?: number | string;
  $height?: number | string;
  $maxWidth?: number | string;
  $minWidth?: number | string;
  $maxHeight?: number | string;
  $placement: DropdownPlacement;
  $shouldHideSideMargin: boolean | undefined;
}

const StyledDropdownDiv = styled.div<DropdownStyleProps>`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  border-radius: 8px;
  background-color: ${(p) => p.theme.foreground};
  color: ${(p) => p.theme.text};
  ${shadows.dialogsAndDropdowns};

  ${(p) => !p.$shouldHideSideMargin && getSideMargin(p.$placement)}

  width: ${(p) => formatSize(p.$width)};
  height: ${(p) => formatSize(p.$height)};
  max-width: ${(p) => formatSize(p.$maxWidth)};
  min-width: ${(p) => formatSize(p.$minWidth)};
  max-height: ${(p) => formatSize(p.$maxHeight)};
`;

const formatSize = (size: number | string | undefined) => {
  if (size === undefined || size === null) {
    return size;
  }

  if (isNumber(size)) {
    return `${size}px`;
  }

  return `${size}`;
};

const getSideMargin = (placement: DropdownPlacement) => {
  const defaultMargin = 'margin: 5px 0;';
  if (!placement) {
    return defaultMargin;
  }

  if (placement.indexOf('right') !== -1) {
    return 'margin-left: 5px;';
  }

  if (placement.indexOf('left') !== -1) {
    return 'margin-right: 5px;';
  }

  return defaultMargin;
};

/*
 * Component.
 */

export const Dropdown: FC<PropsWithChildren<DropdownProps>> = (props) => {
  const {
    onRequestClose,
    isExclusive = true,
    isKeyboardExclusive = true,
    placement = defaultPlacement,
  } = props;

  const keyHandlers = {
    [KeyboardActionsEnum.CLOSE_LEGACY]: () => {
      if (onRequestClose) {
        onRequestClose();
      }
    },
  };

  const interactionId = props.interactionId || 'dropdown';
  const interactionProps = buildInteractionIdProps(interactionId);

  return (
    <KeyboardShortcuts handlers={keyHandlers} requestExclusivity={isKeyboardExclusive}>
      <LegacyInteractionParent interactionId={interactionId}>
        <RepositionPopover {...props} isExclusive={isExclusive} placement={placement}>
          <StyledDropdownDiv
            $width={props.width}
            $height={props.height}
            $maxWidth={props.maxWidth}
            $minWidth={props.minWidth}
            $maxHeight={props.maxHeight}
            $placement={placement}
            $shouldHideSideMargin={props.shouldHideSideMargin}
            className={props.className}
            {...interactionProps}
          >
            {props.children}
          </StyledDropdownDiv>
        </RepositionPopover>
      </LegacyInteractionParent>
    </KeyboardShortcuts>
  );
};
