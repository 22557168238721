import {alphas, greys} from '../../styles/colorStyles';

export const lightSettingsSemanticStyles = {
  menuSwitcherBackground: alphas.gray10,
  menuSwitcherBlackForeground: greys.shade70,
  menuSwitcherBlackForegroundHover: greys.shade80,
  menuSwitcherBlackSelectedForeground: greys.shade30,
  menuSwitcherBlackSelectedBackground: greys.black,
};

export type SettingsSemanticStyles = typeof lightSettingsSemanticStyles;

export const darkSettingsSemanticStyles: SettingsSemanticStyles = {
  menuSwitcherBackground: alphas.white20,
  menuSwitcherBlackForeground: alphas.white40,
  menuSwitcherBlackForegroundHover: alphas.white50,
  menuSwitcherBlackSelectedForeground: greys.black,
  menuSwitcherBlackSelectedBackground: greys.white,
};
