import {noop} from 'lodash';
import {createContext, useContext} from 'react';

import {Unsubscriber} from '../../../../core/src/helpers/react/reactHelpers';

export type KeyboardNavigationOnHighlightChangeCallback = (
  isHighlighted: boolean,
  isMouseHover: boolean,
) => void;
export type KeyboardNavigationOnHighlightChange = (
  id: string,
  callback: KeyboardNavigationOnHighlightChangeCallback,
) => Unsubscriber;

export type KeyboardNavigationOnSelectCallback = () => void;
export type KeyboardNavigationOnSelect = (
  id: string,
  callback: KeyboardNavigationOnSelectCallback,
) => Unsubscriber;

export interface KeyboardNavigationContextProps {
  /** Registers a callback which is called when the user picks an item. */
  addSelectionListener: KeyboardNavigationOnSelect;

  /** Registers a callback which is called when the user's highlighted item changes. */
  addHighlightChangeListener: KeyboardNavigationOnHighlightChange;

  /** Notifies the KeyboardNavigationCoordinator that browser focus is set to the item. */
  onFocus: (id: string) => void;

  /**
   * Starts the intersection observer for the given element, and registers a callback which
   * is called when the element is visible within its scroll parent.
   */
  watchForIntersection: (element: HTMLElement, callback: (isIntersecting: boolean) => void) => Unsubscriber;

  /** Notifies the KeyboardNavigationCoordinator that the item can be cleaned up. */
  onItemUnmount: (id: string) => void;
}

const defaultKeyboardNavigationContext: KeyboardNavigationContextProps = {
  addSelectionListener: () => noop,
  addHighlightChangeListener: () => noop,
  onFocus: noop,
  watchForIntersection: () => noop,
  onItemUnmount: noop,
};

/**
 * Internally used by the KeyboardNavigation system to allow communication between the top level
 * KeyboardNavigationCoordinator and the individual KeyboardNavigation items defined by
 * the useKeyboardNavigation hook. Should not typically be used directly by consumers.
 */
export const KeyboardNavigationContext = createContext<KeyboardNavigationContextProps>(
  defaultKeyboardNavigationContext,
);

export function useKeyboardNavigationContext() {
  return useContext(KeyboardNavigationContext);
}

export function useHasKeyboardNavigationProvider() {
  return useKeyboardNavigationContext() !== defaultKeyboardNavigationContext;
}
