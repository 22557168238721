import normalized from 'normalize.css/normalize.css';
import React, {FC, memo} from 'react';
import {createGlobalStyle, css} from 'styled-components';

import {customFonts} from '../components/fonts/customFonts';
import {layerClassName} from '../components/layers/layer';
import {useCss} from '../css/cssContext';
import {lightTheme} from '../theme/light/lightTheme';
import {fonts, fontSizes} from './fontStyles';
import {addThemedScrollbarStyles, getHighlighterCss} from './mixins';

export const appStyles = css`
  font-family: ${fonts.system};
  font-size: ${fontSizes.medium};

  line-height: 1.2;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  div[contenteditable],
  input {
    text-rendering: auto !important;
  }

  /*
   * Reset.
   */

  * {
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  *:not(body) {
    background-repeat: no-repeat;
  }

  a {
    text-decoration: none;
  }

  /*
   * Layers.
   */

  .${layerClassName} {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    /* Creates a new stacking context on browsers that don't yet support contain: strict. */
    isolation: isolate;
    /* Optimizes rendering, creates a new stacking context, and fixes a flickering issue in Safari 17.2+. */
    /* This causes a flickering issue in Firefox, so let's pray isolation: isolate is enough for Safari. */
    /* contain: strict; */
  }

  body > .${layerClassName} {
    width: 100vw;
    width: 100svw;
    height: 100vh;
    height: 100svh;
  }

  ${customFonts}

  [class*="-active-highlight"] {
    position: relative;

    ${getHighlighterCss()}

    &.button-highlighter {
      &:before {
        border-radius: 15px;
      }
    }

    &.dropdown-item-highlighter {
      &:before {
        border-radius: 8px;
      }
    }
  }
`;

// Avoid removing and re-inserting global styles unnecessarily,
// because it may cause major style recalculation issues.
const InnerGlobalStyles = memo(createGlobalStyle`
  /* This is used for both the main window and for child windows, so we repeat some of what's in index.hbs. */
  html {
    /* Disable scroll bounce on the root element. */
    /* For Safari < 16.0. */
    overflow: hidden;
    overflow: clip;

    /**
     * Many screens were implemented assuming a dark text color and light background color
     * are inherited. Because of this, we set the top level colors to match the light theme
     * regardless of the user's theme preference. UIs which support dark mode explicitly
     * set the text and background colors, so this is safe to default to.
     *
     * This also prevents a dark background from flashing when navigating between pages. Since
     * most of our application does not support full dark mode yet, having the default be a white
     * background looks better.
     */
    color: ${lightTheme.text};
    background: ${lightTheme.foreground};

    ${appStyles}

    ${addThemedScrollbarStyles()};
  }

  body {
    margin: 0;
  }

  /* For backward compatibility, in case anything is measuring window.document.body. */
  html, body {
    width: 100vw;
    width: 100svw;
    height: 100vh;
    height: 100svh;
  }
`);

export const NormalizedStyles: FC = () => {
  useCss(normalized);
  return null;
};

export const GlobalStyles: FC = () => (
  <>
    <NormalizedStyles />
    <InnerGlobalStyles />
  </>
);
