import {autoPlacement, flip, MiddlewareData, Placement, VirtualElement} from '@floating-ui/react-dom';
import {CSSProperties, RefObject} from 'react';

import {useRepositionBoundary} from './useRepositionBoundary';

/*
 * Types.
 */

export type BoundsRect = DOMRectReadOnly;
export type BoundsRectProvider = () => BoundsRect;
export type Anchor = RefObject<HTMLElement> | BoundsRectProvider | VirtualElement;
export type ReferenceObject = VirtualElement | Element;
export interface RepositionArrowProps {
  style?: CSSProperties;
  ref?: RefObject<HTMLDivElement>;
}
export type RepositionPlacement = Placement | 'auto';

/*
 * API.
 */

export function buildReferenceObject(anchor: Anchor): ReferenceObject {
  // If this is a rectangle, build the reference object.
  if (typeof anchor === 'function') {
    return {
      getBoundingClientRect: anchor,
    };
  }

  // Anchor is a VirtualElement
  if (!('current' in anchor)) {
    return anchor;
  }

  const currentAnchor = anchor.current;
  if (!currentAnchor) {
    throw new Error('The provided anchor Ref does not have a value.');
  }

  return currentAnchor;
}

const placementAlignmentMap = {
  auto: undefined,
  'auto-start': 'start',
  'auto-end': 'end',
} as const;

export function useParsePlacement(
  placement: RepositionPlacement | undefined,
  ignoreRepositionBoundary: boolean | undefined,
) {
  const boundary = useRepositionBoundary(ignoreRepositionBoundary);
  switch (placement) {
    case 'auto':
      return {
        flipMiddleware: autoPlacement({
          alignment: placementAlignmentMap[placement],
          boundary,
        }),
        boundary,
      };
    default:
      return {
        flipMiddleware: flip({boundary}),
        parsedPlacement: placement,
        boundary,
      };
  }
}

export function buildRepositionArrowStyles(
  arrowRef: RefObject<HTMLDivElement>,
  middlewareData: MiddlewareData,
) {
  return {
    ref: arrowRef,
    style: {
      position: 'absolute',
      top: `${middlewareData.arrow?.y}px`,
      left: `${middlewareData.arrow?.x}px`,
    } satisfies CSSProperties,
  };
}
