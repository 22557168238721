import {alphas, alphaWhite, brandColor, greys, palette} from '../../styles/colorStyles';
import {lightAddOnPillSemanticStyles} from '../semantic/addOnPillSemanticStyles';
import {lightAnnotationSemanticStyles} from '../semantic/annotationSemanticStyles';
import {lightAppFrameSemanticStyles} from '../semantic/appFrameSemanticStyles';
import {lightAvatarSemanticStyles} from '../semantic/avatarSemanticStyles';
import {lightBannerSemanticStyles} from '../semantic/bannerSemanticStyles';
import {lightBetaFeaturePillSemanticStyles} from '../semantic/betaFeaturePillSemanticStyles';
import {lightButtonSemanticStyles} from '../semantic/buttonSemanticStyles';
import {lightCellSemanticStyles} from '../semantic/cellSemanticStyles';
import {lightCheckboxSemanticStyles} from '../semantic/checkboxSemanticStyles';
import {lightCommentComposerSemanticStyles} from '../semantic/commentComposerSemanticStyles';
import {lightInlineBannerSemanticStyles} from '../semantic/inlineBannerSemanticStyles';
import {lightLeftBarSemanticStyles} from '../semantic/leftBarSemanticStyles';
import {lightLegacyPillSemanticStyles} from '../semantic/legacyPillSemanticStyles';
import {lightMessageSemanticStyles} from '../semantic/messageSemanticStyles';
import {lightNewFeaturePillSemanticStyles} from '../semantic/newFeaturePillSemanticStyles';
import {lightNotificationIndicatorSemanticStyles} from '../semantic/notificationIndicatorSemanticStyles';
import {sharedSearchBarSemanticStyles} from '../semantic/searchBarSemanticStyles';
import {lightSeparatorSemanticStyles} from '../semantic/separatorSemanticStyles';
import {lightSettingsSemanticStyles} from '../semantic/settingsSemanticStyles';
import {lightTagSemanticStyles} from '../semantic/tagSemanticStyles';
import {lightTooltipSemanticStyles, upsellTooltipSemanticStyles} from '../semantic/tooltipSemanticStyles';
import {sharedTopBarSemanticStyles} from '../semantic/topBarSemanticStyles';
import {lightVerticalSwitcherSemanticStyles} from '../semantic/verticalSwitcherSemanticStyles';
import {SemanticStyles} from '../themeTypes';
import {LIGHT_APP_FOREGROUND_COLOR} from './lightColorHelpers';

export const lightSemanticStyles: SemanticStyles = {
  brand: brandColor,

  appBackground: greys.shade20,
  foreground: LIGHT_APP_FOREGROUND_COLOR,

  text: greys.shade80,
  composerText: greys.shade100,
  composerPlaceholder: greys.shade60,

  interactiveContainer: palette.blue.shade40,
  interactiveText: palette.blue.shade40,
  interactiveTextHover: palette.blue.shade50,
  interactiveIcon: palette.blue.shade40,
  interactiveBorders: palette.blue.shade40,

  invertedText: greys.white,
  invertedSubtext: alphas.white50,
  invertedIcon: alphas.white50,

  modalBackdrop: alphas.black50,

  colorScheme: 'normal',

  urlTooltipMinorText: alphaWhite(0.7),
  inputBackground: greys.shade20,
  windowsButtonHover: alphas.white20,

  highlightText: greys.shade90,

  scrollbarTrack: alphas.black20,
  scrollbarThumb: alphas.black30,
  scrollbarThumbHover: alphas.black40,

  refinedP2ResultsIndicator: palette.blue.shade40,

  cell: lightCellSemanticStyles,
  separator: lightSeparatorSemanticStyles,
  topBar: sharedTopBarSemanticStyles,
  searchBar: sharedSearchBarSemanticStyles,
  tag: lightTagSemanticStyles,
  avatar: lightAvatarSemanticStyles,
  message: lightMessageSemanticStyles,
  banner: lightBannerSemanticStyles,
  inlineBanner: lightInlineBannerSemanticStyles,
  button: lightButtonSemanticStyles,
  annotation: lightAnnotationSemanticStyles,
  commentComposer: lightCommentComposerSemanticStyles,
  leftBar: lightLeftBarSemanticStyles,
  settings: lightSettingsSemanticStyles,
  checkbox: lightCheckboxSemanticStyles,
  appFrame: lightAppFrameSemanticStyles,
  tooltip: lightTooltipSemanticStyles,
  upsellTooltip: upsellTooltipSemanticStyles,

  verticalSwitcher: lightVerticalSwitcherSemanticStyles,

  betaFeaturePill: lightBetaFeaturePillSemanticStyles,
  newFeaturePill: lightNewFeaturePillSemanticStyles,
  addOnPill: lightAddOnPillSemanticStyles,
  legacyPill: lightLegacyPillSemanticStyles,

  notificationIndicator: lightNotificationIndicatorSemanticStyles,
};
