export const brandBlues = {
  shade100: '#f0f4fe',
  shade200: '#d4dffc',
  shade300: '#b7ccfa',
  shade400: '#80a4f2',
  shade500: '#5f91ef',
  shade600: '#327eea',
  shade700: '#1658bf',
  shade800: '#0d3883',
  shade900: '#001b38',
} as const;

export const brandBluesDark = {
  shade100: brandBlues.shade900,
  shade200: brandBlues.shade800,
  shade300: brandBlues.shade700,
  shade400: brandBlues.shade600,
  shade500: brandBlues.shade500,
  shade600: brandBlues.shade400,
  shade700: brandBlues.shade300,
  shade800: brandBlues.shade200,
  shade900: brandBlues.shade100,
} as const;

export const brandViolets = {
  shade100: '#f8f1fe',
  shade200: '#e2dcf6',
  shade300: '#d0c6f0',
  shade400: '#af96f6',
  shade500: '#b375f4',
  shade600: '#a857f1',
  shade700: '#8034bf',
  shade800: '#5b1f76',
  shade900: '#300c41',
} as const;

export const brandVioletsDark = {
  shade100: brandViolets.shade900,
  shade200: brandViolets.shade800,
  shade300: brandViolets.shade700,
  shade400: brandViolets.shade600,
  shade500: brandViolets.shade500,
  shade600: brandViolets.shade400,
  shade700: brandViolets.shade300,
  shade800: brandViolets.shade200,
  shade900: brandViolets.shade100,
} as const;

export const brandGreens = {
  shade200: '#c1e8cf',
  shade800: '#1A442C',
} as const;

export const brandGreensDark = {
  shade200: brandGreens.shade800,
} as const;

export const lightBrandPalette = {
  blues: brandBlues,
  violets: brandViolets,
  greens: brandGreens,
} as const;

export const darkBrandPalette = {
  blues: brandBluesDark,
  violets: brandVioletsDark,
  greens: brandGreensDark,
} as const;
