import {useCallback, useLayoutEffect, useRef} from 'react';

/*
 * Types.
 */

type EventHandler<A extends Array<any>, R> = (...args: A) => R;

/*
 * Constants.
 */

const initialEventHandler: EventHandler<[], never> = () => {
  throw new Error('Event handlers wrapped in `useEventHandler` may not be called during rendering');
};

/*
 * Hook.
 */

/**
 * Defines an event handler with an always-stable function identity.
 *
 * This approximates the experimental `useEffectEvent` hook described here:
 * https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
 *
 * For details on when (and when NOT) to use this, see:
 * https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
export function useEventHandler<A extends Array<any>, R>(handler: EventHandler<A, R>): EventHandler<A, R> {
  // Don't initialize the ref to `handler`, because that would be an illegal side-effect.
  const committedHandlerRef = useRef<EventHandler<A, R>>(initialEventHandler);

  // Update the ref as soon as possible after rendering so it can be used in downstream effect hooks.
  useLayoutEffect(() => {
    committedHandlerRef.current = handler;
  });

  return useCallback((...args: A): R => committedHandlerRef.current(...args), []);
}
