import {isElectron} from '../environment/platformHelpers';
import {FrontKeyboardEvent, FrontMouseEvent} from '../react/reactHelpers';
import {metaKey, ModifierKeysEnum} from './modifierKeyPlatformHelpers';

/*
 * Constants.
 */

/** Full list of keyboard actions that components can listen for. */
export enum KeyboardActionsEnum {
  ARCHIVE = 'ARCHIVE',
  ASSIGN = 'ASSIGN',
  ASSIGN_TO_SELF = 'ASSIGN_TO_SELF',
  CAROUSEL_MOVE_LEFT = 'CAROUSEL_MOVE_LEFT',
  CAROUSEL_MOVE_RIGHT = 'CAROUSEL_MOVE_RIGHT',
  CLONE = 'CLONE',
  CLOSE = 'CLOSE',
  CLOSE_LEGACY = 'CLOSE_LEGACY',
  COMPOSE = 'COMPOSE',
  COMPOSE_DISCUSSION = 'COMPOSE_DISCUSSION',
  CONFIRM = 'CONFIRM',
  CONFIRM_WITHOUT_INPUT_CAPTURE = 'CONFIRM_WITHOUT_INPUT_CAPTURE',
  COPY = 'COPY',
  EDITOR_ANSWERS = 'EDITOR_ANSWERS',
  EDITOR_BCC = 'EDITOR_BCC',
  EDITOR_BOLD = 'EDITOR_BOLD',
  EDITOR_CC = 'EDITOR_CC',
  EDITOR_INDENT = 'EDITOR_INDENT',
  EDITOR_INSERT_LINK = 'EDITOR_INSERT_LINK',
  EDITOR_ITALIC = 'EDITOR_ITALIC',
  EDITOR_OUTDENT = 'EDITOR_OUTDENT',
  EDITOR_SUBJECT = 'EDITOR_SUBJECT',
  EDITOR_UNDERLINE = 'EDITOR_UNDERLINE',
  EXPAND_ALL = 'EXPAND_ALL',
  COLLAPSE_ALL = 'COLLAPSE_ALL',
  FOCUS_APPLICATION_MENU = 'FOCUS_APPLICATION_MENU',
  FOCUS_COMMENT = 'FOCUS_COMMENT',
  FORCE_REFETCH_CONVERSATION = 'FORCE_REFETCH_CONVERSATION',
  FORWARD = 'FORWARD',
  FRAME_NEXT = 'FRAME_NEXT',
  FRAME_PREVIOUS = 'FRAME_PREVIOUS',
  GO_TO_CONVERSATION = 'GO_TO_CONVERSATION',
  GO_TO_INBOX = 'GO_TO_INBOX',
  HISTORY_BACK = 'HISTORY_BACK',
  HISTORY_FORWARD = 'HISTORY_FORWARD',
  INFORMATION = 'INFORMATION',
  INVITE = 'INVITE',
  META = 'META',
  MOVE_DOWN = 'MOVE_DOWN',
  MOVE_LEFT = 'MOVE_LEFT',
  MOVE_RIGHT = 'MOVE_RIGHT',
  MOVE_TO_INBOX = 'MOVE_TO_INBOX',
  MOVE_UP = 'MOVE_UP',
  OPEN_LINK = 'OPEN_LINK',
  QUICK_RESPONSE_1 = 'QUICK_RESPONSE_1',
  QUICK_RESPONSE_2 = 'QUICK_RESPONSE_2',
  QUICK_RESPONSE_3 = 'QUICK_RESPONSE_3',
  QUICK_RESPONSE_4 = 'QUICK_RESPONSE_4',
  QUICK_RESPONSE_5 = 'QUICK_RESPONSE_5',
  REDO = 'REDO',
  REMIND = 'REMIND',
  REPLY = 'REPLY',
  REPLY_ALL = 'REPLY_ALL',
  REPLY_ALL_IN_NEW_WINDOW = 'REPLY_ALL_IN_NEW_WINDOW',
  REPLY_IN_NEW_WINDOW = 'REPLY_IN_NEW_WINDOW',
  REPLY_INTRO = 'REPLY_INTRO',
  SEARCH = 'SEARCH',
  SEARCH_IN_CONVERSATION = 'SEARCH_IN_CONVERSATION',
  SELECT_ALL = 'SELECT_ALL',
  SELECT_DOWN = 'SELECT_DOWN',
  SELECT_NEXT_TAB = 'SELECT_NEXT_TAB',
  SELECT_PREVIOUS_TAB = 'SELECT_PREVIOUS_TAB',
  SELECT_UP = 'SELECT_UP',
  SEND = 'SEND',
  SEND_AND_DONE = 'SEND_AND_DONE',
  SEND_AND_SNOOZE = 'SEND_AND_SNOOZE',
  SNOOZE = 'SNOOZE',
  SPAM = 'SPAM',
  STAR = 'STAR',
  SUBSCRIBE = 'SUBSCRIBE',
  SWITCH_TO_ANALYTICS = 'SWITCH_TO_ANALYTICS',
  SWITCH_TO_CALENDAR = 'SWITCH_TO_CALENDAR',
  SWITCH_TO_CONTACTS = 'SWITCH_TO_CONTACTS',
  SWITCH_TO_INBOX = 'SWITCH_TO_INBOX',
  SWITCH_TO_KNOWLEDGE_BASE = 'SWITCH_TO_KNOWLEDGE_BASE',
  TAB = 'TAB',
  TAB_BACK = 'TAB_BACK',
  TAG = 'TAG',
  TOGGLE_EXPANDED_COMMENT_COMPOSER = 'TOGGLE_EXPANDED_COMMENT_COMPOSER',
  TOGGLE_EXPANDED_CONVERSATION_SUMMARY = 'TOGGLE_EXPANDED_CONVERSATION_SUMMARY',
  TOGGLE_HELP = 'TOGGLE_HELP',
  TOGGLE_SIDE_PANEL = 'TOGGLE_SIDE_PANEL',
  TRACE = 'TRACE',
  TRASH = 'TRASH',
  UNDO = 'UNDO',

  /** Full-screen calendar keyboard shortcuts. */
  CALENDAR_GO_TO_TODAY = 'CALENDAR_GO_TO_TODAY',
  CALENDAR_NEXT = 'CALENDAR_NEXT',
  CALENDAR_PREVIOUS = 'CALENDAR_PREVIOUS',
  CALENDAR_WEEK_VIEW = 'CALENDAR_WEEK_VIEW',
  CALENDAR_DAY_VIEW = 'CALENDAR_DAY_VIEW',
  CALENDAR_MONTH_VIEW = 'CALENDAR_MONTH_VIEW',
  CALENDAR_EDIT_EVENT = 'CALENDAR_EDIT_EVENT',
  CALENDAR_DELETE_EVENT = 'CALENDAR_DELETE_EVENT',

  OPEN_PALETTE = 'OPEN_PALETTE',
}

export interface KeyboardShortcut {
  sequence: string;
  shouldCaptureInputs: boolean;
}
export type KeyboardMapping = {[T in KeyboardActionsEnum]: Array<KeyboardShortcut>};

function makeShortcut(sequence: string, shouldCaptureInputs: boolean = false): KeyboardShortcut {
  return {
    sequence,
    shouldCaptureInputs,
  };
}

/** Map of action to keys that we display in the help panel but are provided by the browser. */
const helpMapping = {
  [KeyboardActionsEnum.EDITOR_INDENT]: [makeShortcut('tab')],
  [KeyboardActionsEnum.EDITOR_OUTDENT]: [makeShortcut('shift+tab')],
  [KeyboardActionsEnum.HISTORY_BACK]: [makeShortcut('meta+[')],
  [KeyboardActionsEnum.HISTORY_FORWARD]: [makeShortcut('meta+]')],
  [KeyboardActionsEnum.REDO]: [makeShortcut('meta+shift+z')],
  [KeyboardActionsEnum.OPEN_LINK]: [makeShortcut('meta+click')],
};

/** Map of common action to keys for all shortcut schemes. */
const commonMapping = {
  [KeyboardActionsEnum.CONFIRM]: [makeShortcut('enter', true)],
  [KeyboardActionsEnum.CONFIRM_WITHOUT_INPUT_CAPTURE]: [makeShortcut('enter')],
  [KeyboardActionsEnum.EDITOR_BOLD]: [makeShortcut('meta+b', true)],
  [KeyboardActionsEnum.EDITOR_INSERT_LINK]: [makeShortcut('meta+k', true)],
  [KeyboardActionsEnum.EDITOR_ITALIC]: [makeShortcut('meta+i', true)],
  [KeyboardActionsEnum.EDITOR_UNDERLINE]: [makeShortcut('meta+u', true)],
  [KeyboardActionsEnum.FOCUS_APPLICATION_MENU]: [makeShortcut('alt')],
  [KeyboardActionsEnum.MOVE_LEFT]: [makeShortcut('left', true)],
  [KeyboardActionsEnum.MOVE_RIGHT]: [makeShortcut('right', true)],
  [KeyboardActionsEnum.SEARCH_IN_CONVERSATION]: [makeShortcut('meta+f', true)],
  [KeyboardActionsEnum.TAB]: [makeShortcut('tab', true)],
  [KeyboardActionsEnum.TAB_BACK]: [makeShortcut('shift+tab', true)],
  [KeyboardActionsEnum.SELECT_ALL]: [makeShortcut('meta+a')],
  [KeyboardActionsEnum.TOGGLE_EXPANDED_COMMENT_COMPOSER]: [makeShortcut('meta+.', true)],
  [KeyboardActionsEnum.TOGGLE_EXPANDED_CONVERSATION_SUMMARY]: [makeShortcut('alt+i', false)],
  [KeyboardActionsEnum.TOGGLE_HELP]: [makeShortcut('?')],
  [KeyboardActionsEnum.OPEN_PALETTE]: [
    makeShortcut('meta+k', false),
    makeShortcut('meta+shift+p', true),
    makeShortcut('meta+alt+shift+p', true),
  ],
  [KeyboardActionsEnum.COPY]: [makeShortcut('meta+c')],
  [KeyboardActionsEnum.META]: [makeShortcut('meta')],
};

/** Global map of action to keys that preserves the behavior for existing Front users. */
export const legacyShortcutsMapping: KeyboardMapping = {
  ...helpMapping,
  ...commonMapping,
  [KeyboardActionsEnum.ARCHIVE]: [makeShortcut('e')],
  [KeyboardActionsEnum.ASSIGN_TO_SELF]: [makeShortcut('q')],
  [KeyboardActionsEnum.ASSIGN]: [makeShortcut('w')],
  [KeyboardActionsEnum.CAROUSEL_MOVE_LEFT]: [makeShortcut('left')],
  [KeyboardActionsEnum.CAROUSEL_MOVE_RIGHT]: [makeShortcut('right')],
  [KeyboardActionsEnum.CLONE]: [makeShortcut('meta+shift+f', true)],
  [KeyboardActionsEnum.CLOSE]: [makeShortcut('escape', true)],
  [KeyboardActionsEnum.CLOSE_LEGACY]: [makeShortcut('escape')],
  [KeyboardActionsEnum.COMPOSE_DISCUSSION]: [makeShortcut('d')],
  [KeyboardActionsEnum.COMPOSE]: [makeShortcut('c')],
  [KeyboardActionsEnum.EDITOR_ANSWERS]: [makeShortcut('meta+shift+o', true)],
  [KeyboardActionsEnum.EDITOR_BCC]: [makeShortcut('meta+shift+b', true)],
  [KeyboardActionsEnum.EDITOR_CC]: [makeShortcut('meta+shift+c', true)],
  [KeyboardActionsEnum.EDITOR_SUBJECT]: [makeShortcut('meta+shift+s', true)],
  [KeyboardActionsEnum.EXPAND_ALL]: [makeShortcut(';')],
  [KeyboardActionsEnum.COLLAPSE_ALL]: [makeShortcut(';')],
  [KeyboardActionsEnum.FOCUS_COMMENT]: [makeShortcut('m')],
  [KeyboardActionsEnum.FORCE_REFETCH_CONVERSATION]: [makeShortcut('meta+shift+x', true)],
  [KeyboardActionsEnum.FORWARD]: [makeShortcut('shift+f')],
  [KeyboardActionsEnum.FRAME_NEXT]: [makeShortcut('meta+shift+down')],
  [KeyboardActionsEnum.FRAME_PREVIOUS]: [makeShortcut('meta+shift+up')],
  [KeyboardActionsEnum.GO_TO_CONVERSATION]: [makeShortcut('g c'), makeShortcut('shift+o')],
  [KeyboardActionsEnum.GO_TO_INBOX]: [makeShortcut('g i')],
  [KeyboardActionsEnum.INFORMATION]: [makeShortcut('shift+i')],
  [KeyboardActionsEnum.INVITE]: [makeShortcut('i')],
  [KeyboardActionsEnum.MOVE_DOWN]: [makeShortcut('down', true), makeShortcut('j')],
  [KeyboardActionsEnum.MOVE_TO_INBOX]: [makeShortcut('v')],
  [KeyboardActionsEnum.MOVE_UP]: [makeShortcut('up', true), makeShortcut('k')],
  [KeyboardActionsEnum.QUICK_RESPONSE_1]: [makeShortcut('meta+shift+5', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_2]: [makeShortcut('meta+shift+6', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_3]: [makeShortcut('meta+shift+7', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_4]: [makeShortcut('meta+shift+8', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_5]: [makeShortcut('meta+shift+9', true)],
  [KeyboardActionsEnum.REMIND]: [makeShortcut('meta+shift+l', true)],
  [KeyboardActionsEnum.REPLY_ALL_IN_NEW_WINDOW]: [makeShortcut('shift+a')],
  [KeyboardActionsEnum.REPLY_ALL]: [makeShortcut('a')],
  [KeyboardActionsEnum.REPLY_IN_NEW_WINDOW]: [makeShortcut('shift+r')],
  [KeyboardActionsEnum.REPLY_INTRO]: [makeShortcut('meta+shift+a', true)],
  [KeyboardActionsEnum.REPLY]: [makeShortcut('r')],
  [KeyboardActionsEnum.SEARCH]: [makeShortcut('/')],
  [KeyboardActionsEnum.SELECT_DOWN]: [makeShortcut('shift+down', true), makeShortcut('shift+j')],
  [KeyboardActionsEnum.SELECT_UP]: [makeShortcut('shift+up', true), makeShortcut('shift+k')],
  [KeyboardActionsEnum.SELECT_NEXT_TAB]: [makeShortcut('`')],
  [KeyboardActionsEnum.SELECT_PREVIOUS_TAB]: [makeShortcut('shift+`')],
  [KeyboardActionsEnum.SEND_AND_DONE]: [makeShortcut('meta+enter', true)],
  [KeyboardActionsEnum.SEND_AND_SNOOZE]: [makeShortcut('meta+shift+l', true)],
  [KeyboardActionsEnum.SEND]: [makeShortcut('meta+shift+enter', true)],
  [KeyboardActionsEnum.SNOOZE]: [makeShortcut('s')],
  [KeyboardActionsEnum.SPAM]: [makeShortcut('shift+1')],
  [KeyboardActionsEnum.STAR]: [makeShortcut('alt+s')],
  [KeyboardActionsEnum.SUBSCRIBE]: [makeShortcut('f')],
  [KeyboardActionsEnum.SWITCH_TO_KNOWLEDGE_BASE]: [...maybeMeta5()],
  [KeyboardActionsEnum.SWITCH_TO_ANALYTICS]: [...maybeMeta4()],
  [KeyboardActionsEnum.SWITCH_TO_CALENDAR]: [makeShortcut('meta+shift+2'), ...maybeMeta2()],
  [KeyboardActionsEnum.SWITCH_TO_CONTACTS]: [...maybeMeta3()],
  [KeyboardActionsEnum.SWITCH_TO_INBOX]: [makeShortcut('meta+shift+1'), ...maybeMeta1()],
  [KeyboardActionsEnum.TAG]: [makeShortcut('l')],
  [KeyboardActionsEnum.TOGGLE_SIDE_PANEL]: [makeShortcut('meta+shift+i', true), makeShortcut('meta+/', true)],
  [KeyboardActionsEnum.TRACE]: [makeShortcut('meta+shift+e', true)],
  [KeyboardActionsEnum.TRASH]: [makeShortcut('del'), makeShortcut('backspace'), makeShortcut('#')],
  [KeyboardActionsEnum.UNDO]: [makeShortcut('meta+z')],

  [KeyboardActionsEnum.CALENDAR_GO_TO_TODAY]: [...maybeMetaT(), makeShortcut('t')],
  [KeyboardActionsEnum.CALENDAR_NEXT]: [makeShortcut('meta+right'), makeShortcut('right')],
  [KeyboardActionsEnum.CALENDAR_PREVIOUS]: [makeShortcut('meta+left'), makeShortcut('left')],
  [KeyboardActionsEnum.CALENDAR_DAY_VIEW]: [makeShortcut('d')],
  [KeyboardActionsEnum.CALENDAR_WEEK_VIEW]: [makeShortcut('w')],
  [KeyboardActionsEnum.CALENDAR_MONTH_VIEW]: [makeShortcut('m')],
  [KeyboardActionsEnum.CALENDAR_EDIT_EVENT]: [makeShortcut('e')],
  [KeyboardActionsEnum.CALENDAR_DELETE_EVENT]: [makeShortcut('del'), makeShortcut('backspace')],
};

/** Global map of action to keys when no shortcuts are enabled. */
export const noShortcutsMapping: KeyboardMapping = {
  ...helpMapping,
  ...commonMapping,
  [KeyboardActionsEnum.ARCHIVE]: [],
  [KeyboardActionsEnum.ASSIGN_TO_SELF]: [],
  [KeyboardActionsEnum.ASSIGN]: [],
  [KeyboardActionsEnum.CAROUSEL_MOVE_LEFT]: [],
  [KeyboardActionsEnum.CAROUSEL_MOVE_RIGHT]: [],
  [KeyboardActionsEnum.CLONE]: [],
  [KeyboardActionsEnum.CLOSE]: [],
  [KeyboardActionsEnum.CLOSE_LEGACY]: [],
  [KeyboardActionsEnum.COMPOSE_DISCUSSION]: [],
  [KeyboardActionsEnum.COMPOSE]: [],
  [KeyboardActionsEnum.EDITOR_ANSWERS]: [],
  [KeyboardActionsEnum.EDITOR_BCC]: [],
  [KeyboardActionsEnum.EDITOR_CC]: [],
  [KeyboardActionsEnum.EDITOR_SUBJECT]: [],
  [KeyboardActionsEnum.COLLAPSE_ALL]: [],
  [KeyboardActionsEnum.EXPAND_ALL]: [],
  [KeyboardActionsEnum.FOCUS_COMMENT]: [],
  [KeyboardActionsEnum.FORCE_REFETCH_CONVERSATION]: [],
  [KeyboardActionsEnum.FORWARD]: [],
  [KeyboardActionsEnum.FRAME_NEXT]: [],
  [KeyboardActionsEnum.FRAME_PREVIOUS]: [],
  [KeyboardActionsEnum.GO_TO_CONVERSATION]: [],
  [KeyboardActionsEnum.GO_TO_INBOX]: [],
  [KeyboardActionsEnum.INFORMATION]: [],
  [KeyboardActionsEnum.INVITE]: [],
  [KeyboardActionsEnum.MOVE_DOWN]: [makeShortcut('down', true)],
  [KeyboardActionsEnum.MOVE_TO_INBOX]: [],
  [KeyboardActionsEnum.MOVE_UP]: [makeShortcut('up', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_1]: [],
  [KeyboardActionsEnum.QUICK_RESPONSE_2]: [],
  [KeyboardActionsEnum.QUICK_RESPONSE_3]: [],
  [KeyboardActionsEnum.QUICK_RESPONSE_4]: [],
  [KeyboardActionsEnum.QUICK_RESPONSE_5]: [],
  [KeyboardActionsEnum.REMIND]: [],
  [KeyboardActionsEnum.REPLY_ALL_IN_NEW_WINDOW]: [],
  [KeyboardActionsEnum.REPLY_ALL]: [],
  [KeyboardActionsEnum.REPLY_IN_NEW_WINDOW]: [],
  [KeyboardActionsEnum.REPLY_INTRO]: [],
  [KeyboardActionsEnum.REPLY]: [],
  [KeyboardActionsEnum.SEARCH]: [],
  [KeyboardActionsEnum.SELECT_DOWN]: [],
  [KeyboardActionsEnum.SELECT_UP]: [],
  [KeyboardActionsEnum.SELECT_NEXT_TAB]: [],
  [KeyboardActionsEnum.SELECT_PREVIOUS_TAB]: [],
  [KeyboardActionsEnum.SEND_AND_DONE]: [],
  [KeyboardActionsEnum.SEND_AND_SNOOZE]: [],
  [KeyboardActionsEnum.SEND]: [],
  [KeyboardActionsEnum.SNOOZE]: [],
  [KeyboardActionsEnum.SPAM]: [],
  [KeyboardActionsEnum.STAR]: [],
  [KeyboardActionsEnum.SUBSCRIBE]: [],
  [KeyboardActionsEnum.SWITCH_TO_ANALYTICS]: [],
  [KeyboardActionsEnum.SWITCH_TO_CALENDAR]: [],
  [KeyboardActionsEnum.SWITCH_TO_CONTACTS]: [],
  [KeyboardActionsEnum.SWITCH_TO_INBOX]: [],
  [KeyboardActionsEnum.SWITCH_TO_KNOWLEDGE_BASE]: [],
  [KeyboardActionsEnum.TAG]: [],
  [KeyboardActionsEnum.TOGGLE_SIDE_PANEL]: [],
  [KeyboardActionsEnum.TRACE]: [],
  [KeyboardActionsEnum.TRASH]: [],
  [KeyboardActionsEnum.UNDO]: [makeShortcut('meta+z')],

  [KeyboardActionsEnum.CALENDAR_GO_TO_TODAY]: [],
  [KeyboardActionsEnum.CALENDAR_NEXT]: [],
  [KeyboardActionsEnum.CALENDAR_PREVIOUS]: [],
  [KeyboardActionsEnum.CALENDAR_DAY_VIEW]: [],
  [KeyboardActionsEnum.CALENDAR_WEEK_VIEW]: [],
  [KeyboardActionsEnum.CALENDAR_MONTH_VIEW]: [],
  [KeyboardActionsEnum.CALENDAR_EDIT_EVENT]: [],
  [KeyboardActionsEnum.CALENDAR_DELETE_EVENT]: [],
};

/** Global map of action to keys when Front shortcuts (no single key) are enabled. */
export const frontNoSingleKeyShortcutsMapping: KeyboardMapping = {
  ...helpMapping,
  ...commonMapping,
  [KeyboardActionsEnum.ARCHIVE]: [makeShortcut('meta+e', true)],
  [KeyboardActionsEnum.ASSIGN_TO_SELF]: [makeShortcut('meta+shift+a', true)],
  [KeyboardActionsEnum.ASSIGN]: [makeShortcut('meta+shift+g', true)],
  [KeyboardActionsEnum.CAROUSEL_MOVE_LEFT]: [makeShortcut('left')],
  [KeyboardActionsEnum.CAROUSEL_MOVE_RIGHT]: [makeShortcut('right')],
  [KeyboardActionsEnum.CLONE]: [],
  [KeyboardActionsEnum.CLOSE]: [makeShortcut('escape', true)],
  [KeyboardActionsEnum.CLOSE_LEGACY]: [makeShortcut('escape')],
  [KeyboardActionsEnum.COMPOSE_DISCUSSION]: [makeShortcut('meta+d', true)],
  [KeyboardActionsEnum.COMPOSE]: maybeMetaN(),
  [KeyboardActionsEnum.EDITOR_ANSWERS]: [makeShortcut('meta+shift+o', true)],
  [KeyboardActionsEnum.EDITOR_BCC]: [makeShortcut('meta+shift+b', true)],
  [KeyboardActionsEnum.EDITOR_CC]: [makeShortcut('meta+shift+c', true)],
  [KeyboardActionsEnum.EDITOR_SUBJECT]: [makeShortcut('meta+shift+s', true)],
  [KeyboardActionsEnum.EXPAND_ALL]: [makeShortcut('meta+;', true)],
  [KeyboardActionsEnum.COLLAPSE_ALL]: [makeShortcut('meta+shift+;', true)],
  [KeyboardActionsEnum.FOCUS_COMMENT]: [makeShortcut('meta+.', true)],
  [KeyboardActionsEnum.FORCE_REFETCH_CONVERSATION]: [makeShortcut('meta+shift+x', true)],
  [KeyboardActionsEnum.FORWARD]: [makeShortcut('meta+j', true)],
  [KeyboardActionsEnum.FRAME_NEXT]: [makeShortcut('meta+shift+down')],
  [KeyboardActionsEnum.FRAME_PREVIOUS]: [makeShortcut('meta+shift+up')],
  [KeyboardActionsEnum.GO_TO_CONVERSATION]: [makeShortcut('shift+o')],
  [KeyboardActionsEnum.GO_TO_INBOX]: [],
  [KeyboardActionsEnum.INFORMATION]: [makeShortcut('meta+shift+i', true)],
  [KeyboardActionsEnum.INVITE]: [makeShortcut('meta+i')],
  [KeyboardActionsEnum.MOVE_DOWN]: [makeShortcut('down', true), makeShortcut('ctrl+]', true)],
  [KeyboardActionsEnum.MOVE_TO_INBOX]: [makeShortcut('meta+shift+m', true)],
  [KeyboardActionsEnum.MOVE_UP]: [makeShortcut('up', true), makeShortcut('ctrl+[', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_1]: [makeShortcut('meta+shift+5', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_2]: [makeShortcut('meta+shift+6', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_3]: [makeShortcut('meta+shift+7', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_4]: [makeShortcut('meta+shift+8', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_5]: [makeShortcut('meta+shift+9', true)],
  [KeyboardActionsEnum.REMIND]: [makeShortcut('meta+shift+l', true)],
  [KeyboardActionsEnum.REPLY_ALL_IN_NEW_WINDOW]: [
    makeShortcut('meta+shift+alt+r', true),
    makeShortcut('shift+alt+r'),
  ],
  [KeyboardActionsEnum.REPLY_ALL]: maybeMetaShiftR(),
  [KeyboardActionsEnum.REPLY_IN_NEW_WINDOW]: [makeShortcut('meta+alt+r', true), makeShortcut('alt+r')],
  [KeyboardActionsEnum.REPLY_INTRO]: [makeShortcut('meta+shift+x', true)],
  [KeyboardActionsEnum.REPLY]: maybeMetaR(),
  [KeyboardActionsEnum.SEARCH]: [makeShortcut('meta+shift+f', true)],
  [KeyboardActionsEnum.SELECT_DOWN]: [makeShortcut('shift+down', true)],
  [KeyboardActionsEnum.SELECT_UP]: [makeShortcut('shift+up', true)],
  [KeyboardActionsEnum.SELECT_NEXT_TAB]: [],
  [KeyboardActionsEnum.SELECT_PREVIOUS_TAB]: [],
  [KeyboardActionsEnum.SEND_AND_DONE]: [makeShortcut('meta+enter', true)],
  [KeyboardActionsEnum.SEND_AND_SNOOZE]: [makeShortcut('meta+shift+l', true)],
  [KeyboardActionsEnum.SEND]: [makeShortcut('meta+shift+enter', true)],
  [KeyboardActionsEnum.SNOOZE]: [makeShortcut('meta+s', true)],
  [KeyboardActionsEnum.SPAM]: [makeShortcut('meta+shift+j', true)],
  [KeyboardActionsEnum.STAR]: [makeShortcut('alt+s', true)],
  [KeyboardActionsEnum.SUBSCRIBE]: [makeShortcut('meta+shift+u', true)],
  [KeyboardActionsEnum.SWITCH_TO_KNOWLEDGE_BASE]: [...maybeMeta5()],
  [KeyboardActionsEnum.SWITCH_TO_ANALYTICS]: [...maybeMeta4()],
  [KeyboardActionsEnum.SWITCH_TO_CALENDAR]: [makeShortcut('meta+shift+2'), ...maybeMeta2()],
  [KeyboardActionsEnum.SWITCH_TO_CONTACTS]: [...maybeMeta3()],
  [KeyboardActionsEnum.SWITCH_TO_INBOX]: [makeShortcut('meta+shift+1'), ...maybeMeta1()],
  [KeyboardActionsEnum.TAG]: maybeMetaT(),
  [KeyboardActionsEnum.TOGGLE_SIDE_PANEL]: [makeShortcut('meta+/', true)],
  [KeyboardActionsEnum.TRACE]: [makeShortcut('meta+shift+e', true)],
  [KeyboardActionsEnum.TRASH]: [makeShortcut('del')],
  [KeyboardActionsEnum.UNDO]: [makeShortcut('meta+z')],

  [KeyboardActionsEnum.CALENDAR_GO_TO_TODAY]: maybeMetaT(),
  [KeyboardActionsEnum.CALENDAR_NEXT]: [makeShortcut('meta+right')],
  [KeyboardActionsEnum.CALENDAR_PREVIOUS]: [makeShortcut('meta+left')],
  [KeyboardActionsEnum.CALENDAR_DAY_VIEW]: [],
  [KeyboardActionsEnum.CALENDAR_WEEK_VIEW]: [],
  [KeyboardActionsEnum.CALENDAR_MONTH_VIEW]: [],
  [KeyboardActionsEnum.CALENDAR_EDIT_EVENT]: [],
  [KeyboardActionsEnum.CALENDAR_DELETE_EVENT]: [makeShortcut('del')],
};

/** Global map of action to keys when Gmail shortcuts are enabled. */
export const gmailShortcutsMapping: KeyboardMapping = {
  ...helpMapping,
  ...commonMapping,
  [KeyboardActionsEnum.ARCHIVE]: [makeShortcut('e'), makeShortcut('y')],
  [KeyboardActionsEnum.ASSIGN_TO_SELF]: [makeShortcut('q')],
  [KeyboardActionsEnum.ASSIGN]: [makeShortcut('w')],
  [KeyboardActionsEnum.CAROUSEL_MOVE_LEFT]: [makeShortcut('left')],
  [KeyboardActionsEnum.CAROUSEL_MOVE_RIGHT]: [makeShortcut('right')],
  [KeyboardActionsEnum.CLONE]: [makeShortcut('meta+shift+f', true)],
  [KeyboardActionsEnum.CLOSE]: [makeShortcut('escape', true)],
  [KeyboardActionsEnum.CLOSE_LEGACY]: [makeShortcut('escape')],
  [KeyboardActionsEnum.COMPOSE_DISCUSSION]: [makeShortcut('d')],
  [KeyboardActionsEnum.COMPOSE]: [makeShortcut('c')],
  [KeyboardActionsEnum.EDITOR_ANSWERS]: [makeShortcut('meta+shift+o', true)],
  [KeyboardActionsEnum.EDITOR_BCC]: [makeShortcut('meta+shift+b', true)],
  [KeyboardActionsEnum.EDITOR_CC]: [makeShortcut('meta+shift+c', true)],
  [KeyboardActionsEnum.EDITOR_SUBJECT]: [makeShortcut('meta+shift+s', true)],
  [KeyboardActionsEnum.EXPAND_ALL]: [makeShortcut(';')],
  [KeyboardActionsEnum.COLLAPSE_ALL]: [makeShortcut('shift+;')],
  [KeyboardActionsEnum.FOCUS_COMMENT]: [makeShortcut('meta+.', true), makeShortcut('shift+m')],
  [KeyboardActionsEnum.FORCE_REFETCH_CONVERSATION]: [makeShortcut('meta+shift+x', true)],
  [KeyboardActionsEnum.FORWARD]: [makeShortcut('f')],
  [KeyboardActionsEnum.FRAME_NEXT]: [makeShortcut('meta+shift+down')],
  [KeyboardActionsEnum.FRAME_PREVIOUS]: [makeShortcut('meta+shift+up')],
  [KeyboardActionsEnum.GO_TO_CONVERSATION]: [makeShortcut('g c'), makeShortcut('shift+o')],
  [KeyboardActionsEnum.GO_TO_INBOX]: [makeShortcut('g i')],
  [KeyboardActionsEnum.INFORMATION]: [makeShortcut('meta+shift+i', true)],
  [KeyboardActionsEnum.INVITE]: [makeShortcut('i')],
  [KeyboardActionsEnum.MOVE_DOWN]: [makeShortcut('down', true), makeShortcut('j')],
  [KeyboardActionsEnum.MOVE_TO_INBOX]: [makeShortcut('v')],
  [KeyboardActionsEnum.MOVE_UP]: [makeShortcut('up', true), makeShortcut('k')],
  [KeyboardActionsEnum.QUICK_RESPONSE_1]: [makeShortcut('meta+shift+5', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_2]: [makeShortcut('meta+shift+6', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_3]: [makeShortcut('meta+shift+7', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_4]: [makeShortcut('meta+shift+8', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_5]: [makeShortcut('meta+shift+9', true)],
  [KeyboardActionsEnum.REMIND]: [makeShortcut('meta+shift+l', true)],
  [KeyboardActionsEnum.REPLY_ALL_IN_NEW_WINDOW]: [makeShortcut('shift+a')],
  [KeyboardActionsEnum.REPLY_ALL]: [makeShortcut('a')],
  [KeyboardActionsEnum.REPLY_IN_NEW_WINDOW]: [makeShortcut('shift+r')],
  [KeyboardActionsEnum.REPLY_INTRO]: [makeShortcut('meta+shift+x', true)],
  [KeyboardActionsEnum.REPLY]: [makeShortcut('r')],
  [KeyboardActionsEnum.SEARCH]: [makeShortcut('/')],
  [KeyboardActionsEnum.SELECT_DOWN]: [makeShortcut('shift+down', true), makeShortcut('shift+j')],
  [KeyboardActionsEnum.SELECT_UP]: [makeShortcut('shift+up', true), makeShortcut('shift+k')],
  [KeyboardActionsEnum.SELECT_NEXT_TAB]: [makeShortcut('`')],
  [KeyboardActionsEnum.SELECT_PREVIOUS_TAB]: [makeShortcut('shift+`')],
  [KeyboardActionsEnum.SEND_AND_DONE]: [makeShortcut('meta+enter', true)],
  [KeyboardActionsEnum.SEND_AND_SNOOZE]: [makeShortcut('meta+shift+l', true)],
  [KeyboardActionsEnum.SEND]: [makeShortcut('meta+shift+enter', true)],
  [KeyboardActionsEnum.SNOOZE]: [makeShortcut('b')],
  [KeyboardActionsEnum.SPAM]: [makeShortcut('shift+1')],
  [KeyboardActionsEnum.STAR]: [makeShortcut('s')],
  [KeyboardActionsEnum.SUBSCRIBE]: [makeShortcut('m')],
  [KeyboardActionsEnum.SWITCH_TO_KNOWLEDGE_BASE]: [...maybeMeta5()],
  [KeyboardActionsEnum.SWITCH_TO_ANALYTICS]: [...maybeMeta4()],
  [KeyboardActionsEnum.SWITCH_TO_CALENDAR]: [makeShortcut('meta+shift+2'), ...maybeMeta2()],
  [KeyboardActionsEnum.SWITCH_TO_CONTACTS]: [...maybeMeta3()],
  [KeyboardActionsEnum.SWITCH_TO_INBOX]: [makeShortcut('meta+shift+1'), ...maybeMeta1()],
  [KeyboardActionsEnum.TAG]: [makeShortcut('l')],
  [KeyboardActionsEnum.TOGGLE_SIDE_PANEL]: [makeShortcut('meta+/', true)],
  [KeyboardActionsEnum.TRACE]: [makeShortcut('meta+shift+e', true)],
  [KeyboardActionsEnum.TRASH]: [makeShortcut('#')],
  [KeyboardActionsEnum.UNDO]: [makeShortcut('z'), makeShortcut('meta+z')],

  [KeyboardActionsEnum.CALENDAR_GO_TO_TODAY]: [makeShortcut('t')],
  [KeyboardActionsEnum.CALENDAR_NEXT]: [makeShortcut('n')],
  [KeyboardActionsEnum.CALENDAR_PREVIOUS]: [makeShortcut('p')],
  [KeyboardActionsEnum.CALENDAR_DAY_VIEW]: [makeShortcut('d')],
  [KeyboardActionsEnum.CALENDAR_WEEK_VIEW]: [makeShortcut('w')],
  [KeyboardActionsEnum.CALENDAR_MONTH_VIEW]: [makeShortcut('m')],
  [KeyboardActionsEnum.CALENDAR_EDIT_EVENT]: [makeShortcut('e')],
  [KeyboardActionsEnum.CALENDAR_DELETE_EVENT]: [makeShortcut('del'), makeShortcut('backspace')],
};

/** Global map of action to keys when Front shortcuts (with single keys) are enabled. */
export const frontShortcutsMapping: KeyboardMapping = {
  ...helpMapping,
  ...commonMapping,
  [KeyboardActionsEnum.ARCHIVE]: [makeShortcut('meta+e', true), makeShortcut('e')],
  [KeyboardActionsEnum.ASSIGN_TO_SELF]: [makeShortcut('meta+shift+a', true), makeShortcut('shift+a')],
  [KeyboardActionsEnum.ASSIGN]: [makeShortcut('meta+shift+g', true), makeShortcut('shift+g')],
  [KeyboardActionsEnum.CAROUSEL_MOVE_LEFT]: [makeShortcut('left')],
  [KeyboardActionsEnum.CAROUSEL_MOVE_RIGHT]: [makeShortcut('right')],
  [KeyboardActionsEnum.CLONE]: [],
  [KeyboardActionsEnum.CLOSE]: [makeShortcut('escape', true)],
  [KeyboardActionsEnum.CLOSE_LEGACY]: [makeShortcut('escape')],
  [KeyboardActionsEnum.COMPOSE_DISCUSSION]: [makeShortcut('meta+d', true), makeShortcut('d')],
  [KeyboardActionsEnum.COMPOSE]: [...maybeMetaN(), makeShortcut('n')],
  [KeyboardActionsEnum.EDITOR_ANSWERS]: [makeShortcut('meta+shift+o', true)],
  [KeyboardActionsEnum.EDITOR_BCC]: [makeShortcut('meta+shift+b', true)],
  [KeyboardActionsEnum.EDITOR_CC]: [makeShortcut('meta+shift+c', true)],
  [KeyboardActionsEnum.EDITOR_SUBJECT]: [makeShortcut('meta+shift+s', true)],
  [KeyboardActionsEnum.EXPAND_ALL]: [makeShortcut('meta+;', true), makeShortcut(';')],
  [KeyboardActionsEnum.COLLAPSE_ALL]: [makeShortcut('meta+shift+;', true), makeShortcut('shift+;')],
  [KeyboardActionsEnum.FOCUS_COMMENT]: [makeShortcut('meta+.', true)],
  [KeyboardActionsEnum.FORCE_REFETCH_CONVERSATION]: [makeShortcut('meta+shift+x', true)],
  [KeyboardActionsEnum.FORWARD]: [makeShortcut('meta+j', true), makeShortcut('j')],
  [KeyboardActionsEnum.FRAME_NEXT]: [makeShortcut('meta+shift+down')],
  [KeyboardActionsEnum.FRAME_PREVIOUS]: [makeShortcut('meta+shift+up')],
  [KeyboardActionsEnum.GO_TO_CONVERSATION]: [makeShortcut('shift+o')],
  [KeyboardActionsEnum.GO_TO_INBOX]: [],
  [KeyboardActionsEnum.INFORMATION]: [makeShortcut('meta+shift+i', true), makeShortcut('shift+i')],
  [KeyboardActionsEnum.INVITE]: [makeShortcut('meta+i'), makeShortcut('i')],
  [KeyboardActionsEnum.MOVE_DOWN]: [makeShortcut('down', true), makeShortcut('ctrl+]', true)],
  [KeyboardActionsEnum.MOVE_TO_INBOX]: [makeShortcut('meta+shift+m', true), makeShortcut('shift+m')],
  [KeyboardActionsEnum.MOVE_UP]: [makeShortcut('up', true), makeShortcut('ctrl+[', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_1]: [makeShortcut('meta+shift+5', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_2]: [makeShortcut('meta+shift+6', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_3]: [makeShortcut('meta+shift+7', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_4]: [makeShortcut('meta+shift+8', true)],
  [KeyboardActionsEnum.QUICK_RESPONSE_5]: [makeShortcut('meta+shift+9', true)],
  [KeyboardActionsEnum.REMIND]: [makeShortcut('meta+shift+l', true), makeShortcut('shift+l')],
  [KeyboardActionsEnum.REPLY_ALL_IN_NEW_WINDOW]: [
    makeShortcut('meta+shift+alt+r', true),
    makeShortcut('shift+alt+r'),
  ],
  [KeyboardActionsEnum.REPLY_ALL]: [...maybeMetaShiftR(), makeShortcut('shift+r')],
  [KeyboardActionsEnum.REPLY_IN_NEW_WINDOW]: [makeShortcut('meta+alt+r', true), makeShortcut('alt+r')],
  [KeyboardActionsEnum.REPLY_INTRO]: [makeShortcut('meta+shift+x', true)],
  [KeyboardActionsEnum.REPLY]: [...maybeMetaR(), makeShortcut('r')],
  [KeyboardActionsEnum.SEARCH]: [makeShortcut('meta+shift+f', true), makeShortcut('shift+f')],
  [KeyboardActionsEnum.SELECT_DOWN]: [makeShortcut('shift+down', true)],
  [KeyboardActionsEnum.SELECT_UP]: [makeShortcut('shift+up', true)],
  [KeyboardActionsEnum.SELECT_NEXT_TAB]: [],
  [KeyboardActionsEnum.SELECT_PREVIOUS_TAB]: [],
  [KeyboardActionsEnum.SEND_AND_DONE]: [makeShortcut('meta+enter', true)],
  [KeyboardActionsEnum.SEND_AND_SNOOZE]: [makeShortcut('meta+shift+l', true)],
  [KeyboardActionsEnum.SEND]: [makeShortcut('meta+shift+enter', true)],
  [KeyboardActionsEnum.SNOOZE]: [makeShortcut('meta+s', true), makeShortcut('s')],
  [KeyboardActionsEnum.SPAM]: [makeShortcut('meta+shift+j', true), makeShortcut('shift+j')],
  [KeyboardActionsEnum.STAR]: [makeShortcut('alt+s', true)],
  [KeyboardActionsEnum.SUBSCRIBE]: [makeShortcut('meta+shift+u', true), makeShortcut('shift+u')],
  [KeyboardActionsEnum.SWITCH_TO_KNOWLEDGE_BASE]: [...maybeMeta5()],
  [KeyboardActionsEnum.SWITCH_TO_ANALYTICS]: [...maybeMeta4()],
  [KeyboardActionsEnum.SWITCH_TO_CALENDAR]: [makeShortcut('meta+shift+2'), ...maybeMeta2()],
  [KeyboardActionsEnum.SWITCH_TO_CONTACTS]: [...maybeMeta3()],
  [KeyboardActionsEnum.SWITCH_TO_INBOX]: [makeShortcut('meta+shift+1'), ...maybeMeta1()],
  [KeyboardActionsEnum.TAG]: [...maybeMetaT(), makeShortcut('t')],
  [KeyboardActionsEnum.TOGGLE_SIDE_PANEL]: [makeShortcut('meta+/', true)],
  [KeyboardActionsEnum.TRACE]: [makeShortcut('meta+shift+e', true)],
  [KeyboardActionsEnum.TRASH]: [makeShortcut('del')],
  [KeyboardActionsEnum.UNDO]: [makeShortcut('meta+z')],

  [KeyboardActionsEnum.CALENDAR_GO_TO_TODAY]: [...maybeMetaT(), makeShortcut('t')],
  [KeyboardActionsEnum.CALENDAR_NEXT]: [makeShortcut('meta+right'), makeShortcut('right')],
  [KeyboardActionsEnum.CALENDAR_PREVIOUS]: [makeShortcut('meta+left'), makeShortcut('left')],
  [KeyboardActionsEnum.CALENDAR_DAY_VIEW]: [makeShortcut('d')],
  [KeyboardActionsEnum.CALENDAR_WEEK_VIEW]: [makeShortcut('w')],
  [KeyboardActionsEnum.CALENDAR_MONTH_VIEW]: [makeShortcut('m')],
  [KeyboardActionsEnum.CALENDAR_EDIT_EVENT]: [makeShortcut('e')],
  [KeyboardActionsEnum.CALENDAR_DELETE_EVENT]: [makeShortcut('del'), makeShortcut('backspace')],
};

function maybeMeta(key: string) {
  if (!isElectron()) {
    // Don't allow the new tab shortcut on web.
    return [];
  }

  return [makeShortcut(`meta+${key}`, true)];
}

function maybeMetaR() {
  return maybeMeta('r');
}

function maybeMetaShiftR() {
  return maybeMeta('shift+r');
}

function maybeMetaT() {
  return maybeMeta('t');
}

function maybeMetaN() {
  return maybeMeta('n');
}

function maybeMeta1() {
  return maybeMeta('1');
}

function maybeMeta2() {
  return maybeMeta('2');
}

function maybeMeta3() {
  return maybeMeta('3');
}

function maybeMeta4() {
  return maybeMeta('4');
}

function maybeMeta5() {
  return maybeMeta('5');
}

/*
 * Methods.
 */

const beforeActions = [
  KeyboardActionsEnum.MOVE_LEFT,
  KeyboardActionsEnum.MOVE_UP,
  KeyboardActionsEnum.SELECT_UP,
  KeyboardActionsEnum.SELECT_PREVIOUS_TAB,
];

const afterActions = [
  KeyboardActionsEnum.MOVE_DOWN,
  KeyboardActionsEnum.MOVE_RIGHT,
  KeyboardActionsEnum.SELECT_DOWN,
  KeyboardActionsEnum.SELECT_NEXT_TAB,
];

const beforeAfterActions = [...beforeActions, ...afterActions];

/** Check if a keyboard action represents the idea of "before". */
export function isBeforeAction(action: KeyboardActionsEnum) {
  return beforeActions.includes(action);
}

export function isAfterAction(action: KeyboardActionsEnum) {
  return afterActions.includes(action);
}

export function isBeforeAfterAction(action: KeyboardActionsEnum) {
  return beforeAfterActions.includes(action);
}

const gmailSequenceRegexp = /^[a-z]$/;
export function isGmailSequence(sequence: string) {
  return gmailSequenceRegexp.test(sequence);
}

/** @returns true if ctrl/meta (depending on the platform) is pressed during the keyboard event. */
export function hasMetaKey(event: FrontKeyboardEvent | FrontMouseEvent) {
  switch (metaKey) {
    case ModifierKeysEnum.COMMAND:
      return event.metaKey;

    case ModifierKeysEnum.CTRL:
    default:
      return event.ctrlKey;
  }
}
