import {cloneDeep, merge} from 'lodash';

import {DeepPartial} from '../../../../core/src/helpers/typescript/typeHelpers';
import {expandedDarkAppFrameSemanticStyles} from '../semantic/appFrameSemanticStyles';
import {expandedDarkBannerSemanticStyles} from '../semantic/bannerSemanticStyles';
import {expandedLeftBarSemanticStyles} from '../semantic/leftBarSemanticStyles';
import {themeConstants} from '../themeConstants';
import {ThemeTypesEnum, UserTheme} from '../themeTypes';
import {darkColors} from './darkColors';
import {darkSemanticStyles} from './darkSemanticStyles';

export const darkTheme: UserTheme = {
  ...darkSemanticStyles,
  ...darkColors,
  constants: themeConstants,
  type: ThemeTypesEnum.DARK,
};

const expandedDarkThemeStyles: DeepPartial<UserTheme> = {
  leftBar: expandedLeftBarSemanticStyles,
  banner: expandedDarkBannerSemanticStyles,
  appFrame: expandedDarkAppFrameSemanticStyles,
};

// Expanded P2/P3 dark mode styles, including dark banner styles and the new sidebar.
export const expandedDarkTheme: UserTheme = merge(
  {
    ...cloneDeep(darkTheme),
    type: ThemeTypesEnum.EXPANDED_DARK,
  },
  expandedDarkThemeStyles,
);

export const darkSidebarTheme: UserTheme = {
  ...expandedDarkTheme,
  type: ThemeTypesEnum.DARK_SIDEBAR,
};
