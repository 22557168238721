/* eslint-disable no-underscore-dangle */
import React, {Children, FC, isValidElement, ReactNode} from 'react';

import {findPluralMessage, replacePluralValue} from '../pluralMessage';
import {useEnsureI18nContext} from './i18nContext';
import {MessageReactNode} from './trans';

/*
 * Props.
 */

interface PluralProps {
  id: string;
  value: number;
  _0?: MessageReactNode;
  one: MessageReactNode;
  other: MessageReactNode;
}

/*
 * Component.
 */

export const Plural: FC<PluralProps> = (props) => {
  useEnsureI18nContext();

  return <>{replacePluralValueReact(props.value, findPluralMessage(props))}</>;
};

/*
 * Helpers.
 */

export function replacePluralValueReact(value: number, message: ReactNode): ReactNode {
  return Children.map(message, (node) => {
    if (typeof node === 'string') {
      return replacePluralValue(value, node);
    }

    if (isValidElement(node) && node.props && typeof node.props === 'object') {
      const grandchildren = node.props.children;

      return grandchildren && typeof grandchildren !== 'function'
        ? {
            ...node,
            props: {
              ...node.props,
              children: replacePluralValueReact(value, grandchildren),
            },
          }
        : node;
    }

    // Children.map unpacks ReactFragment = Iterable<ReactNode> for us, so we shouldn't encounter that here.
    return node;
  });
}
