import React, {FC, memo, ReactNode, useCallback, useLayoutEffect, useRef} from 'react';
import styled, {css} from 'styled-components';

import {useUniqueElementId} from '../../../../core/src/helpers/react/hooks/uniqueIdHelpers';
import {PaletteColorsEnum} from '../../../../core/src/models/api/paletteModel';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {appearanceNone, center} from '../../styles/mixins';
import {makeSizeConstants} from '../../styles/styleHelpers';
import {TooltipCoordinator} from '../tooltips/tooltipCoordinator';
import {TooltipOverflowCondition} from '../tooltips/tooltipOverflowCondition';
import {BooleanInputProps} from './booleanInputTypes';

/*
 * Props.
 */

interface BooleanInputWithLabelProps extends BooleanInputProps {
  /** The type of input to render. */
  type: string;
  /** The size of input to render. */
  size: VisualSizesEnum;
  /** The content to render as the isChecked indicator. */
  checkedIndicator: ReactNode;
  /** Whether to render the indicator as a circle. */
  useCircularIndicator?: boolean;
  children?: ReactNode;
  isOnRightSide?: boolean;
  isMultiline?: boolean;
}

/*
 * Style.
 */

const StyledWrapperLabel = styled.label`
  display: flex;
  align-items: start;
  gap: 10px;
`;

interface IndicatorStyleProps {
  $size: VisualSizesEnum;
  $useCircularIndicator?: boolean;
  $isChecked?: boolean;
  $isIndeterminate?: boolean;
  $isDisabled?: boolean;
}

const indicatorSizes = makeSizeConstants(12, 16);
const StyledIndicatorDiv = styled.div<IndicatorStyleProps>`
  flex-shrink: 0;
  position: relative;

  width: ${(p) => indicatorSizes[p.$size]}px;
  height: ${(p) => indicatorSizes[p.$size]}px;

  border-radius: ${(p) => (p.$useCircularIndicator ? '50%' : '3px')};
  ${(p) => addIndicatorColorStyles(p)};
  ${center()};
`;

function addIndicatorColorStyles(props: IndicatorStyleProps) {
  if ((props.$isDisabled && props.$isChecked) || (props.$isDisabled && props.$isIndeterminate)) {
    return css`
      color: ${(p) => p.theme.checkbox.disabledCheckColor};
      background-color: ${(p) => p.theme.checkbox.disabledBoxColor};
    `;
  }

  if (props.$isDisabled) {
    return css`
      background-color: ${(p) => p.theme.greys.shade20};
      box-shadow: inset 0 0 0 1px ${(p) => p.theme.greys.shade40};
    `;
  }

  if (props.$isChecked || props.$isIndeterminate) {
    return css`
      color: ${(p) => p.theme.greys.white};
      background-color: ${(p) => p.theme.palette[PaletteColorsEnum.BLUE].shade40};
    `;
  }

  return css`
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.greys.shade60};

    &:hover {
      box-shadow: inset 0 0 0 1px ${(p) => p.theme.greys.shade70};
    }
  `;
}

const StyledBooleanInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${appearanceNone()};
`;

const lineHeight = 20;

const labelStyle = css<{$size: VisualSizesEnum}>`
  color: ${(p) => p.theme.greys.shade80};
  line-height: ${lineHeight}px;
  // Vertically align the label with the indicator.
  margin-top: ${(p) => indicatorSizes[p.$size] / 2 - lineHeight / 2}px;
`;

const indeterminateIndicatorWidths = makeSizeConstants(6, 8);
const StyledIndeterminateCheckedIndicator = styled.span<{$size: VisualSizesEnum}>`
  width: ${(p) => indeterminateIndicatorWidths[p.$size]}px;
  height: 2px;
  border-radius: 4px;
  background-color: currentColor;
`;

// For single line labels.
const StyledTooltipOverflowCondition = styled(TooltipOverflowCondition)<{$size: VisualSizesEnum}>`
  ${labelStyle}
`;

const StyledMultilineLabelDiv = styled.div<{$size: VisualSizesEnum}>`
  ${labelStyle}
`;

/*
 * Component.
 */

/** Base component to build various boolean inputs. */
export const BooleanInputWithLabel = memo<BooleanInputWithLabelProps>(({children, ...props}) => {
  if (!children) {
    return <BooleanInput {...props} />;
  }

  return (
    <TooltipCoordinator render={!props.isMultiline ? () => children : undefined}>
      <BooleanInput {...props}>
        {!props.isMultiline ? (
          <StyledTooltipOverflowCondition $size={props.size}>{children}</StyledTooltipOverflowCondition>
        ) : (
          <StyledMultilineLabelDiv $size={props.size}>{children}</StyledMultilineLabelDiv>
        )}
      </BooleanInput>
    </TooltipCoordinator>
  );
});

const BooleanInput: FC<BooleanInputWithLabelProps> = (props) => {
  const inputId = useUniqueElementId();

  const {
    className,
    type,
    size,
    checkedIndicator,
    isChecked,
    isDisabled,
    onChange,
    children,
    isIndeterminate,
    isOnRightSide,
  } = props;

  const onBooleanInputChange = useCallback(() => onChange(!isChecked), [isChecked, onChange]);
  const inputRef = useRef<HTMLInputElement>(null);

  const maybeRenderIndicator = () => {
    if (isIndeterminate) {
      return <StyledIndeterminateCheckedIndicator $size={size} />;
    }

    if (isChecked) {
      return checkedIndicator;
    }

    return null;
  };

  useLayoutEffect(() => {
    const input = inputRef.current;

    if (!input) {
      return;
    }

    if (isIndeterminate) {
      input.indeterminate = true;
      input.checked = false;
    } else if (isChecked) {
      input.indeterminate = false;
      input.checked = true;
    }
  }, [isChecked, isIndeterminate]);

  const renderInput = () => (
    <StyledIndicatorDiv
      $size={props.size}
      $useCircularIndicator={props.useCircularIndicator}
      $isChecked={props.isChecked}
      $isIndeterminate={props.isIndeterminate}
      $isDisabled={props.isDisabled}
    >
      {maybeRenderIndicator()}
      <StyledBooleanInput
        id={inputId}
        type={type}
        checked={isChecked}
        disabled={isDisabled}
        onChange={onBooleanInputChange}
      />
    </StyledIndicatorDiv>
  );

  return (
    <StyledWrapperLabel htmlFor={inputId} className={className}>
      {!isOnRightSide && renderInput()}
      {children}
      {isOnRightSide && renderInput()}
    </StyledWrapperLabel>
  );
};
