/* eslint-disable react/jsx-props-no-spreading */
import {isEmpty, isFunction, isNumber} from 'lodash';
import React, {
  forwardRef,
  MutableRefObject,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import {ListItemKeySelector, VariableSizeList} from 'react-window';

import {FrontKeyboardEvent, Renderer} from '../../../../../core/src/helpers/react/reactHelpers';
import {VisualSizesEnum} from '../../../styles/commonStyles';
import {IconButton} from '../../buttons/iconButton';
import {Dropdown, DropdownProps} from '../dropdown';
import {DropdownEmpty} from '../dropdownEmpty';
import {dropdownListVerticalPadding} from '../dropdownList';
import {dropdownListDefaultRowHeight, DropdownVirtualList, ScrollHandler} from '../dropdownVirtualList';
import {SearchDropdownHeader, searchDropdownHeaderHeight} from './searchDropdownHeader';
import {computeIsNearBottomOfScrollableList} from './searchDropdownHelpers';

/*
 * Constants.
 */

const defaultSearchDropdownWidth = 260;
const defaultMaxHeight = Math.round(dropdownListVerticalPadding + dropdownListDefaultRowHeight * 8.5);

/*
 * Props.
 */

export interface SearchDropdownProps<T> extends DropdownProps {
  /**
   * The data to render.
   */
  data: ReadonlyArray<T>;

  /**
   * Renders the rows in the dropdown. Each item from the data array is passed here.
   * @param item Item being rendered.
   * @param index Index of the item being rendered.
   * @param data Array containing all the data that was requested.
   * @returns The rendered row
   */
  renderRow: (item: T, index: number, data: ReadonlyArray<T>) => ReactNode;

  /**
   * Optional. Default height of 30. If all the items have the same height, this prop should be a number.
   * If items have different heights, this prop should be a function that returns the height of a given row.
   * Needed for the virtual scroller.
   */
  findRowHeight?: number | ((item: T, index: number, data: ReadonlyArray<T>) => number);
  /**
   * Optional. Returns a unique identifier for each item.
   */
  findRowKey?: ListItemKeySelector;

  /*
   * String displayed in the search input.
   */
  query: string;
  /**
   * Called when the search input text changes.
   * @param newQuery new text inside the search input
   */
  onQueryChange: (newQuery: string) => void;

  /**
   * Optional. Set the dropdown title. Will be visible in the dropdown header.
   */
  headerTitle?: ReactNode;
  /**
   *  Optional. Custom icon in the header, next to the title.
   */
  headerRightIcon?: ReactNode;
  /**
   *  Optional. Label given to the arrow back icon in the header.
   */
  headerBackButtonLabel?: ReactNode;
  /**
   *  Optional. Callback called when the arrow back icon in the header is pressed.
   */
  onHeaderBackButtonClick?: () => void;

  /**
   *  Optional. Set a placeholder value in the search input.
   */
  searchInputPlaceholder?: string;
  /**
   *  Optional. Set to true to show the search icon in the search input.
   */
  searchInputShouldIncludeSearchIcon?: boolean;
  /**
   *  Optional. Callback called when the user presses enter in the search input.
   */
  onSearchInputEnter?: (e: FrontKeyboardEvent, query: string) => void;

  /**
   *  Optional. Highlight an item in the list of items.
   */
  defaultHighlightedIndex?: number;
  /**
   *  Optional. Render a node below the list of items, at the bottom of the dropdown.
   */
  footer?: React.ReactNode;
  /**
   *  Optional. Set the max height the dropdown can have.
   */
  maxContentHeight?: number;

  /**
   * Optional. Scroll event fired near the end of the scrollable area. Likely to be called multiple
   * times, since this is a scroll event handler. If using this to fetch more data, ensure
   * unnecessary requests are not being made.
   */
  onScrollNearBottom?: () => void;

  /**
   * Optional. By default, the SearchDropdown will render <EmptyDropdown/> when not data is present
   * If a non-null value is returned, it's rendered instead
   * of the rows. Used to display any custom content (loading, error, initial
   * states, etc.)
   */
  renderOverlay?: Renderer;

  // Whether the search input field is disabled
  isSearchInputDisabled?: boolean;

  // Hide the header
  isHeaderHidden?: boolean;
}

export interface SearchDropdownHandle {
  /**
   * Scroll to the specified offset (scrollTop or scrollLeft, depending on the direction prop).
   */
  scrollTo(scrollOffset: number): void;
  /**
   * Scroll to the specified item.
   */
  scrollToItem(index: number): void;

  /**
   * SearchDropdown caches offsets and measurements for performance purposes.
   * This method clears that cached data.
   * It should be called whenever a item's size changes.
   * By default the search dropdown will reset the cache when the query text changes.
   */
  resetHeightCache(): void;
}

const SearchDropdownComponent = <T,>(
  props: SearchDropdownProps<T>,
  ref?: React.ForwardedRef<SearchDropdownHandle>,
) => {
  const {headerTitle, headerRightIcon, query, width} = props;
  const {
    searchInputPlaceholder,
    searchInputShouldIncludeSearchIcon,
    footer,
    isSearchInputDisabled,
    isHeaderHidden,
  } = props;
  const {onSearchInputEnter, onScrollNearBottom, onQueryChange} = props;

  const dropdownVirtualListRef = useRef<VariableSizeList>(null);

  /*
   * Api.
   */

  useImperativeHandle(ref, () => ({
    scrollTo(scrollOffset: number) {
      dropdownVirtualListRef.current?.scrollTo(scrollOffset);
    },
    scrollToItem(index: number) {
      dropdownVirtualListRef.current?.scrollToItem(index);
    },
    resetHeightCache() {
      dropdownVirtualListRef.current?.resetAfterIndex(0, false);
    },
  }));

  /*
   * Events.
   */

  const onScroll: ScrollHandler = useCallback(
    (scrollContext) => {
      if (!onScrollNearBottom) {
        return;
      }

      // Only load more if we're near the bottom of the list.
      const isNearBottom = computeIsNearBottomOfScrollableList(scrollContext);
      if (!isNearBottom) {
        return;
      }

      onScrollNearBottom();
    },
    [onScrollNearBottom],
  );

  /*
   * Render.
   */

  return (
    <Dropdown {...props} width={width || defaultSearchDropdownWidth}>
      {!isHeaderHidden && (
        <SearchDropdownHeader
          title={headerTitle}
          rightIcon={headerRightIcon}
          isDisabled={isSearchInputDisabled}
          leftIcon={maybeRenderBackButton(props)}
          onSearchInputEnter={onSearchInputEnter}
          placeholder={searchInputPlaceholder}
          shouldIncludeSearchIcon={searchInputShouldIncludeSearchIcon}
          value={query}
          onChange={(newQuery) => {
            // If findRowHeight is a function and not a number or undefined,
            // it means we should reset the cached heights when the search query changes by default.
            if (isFunction(props.findRowHeight)) {
              dropdownVirtualListRef.current?.resetAfterIndex(0, false);
            }
            onQueryChange(newQuery);
          }}
        />
      )}
      {renderContent(props, onScroll, dropdownVirtualListRef)}
      {footer}
    </Dropdown>
  );
};

function renderContent(
  props: SearchDropdownProps<any>,
  onScroll: ScrollHandler,
  ref: MutableRefObject<VariableSizeList | null>,
) {
  const {width, maxContentHeight, height, data, query, defaultHighlightedIndex} = props;
  const {renderRow, renderOverlay, onRequestClose, findRowKey} = props;

  const overlay = renderOverlay && renderOverlay();
  if (overlay) {
    return overlay;
  }

  if (isEmpty(data)) {
    return <DropdownEmpty />;
  }

  return (
    <DropdownVirtualList
      ref={ref}
      width={width || defaultSearchDropdownWidth}
      maxHeight={maxContentHeight ?? (height ? height - searchDropdownHeaderHeight : defaultMaxHeight)}
      rowCount={data.length}
      renderRow={(index) => renderRow(data[index], index, data)}
      getRowHeight={buildGetRowHeight(props)}
      onRequestClose={onRequestClose}
      onScroll={onScroll}
      shouldSelectFirst={query.length > 0 ? true : undefined}
      defaultHighlightedIndex={defaultHighlightedIndex}
      itemKey={findRowKey}
    />
  );
}

function buildGetRowHeight<T>(props: SearchDropdownProps<T>) {
  const {data, findRowHeight} = props;

  if (!findRowHeight) {
    return undefined;
  }

  if (isNumber(findRowHeight)) {
    return () => findRowHeight;
  }

  return (index: number) => findRowHeight(data[index], index, data);
}

function maybeRenderBackButton<T>(props: SearchDropdownProps<T>) {
  if (!props.headerBackButtonLabel || !props.onHeaderBackButtonClick) {
    return null;
  }

  return (
    <IconButton
      iconName="chevronLeftSmall"
      label={props.headerBackButtonLabel}
      onClick={props.onHeaderBackButtonClick}
      size={VisualSizesEnum.SMALL}
    />
  );
}

/**
 * See docs/search-dropdowns.md for detailed instructions on how to use this component.
 */
// This type assertion is needed because SearchDropdown is a generic functional component
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const SearchDropdown = forwardRef(SearchDropdownComponent) as <T>(
  props: SearchDropdownProps<T> & {ref?: React.ForwardedRef<SearchDropdownHandle>},
) => ReturnType<typeof SearchDropdownComponent>;
