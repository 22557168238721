import {darkBrandPalette} from '../../styles/brandColors';
import {alphasDark, greysDark, paletteDark} from '../../styles/colorStyles';
import {ColorStyles} from '../themeTypes';

// TODO: Move color styles into this file
export const darkColors: ColorStyles = {
  greys: greysDark,
  alphas: alphasDark,
  palette: paletteDark,
  brandPalette: darkBrandPalette,
};
