import React, {FC, PropsWithChildren, useContext} from 'react';
import {DefaultTheme, ThemeContext, ThemeProvider} from 'styled-components';

import {UserThemesEnum} from '../../../core/src/models/api/userThemesEnum';
import {lightTheme} from './light/lightTheme';
import {useUserThemeStyles} from './themeHelpers';
import {UserTheme} from './themeTypes';

/*
 * Types.
 */

export interface ThemeContextType {
  theme: DefaultTheme;
}

export type ThemeContextReader = (props: ThemeContextType) => string;
export type ThemeReader = (theme: DefaultTheme) => string;

/*
 * Props.
 */

export interface UserThemeProviderProps {
  theme: UserThemesEnum;
}

/*
 * Component.
 */

export const UserThemeProvider: FC<PropsWithChildren<UserThemeProviderProps>> = ({theme, children}) => {
  const userTheme = useUserThemeStyles(theme);
  return <ThemeProvider theme={userTheme}>{children}</ThemeProvider>;
};

export const LightUserThemeProvider: FC<PropsWithChildren<unknown>> = ({children}) => (
  <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>
);

/*
 * Hook.
 */

export function useUserTheme(): UserTheme {
  const theme = useContext(ThemeContext);
  return theme as UserTheme;
}
