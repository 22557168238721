import {noop} from 'lodash';
import {Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

import {globalWindow} from '../browser/browserHelpers';

export const prefersDarkMode$ = new Observable((subscriber) => {
  try {
    const query = globalWindow.matchMedia('(prefers-color-scheme: dark)');
    const emit = () => subscriber.next(query.matches);

    // Emit on subscribe.
    emit();

    // Emit on change.
    const onChange = () => {
      try {
        emit();
      } catch (error) {
        subscriber.error(error);
      }
    };
    if (query.addEventListener) {
      query.addEventListener('change', onChange);
    } else {
      query.addListener(onChange);
    }
    return () => {
      if (query.removeEventListener) {
        query.removeEventListener('change', onChange);
      } else {
        query.removeListener(onChange);
      }
    };
  } catch (error) {
    subscriber.error(error);
    return noop;
  }
}).pipe(distinctUntilChanged());
