import {useSyncExternalStore} from 'react';

import {globalWindow} from '../../../core/src/helpers/browser/browserHelpers';
import {prefersDarkMode$} from '../../../core/src/helpers/environment/darkMode';
import {UnreachableError} from '../../../core/src/helpers/types/customErrors';
import {UserThemesEnum} from '../../../core/src/models/api/userThemesEnum';
import {darkTheme} from './dark/darkTheme';
import {lightTheme} from './light/lightTheme';
import {UserTheme} from './themeTypes';

const subscribeToPrefersDarkMode = (callback: () => void) => {
  const subscription = prefersDarkMode$.subscribe(callback);
  return () => subscription.unsubscribe();
};

const getPrefersDarkModeSnapshot = () => globalWindow.matchMedia('(prefers-color-scheme: dark)').matches;

export function usePrefersDarkMode() {
  return useSyncExternalStore(subscribeToPrefersDarkMode, getPrefersDarkModeSnapshot);
}

export function useUserThemeStyles(theme: UserThemesEnum): UserTheme {
  const prefersDarkMode = usePrefersDarkMode();
  switch (theme) {
    case UserThemesEnum.LIGHT:
    case UserThemesEnum.DARK_SIDEBAR:
      return lightTheme;
    case UserThemesEnum.DARK:
      return darkTheme;
    case UserThemesEnum.SYSTEM:
      return prefersDarkMode ? darkTheme : lightTheme;
    default:
      throw new UnreachableError(theme);
  }
}
