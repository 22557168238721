import {alphas, greys, greysDark} from '../../styles/colorStyles';

export type VerticalSwitcherSemanticStyles = {
  background: string;
  itemBackground: string;
  itemBackgroundHover: string;
  itemBackgroundActive: string;
  itemColor: string;
  itemIconColor: string;
};

export const lightVerticalSwitcherSemanticStyles: VerticalSwitcherSemanticStyles = {
  background: greys.shade20,
  itemBackground: alphas.transparent,
  itemBackgroundHover: alphas.gray10,
  itemBackgroundActive: alphas.gray20,
  itemColor: greys.shade90,
  itemIconColor: greys.shade80,
};

export const darkVerticalSwitcherSemanticStyles: VerticalSwitcherSemanticStyles = {
  background: greysDark.white,
  itemBackground: alphas.transparent,
  itemBackgroundHover: alphas.white20,
  itemBackgroundActive: alphas.white30,
  itemColor: greysDark.shade90,
  itemIconColor: greysDark.shade100,
};
