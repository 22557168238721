import React, {forwardRef} from 'react';
import styled from 'styled-components';

import {InteractionComponentProps} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {Trans} from '../../../../core/src/helpers/internationalization/react';
import {IconButton} from '../buttons/iconButton';
import {BoxedInput, BoxedInputInheritableProps} from './boxedInput';
import {
  BoxedInputElement,
  BoxedInputElementInheritableProps,
  BoxedInputElementTypesEnum,
} from './boxedInputElement';

/*
 * Props.
 */

export interface TextBoxProps
  extends BoxedInputInheritableProps,
    BoxedInputElementInheritableProps,
    InteractionComponentProps {
  onClear?: () => void;
  onCopy?: () => void;
  noMargin?: boolean;
  customRightAction?: React.ReactNode;
  customLeftAction?: React.ReactNode;
}

/*
 * Styles.
 */

const StyledInputIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

/*
 * Component.
 */

/** Basic text input. */
export const TextBox = forwardRef<BoxedInput, TextBoxProps>((props, ref) => (
  <BoxedInput {...props} ref={ref}>
    {(childProps) => {
      const {onClear, onCopy, isDisabled, customRightAction, customLeftAction} = props;
      const inputNode = (
        <BoxedInputElement {...childProps} {...props} type={BoxedInputElementTypesEnum.TEXT} />
      );

      if (customLeftAction) {
        return (
          <StyledInputIconWrapper>
            {customLeftAction}
            {inputNode}
          </StyledInputIconWrapper>
        );
      }

      if (customRightAction) {
        return (
          <StyledInputIconWrapper>
            {inputNode}
            {customRightAction}
          </StyledInputIconWrapper>
        );
      }

      if (onCopy) {
        return (
          <StyledInputIconWrapper>
            {inputNode}
            <IconButton
              iconName="copySmall"
              label={<Trans id="textbox-copy-icon-label">Copy</Trans>}
              onClick={onCopy}
            />
          </StyledInputIconWrapper>
        );
      }

      if (isDisabled) {
        return inputNode;
      }

      if (onClear) {
        return (
          <StyledInputIconWrapper>
            {inputNode}
            <IconButton
              iconName="clearSmall"
              label={<Trans id="textbox-clear-icon-label">Clear</Trans>}
              onClick={onClear}
            />
          </StyledInputIconWrapper>
        );
      }

      return inputNode;
    }}
  </BoxedInput>
));
