import {greys, palette, paletteDark} from '../../styles/colorStyles';

export const lightAddOnPillSemanticStyles = {
  backgroundColor: palette.purple.shade40,
  color: greys.white,
};

export type AddOnPillSemanticStyles = typeof lightAddOnPillSemanticStyles;

export const darkAddOnPillSemanticStyles: AddOnPillSemanticStyles = {
  backgroundColor: paletteDark.purple.shade40,
  color: greys.white,
};
