import {datadogLogs, StatusType} from '@datadog/browser-logs';
import {merge} from 'lodash';

import {globalThis} from '../../helpers/browser/globalThis';
import {FrontConfig} from '../../helpers/bundler/bundlerDefineConfig';
import {sessionId} from '../http/session';
import {
  DatadogLogger,
  DatadogUserConfiguration,
  LogLevelsEnum,
  LogMessageContext,
  LogMessageContextValue,
} from './loggerCommon';

/*
 * Constants.
 */

async function initMobileAsync(_config: DatadogUserConfiguration): Promise<void> {
  throw new Error('This function is not available on this platform. Use "initDesktop" instead.');
}

async function initDesktop(config: DatadogUserConfiguration): Promise<void> {
  datadogLogs.init({...config, service: 'front-client'});
}

function setClientAttributes() {
  datadogLogs.setGlobalContext({
    env: FrontConfig.FRONT_ENV,
    // This is separate from Datadog's cookie-based Session ID.
    front_session_id: sessionId,
    client: {
      commit: FrontConfig.GIT_COMMIT,
      build_timestamp: FrontConfig.BUILD_TIMESTAMP,
    },
    navigator_language: getNavigatorLanguage(),
  });
}

function log(
  message: string,
  context: LogMessageContext | undefined,
  methodName?: LogLevelsEnum,
  loggerName?: string | symbol,
) {
  // Override the logger name with ours.
  const extendedContext = merge({}, context, {logger: {name: loggerName}});
  if (!methodName) {
    datadogLogs.logger.log(message, extendedContext);
    return;
  }

  const statusType = methodName ? StatusType[methodName] : undefined;
  datadogLogs.logger.log(message, extendedContext, statusType);
}

function setLoggerGlobalContext(newContext: LogMessageContext) {
  datadogLogs.setGlobalContext(newContext);
}

function addLoggerGlobalContext(key: string, value: LogMessageContextValue) {
  datadogLogs.setGlobalContextProperty(key, value);
}

function getNavigatorLanguage() {
  const {languages, language} = globalThis.navigator;
  if (languages) {
    return languages[0];
  }

  return language;
}

const datadogLogger: DatadogLogger = {
  initMobileAsync,
  initDesktop,
  setClientAttributes,
  log,
  setLoggerGlobalContext,
  addLoggerGlobalContext,
};

export {datadogLogger};
