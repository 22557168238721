import {InlineBannerTypesEnum} from '../../components/banners/inlineBanner/inlineBannerTypes';
import {greys, greysDark, palette, paletteDark} from '../../styles/colorStyles';

export const lightInlineBannerSemanticStyles = {
  [InlineBannerTypesEnum.INFO]: {
    backgroundColor: palette.blue.shade20,
    iconColor: palette.blue.shade50,
  },
  [InlineBannerTypesEnum.SUCCESS]: {
    backgroundColor: palette.green.shade20,
    iconColor: palette.green.shade50,
  },
  [InlineBannerTypesEnum.WARNING]: {
    backgroundColor: palette.orange.shade20,
    iconColor: palette.orange.shade50,
  },
  [InlineBannerTypesEnum.ERROR]: {
    backgroundColor: palette.red.shade20,
    iconColor: palette.red.shade50,
  },
  titleColor: greys.shade90,
  textColor: greys.shade80,
};

export type InlineBannerSemanticStyles = typeof lightInlineBannerSemanticStyles;

export const darkInlineBannerSemanticStyles = {
  [InlineBannerTypesEnum.INFO]: {
    backgroundColor: paletteDark.blue.shade20,
    iconColor: paletteDark.blue.shade50,
  },
  [InlineBannerTypesEnum.SUCCESS]: {
    backgroundColor: paletteDark.green.shade20,
    iconColor: paletteDark.green.shade50,
  },
  [InlineBannerTypesEnum.WARNING]: {
    backgroundColor: paletteDark.orange.shade20,
    iconColor: paletteDark.orange.shade50,
  },
  [InlineBannerTypesEnum.ERROR]: {
    backgroundColor: paletteDark.red.shade20,
    iconColor: paletteDark.red.shade50,
  },
  titleColor: greysDark.shade90,
  textColor: greysDark.shade90,
};
