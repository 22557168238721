/**
 * @file
 * These are explicit exports of common globals, to make use of those globals a little more explicit.
 */

import {
  buildInstrumentedAnimationFrameProvider,
  InstrumentedAnimationFrameProvider,
  isInstrumentedAnimationFrameProvider,
} from '../instrumentation/animationFrame';
import {
  buildInstrumentedIdleCallbackProvider,
  IdleCallbackProvider,
  InstrumentedIdleCallbackProvider,
  isInstrumentedIdleCallbackProvider,
} from '../instrumentation/idleCallback';
import {
  buildInstrumentedTimeoutProvider,
  InstrumentedTimeoutProvider,
  isInstrumentedTimeoutProvider,
  TimeoutProvider,
} from '../instrumentation/timeout';

type DefaultCallbacksProvider = AnimationFrameProvider & IdleCallbackProvider & TimeoutProvider;
type InstrumentedCallbacksProvider = InstrumentedAnimationFrameProvider &
  InstrumentedIdleCallbackProvider &
  InstrumentedTimeoutProvider;

/** Let's use a custom FrontWindow type with timing functions replaced with instrumented variants. */
export type FrontWindow = Omit<Window, keyof DefaultCallbacksProvider> & InstrumentedCallbacksProvider;

const globalScope = window;

export const globalWindow = buildFrontWindow(globalScope);
export const globalDocument = globalScope.document;
export const {setTimeout, setInterval, clearTimeout, clearInterval} = globalScope;

/** Find the origin of the global window. */
export function findGlobalOrigin() {
  const {protocol, host} = globalWindow.location;
  return `${protocol}//${host}`;
}

export enum IframeFeaturePoliciesEnum {
  CAMERA = 'camera',
  FULLSCREEN = 'fullscreen',
  MICROPHONE = 'microphone',
  CLIPBOARD_READ = 'clipboard-read',
  CLIPBOARD_WRITE = 'clipboard-write',
  DISPLAY_CAPTURE = 'display-capture',
  SPEAKER_SELECTION = 'speaker-selection',
  AUTOPLAY = 'autoplay',
  HID = 'hid',
}

/**
 * WARNING: This function should:
 *   - Not remove properties from the window object since other places may depend on them.
 *   - Still work if called more than once for a certain window.
 */
export function buildFrontWindow(window: Window): FrontWindow {
  if (isInstrumentedCallbacksProvider(window)) {
    // We've already added the instrumented callback providers to this window.
    return window;
  }

  const instrumentedTimeoutProvider = buildInstrumentedTimeoutProvider(window);
  const instrumentedAnimationFrameProvider = buildInstrumentedAnimationFrameProvider(window);
  const instrumentedIdleCallbackProvider = buildInstrumentedIdleCallbackProvider(window);

  return Object.assign(
    window,
    instrumentedTimeoutProvider,
    instrumentedAnimationFrameProvider,
    instrumentedIdleCallbackProvider,
  );
}

export function buildWindow(frontWindow: FrontWindow): Window {
  // WARNING: It is unsafe to use Window in our app but we may need to convert a FrontWindow to Window
  // to work with external libraries that take in a window.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return frontWindow as any as Window;
}

/*
 * Helpers.
 */

function isInstrumentedCallbacksProvider<T extends object>(
  window: T,
): window is T & InstrumentedCallbacksProvider {
  return (
    isInstrumentedAnimationFrameProvider(window) &&
    isInstrumentedIdleCallbackProvider(window) &&
    isInstrumentedTimeoutProvider(window)
  );
}

export function reloadBrowser() {
  globalWindow.location.reload();
}

/**
 * Returns true if the web app is running in standalone mode.
 */
export function isStandaloneApp() {
  // Recommended method to cover all cases with Safari
  if ('standalone' in globalWindow.navigator) {
    return globalWindow.navigator.standalone;
  }

  // Only works if a web app manifest is present
  // Recommended by https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/How_to/Create_a_standalone_app#customize_your_app_depending_on_the_display_mode
  return globalWindow.matchMedia('(display-mode: standalone)').matches;
}
/**
 * Return true when `display_override` is set to window-controls-overlay in the web app manifest.
 * The value of `display_override` in the manifest takes precedence over the `display`
 * value if the browser supports the override mode.
 */
export function hasWindowControlsOverlay() {
  return globalWindow.matchMedia('(display-mode: window-controls-overlay)').matches;
}

/**
 * Returns true if the web app has been installed as a "home screen" app.
 * Browsers may only support some of the display modes declared in the manifest.
 * Only checking for standalone mode is not enough.
 */
export function isInstalledWebApp() {
  return isStandaloneApp() || hasWindowControlsOverlay();
}
