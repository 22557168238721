import React, {createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef} from 'react';

/*
 * Types.
 */

type LayerEffectCallback = () => () => void;

interface LayerTrackerInternalContextValue {
  trackLayer: LayerEffectCallback;
  useDownstreamLayerEffect: (effect: LayerEffectCallback) => () => void;
}

/*
 * Context.
 */

export const LayerTrackerContext = createContext<LayerTrackerInternalContextValue>({
  trackLayer: () => () => {},
  useDownstreamLayerEffect: () => () => {},
});

/*
 * Provider.
 */

/** Provides a way to track layer mounts and unmounts. */
export const LayerListenerProvider = (props: PropsWithChildren<unknown>) => {
  const subscribers = useRef<Set<LayerEffectCallback>>(new Set());

  const trackerInternalValue = useMemo(
    (): LayerTrackerInternalContextValue => ({
      trackLayer: () => {
        const unsubcribers = [...subscribers.current.values()].map((subscriber) => subscriber());

        return () => {
          unsubcribers.forEach((unsubscribe) => unsubscribe());
        };
      },
      useDownstreamLayerEffect: (effect) => {
        subscribers.current.add(effect);
        return () => {
          subscribers.current.delete(effect);
        };
      },
    }),
    [],
  );

  return (
    <LayerTrackerContext.Provider value={trackerInternalValue}>{props.children}</LayerTrackerContext.Provider>
  );
};

/*
 * Hook.
 */

/** Calls the provided effect when a layer is mounted or unmounted. */
export function useLayerEffect(effect: LayerEffectCallback) {
  const {useDownstreamLayerEffect: listenerUseLayerEffect} = useContext(LayerTrackerContext);

  useEffect(() => {
    const remove = listenerUseLayerEffect(effect);
    return () => remove();
  });
}
