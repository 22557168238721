import * as O from 'fp-ts/Option';
import _, {debounce, isEqual, throttle, ThrottleSettings} from 'lodash';
import {
  DependencyList,
  ForwardedRef,
  MutableRefObject,
  Ref,
  RefCallback,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {BehaviorSubject, distinctUntilChanged, Observable} from 'rxjs';

import {globalWindow} from '../../browser/browserHelpers';
import {buildCancelTokenSource, CancelToken, CancelTokenSource} from '../../utilities/asyncCancelHelpers';
import {DeferredFunc} from '../../utilities/asyncHelpers';
import {isShallowEqual} from '../../utilities/objectHelpers';

/** Returns the passed value as it was after the last successful render of the component. */
export function usePrevious<T>(value: T) {
  const ref = useRef<T | null>(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useConstantAssertion(value: unknown, onError: () => void): void {
  const valueRef = useRef(value);
  if (valueRef.current !== value) {
    onError();
  }
}

/** Returns a setTimeout-like function that clears the previous timeout when re-invoked or when the component unmounts. */
export function useTimeout(): [(handler: () => void, timeout: number) => void, () => void] {
  const handleRef = useRef<number>();

  useEffect(() => () => globalWindow.clearTimeout(handleRef.current), []);

  const safeSetTimeout = useCallback((handler: () => void, timeout: number) => {
    globalWindow.clearTimeout(handleRef.current);
    handleRef.current = globalWindow.setInstrumentedTimeout(handler, timeout);
  }, []);
  const safeClearTimeout = useCallback(() => globalWindow.clearTimeout(handleRef.current), []);

  return [safeSetTimeout, safeClearTimeout];
}

/** Returns a function that chains together multiple setTimeouts back-to-back-to-back.
 *
 *  e.g.
 *    const setTimeoutChain = useTimeoutChain();
 *    setTimeoutChain([
 *       [() => console.log("A"), 100],
 *       [() => console.log("B"), 200],
 *       [() => console.log("C"), 300]
 *    ]);
 *
 *   The above will wait 100ms, print A, then wait 200ms, print B, then wait 300ms, print C.
 */
export function useTimeoutChain() {
  const [safeSetTimeout] = useTimeout();

  return (callbackAndDelayChain: Array<[() => void, number]>) =>
    callbackAndDelayChain.reduce(
      (promiseChain, [callback, delay]) =>
        promiseChain.then(
          () =>
            new Promise((resolve, reject) => {
              safeSetTimeout(() => {
                try {
                  callback();
                } catch (error) {
                  reject(error);
                }
                resolve();
              }, delay);
            }),
        ),
      Promise.resolve(),
    );
}

/** Returns a setInterval-like function that clears the previous interval when re-invoked or when the component unmounts. */
export function useInterval(): [(handler: () => void, timeout: number) => void, () => void] {
  const handleRef = useRef<number | undefined>();

  useEffect(
    () => () => {
      if (handleRef.current) {
        globalWindow.clearInterval(handleRef.current);
      }
    },
    [],
  );

  const setIntervalFunc = useCallback((handler: () => void, timeout: number) => {
    if (handleRef.current) {
      globalWindow.clearInterval(handleRef.current);
    }

    handleRef.current = globalWindow.setInstrumentedInterval(handler, timeout);
  }, []);
  const clearIntervalFunc = useCallback(() => {
    globalWindow.clearInterval(handleRef.current);
    handleRef.current = undefined;
  }, []);
  return [setIntervalFunc, clearIntervalFunc];
}

function useDeferred<T extends (...args: Array<any>) => any>(
  func: T,
  makeDeferredFunc: (currentFunc: T) => DeferredFunc<T>,
  dependencies: DependencyList,
): DeferredFunc<T> {
  // Always maintain a reference to the latest version of the function.
  const funcRef = useRef(func);
  funcRef.current = func;

  // Create a function that will always call the current implemention from the ref.
  const currentFunc = ((...args: Array<any>) => funcRef.current(...args)) as T;

  // Initialize the more permanent refs. The deferred function + the previous dependencies.
  const deferredFuncRef = useLazyRef(() => makeDeferredFunc(currentFunc));
  const prevDependenciesRef = useRef(dependencies);

  // If the dependencies change, re-create the deferred function.
  if (!isShallowEqual(prevDependenciesRef.current, dependencies)) {
    deferredFuncRef.current = makeDeferredFunc(currentFunc);
    prevDependenciesRef.current = dependencies;
  }

  // Return the current version of the deferred function.
  return deferredFuncRef.current;
}

/** Debounce the provided function for the specified amount of time. */
export function useDebounce<T extends (...args: Array<any>) => any>(func: T, wait?: number) {
  const debouncedFunc = useDeferred(func, (currentFunc) => debounce(currentFunc, wait), [wait]);

  useEffect(() => () => debouncedFunc.cancel(), [debouncedFunc]);

  return debouncedFunc;
}

/** Debounce the provided value for the specified amount of time. */
export function useDebouncedValue<T>(value: T, wait?: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debouncedSetDebouncedValue = useDebounce(setDebouncedValue, wait);

  useEffect(
    () => {
      debouncedSetDebouncedValue(value);
    },
    [debouncedSetDebouncedValue, value], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function useThrottle<T extends (...args: Array<any>) => any>(
  func: T,
  wait?: number,
  options?: ThrottleSettings,
) {
  // Don't reset the throttle for a new options object instance with the same values.
  const optionsRef = useRef(options);
  if (!isEqual(optionsRef.current, options)) {
    optionsRef.current = options;
  }

  const throttledFunc = useDeferred(func, (currentFunc) => throttle(currentFunc, wait, optionsRef.current), [
    wait,
    optionsRef.current,
  ]);

  useEffect(() => () => throttledFunc.cancel(), [throttledFunc]);

  return throttledFunc;
}

/** Provide a ref indicating whether the current component was unmounted. */
export function useWasUnmounted() {
  const wasUnmountedRef = useRef(false);
  useEffect(
    () => () => {
      wasUnmountedRef.current = true;
    },
    [],
  );

  return {wasUnmountedRef};
}

/** Wrap onRequestClose to not go through if the component has already been unmounted. */
export function useOnRequestClose<T extends (...args: Array<any>) => any>(func: T | undefined): T {
  const {wasUnmountedRef} = useWasUnmounted();

  // Wrap the provided function to only call it if we're still mounted.
  const onRequestClose = useCallback(
    (...args: Array<any>) => {
      if (wasUnmountedRef.current || !func) {
        return;
      }

      func(...args);
    },
    [wasUnmountedRef, func],
  );

  return onRequestClose as T;
}

/**
 * Generates a builder function which creates a cancel token. When a token is generated,
 * the previous token is automatically canceled. The tokens are also canceled when the
 * component is unmounted (similar to useLazyUnmountCancelToken.)
 *
 * Because this function always cancels the previous token, parallel requests should
 * typically share a token between them:
 *
 *   const {buildCancelToken} = useBuildCancelToken();
 *   const cancelToken = buildCancelToken();
 *   Promise.all(data.map(item => fetch(item, {cancelToken})));
 *
 * Additionally, separate callback functions should generally have their own useBuildCancelToken
 * hook unless you want them to cancel each other's requests:
 *
 *   const {buildCancelToken: buildPreviewCancelToken} = useBuildCancelToken();
 *   const onClickPreview = () => {
 *     // use buildPreviewCancelToken here.
 *   };
 *
 *   const {buildCancelToken: buildDownloadCancelToken} = useBuildCancelToken();
 *   const onClickDownload = () => {
 *     // use buildDownloadCancelToken here.
 *   };
 *
 * If you do not want the auto-cancelation behavior whenever a token is generated, but still
 * want to cancel tokens on component unmount, use useLazyUnmountCancelToken instead.
 */
export function useBuildCancelToken() {
  const cancelTokenSourceRef = useLazyRef(() => buildCancelTokenSource());

  const cancelCancelToken = useCallback(() => {
    // Cancel the existing token.
    cancelTokenSourceRef.current.cancel();
  }, [cancelTokenSourceRef]);

  const buildCancelToken = useCallback(() => {
    // Cancel the existing token.
    cancelCancelToken();

    // Create a new cancel token.
    cancelTokenSourceRef.current = buildCancelTokenSource();

    // Return the new token.
    return cancelTokenSourceRef.current.token;
  }, [cancelTokenSourceRef, cancelCancelToken]);

  // Cancel on unmount.
  useEffect(() => () => cancelCancelToken(), [cancelTokenSourceRef, cancelCancelToken]);

  return {buildCancelToken, cancelCancelToken};
}

/**
 * Wraps useEffect. Provides a fresh cancel token whenever the effect is called. The previous
 * cancel token is automatically canceled in the effect cleanup.
 */
export function useCancelableEffect(
  effect: (cancelToken: CancelToken) => (() => void) | void,
  deps: DependencyList,
) {
  useEffect(() => {
    const cancelTokenSource = buildCancelTokenSource();
    const effectCleanup = effect(cancelTokenSource.token);
    return () => {
      cancelTokenSource.cancel();
      effectCleanup?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

/**
 * Returns a builder function which creates a cancel token that will be cancelled on unmount. The
 * builder function will always return the same token while the component is mounted. The main
 * use case for this token is to prevent setState() calls after a component has already unmounted.
 *
 * In general, useBuildCancelToken is preferred unless you need this specific behavior.
 *
 * Limitation: Because this function always returns the same token while the component is mounted,
 * you cannot use this token if you need to manually cancel async operations (since the token will
 * be canceled and you won't be able to get a new one.)
 *
 * Usage:
 *   const buildUnmountCancelToken = useLazyUnmountCancelToken();
 *   const cancelToken = buildUnmountCancelToken();
 */
export function useLazyUnmountCancelToken() {
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const buildUnmountCancelToken = useCallback(() => {
    // Make the token source if it hasn't been created.
    const cancelTokenSource = cancelTokenSourceRef.current || buildCancelTokenSource();
    cancelTokenSourceRef.current = cancelTokenSource;

    // Return the new token.
    return cancelTokenSource.token;
  }, [cancelTokenSourceRef]);

  // Trigger on unmount.
  useEffect(
    () => () => {
      if (cancelTokenSourceRef.current) {
        // If we've created a cancel token, let's cancel it.
        cancelTokenSourceRef.current.cancel();
        cancelTokenSourceRef.current = undefined;
      }
    },
    [cancelTokenSourceRef],
  );

  return buildUnmountCancelToken;
}

const missingRefValue = Symbol('missingRefValue');

/** Flavor of useRef with lazy initialization. */
export function useLazyRef<T>(factory: () => T) {
  // Create the ref with a unique symbol as the initial value.
  const ref = useRef<T | typeof missingRefValue>(missingRefValue);

  // If it wasn't initialized, do it now.
  if (ref.current === missingRefValue) {
    ref.current = factory();
  }

  // Return the ref.
  return ref as MutableRefObject<T>;
}

/** Returns a stable constant that will be initialized on the first render. */
export function useConstant<T>(factory: () => T) {
  const ref = useLazyRef(factory);
  return ref.current;
}

/** Similar to useMemo, but deep compares the result for more stability. */
export function useDeepMemo<T>(factory: () => T, dependencies: DependencyList) {
  const value = useMemo(factory, dependencies);
  const lastValueRef = useRef<O.Option<T>>(O.none);

  // Deep compare the values to check if something has changed.
  const lastValue = lastValueRef.current;
  const result = O.isSome(lastValue) && isEqual(value, lastValue.value) ? lastValue.value : value;

  // If the result has changed, update the ref for next time.
  useEffect(() => {
    lastValueRef.current = O.some(result);
  }, [result]);

  return result;
}

/** Returns the keys that changed between component renders. For debugging only. */
export function useChangedKeys<T extends object>(object: T) {
  const previousObject = usePrevious(object);
  if (!previousObject) {
    return {};
  }

  return _(object)
    .mapValues((value, key) => {
      const previousValue = previousObject[key as keyof typeof previousObject];
      return {
        hasSameIdentity: value === previousValue,
        isEqual: isEqual(value, previousValue),
        previousValue,
        value,
      };
    })
    .pickBy((comparisonResult) => !comparisonResult.hasSameIdentity)
    .value();
}

/** Logs the keys that changed between component renders. For debugging only. */
export function useLogChangedKeysDebugOnly<T extends object>(object: T, label: string = '') {
  const changedKeys = useChangedKeys(object);
  if (Object.keys(changedKeys).length > 0) {
    // eslint-disable-next-line no-console
    console.debug(label, `Changed keys`, changedKeys);
  }
}

/** Returns a function that remembers its last arguments until after the next completed render. */
export function useLastCallEffect<A extends Array<any>>(
  effectCallback: (...args: A) => void,
): (...args: A) => void {
  const lastArgsRef = useRef<A>();

  useEffect(() => {
    if (!lastArgsRef.current) {
      return;
    }

    const lastArgs = lastArgsRef.current;
    lastArgsRef.current = undefined;

    effectCallback(...lastArgs);
  });

  return useCallback((...args) => {
    lastArgsRef.current = args;
  }, []);
}

// Forwarded ref can be a function or an object
export const useForwardRef = <T>(ref: ForwardedRef<T>, initialValue: any = null) => {
  const targetRef = useRef<T>(initialValue);

  useEffect(() => {
    if (!ref) {
      return;
    }

    if (typeof ref === 'function') {
      ref(targetRef.current);
    } else {
      // eslint-disable-next-line no-param-reassign
      ref.current = targetRef.current;
    }
  }, [ref]);

  return targetRef;
};
/** Creates a new ref and observable pair. The observable emits the ref's value when subscribed to and when it changes. */
export function useObservableRef<T>(): [Ref<T>, Observable<T | null>] {
  // Create an observable.
  const current$ = useConstant(() => new BehaviorSubject<T | null>(null));

  // Create a callback ref that feeds into the observable.
  const ref = useCallback<RefCallback<T>>((current) => current$.next(current), [current$]);

  return [ref, current$];
}

/** Returns an observable that emits an existing ref's value, when subscribed to and when it changes. */
export function useObservableFromRef<T>(ref: RefObject<T>): Observable<T | null> {
  // Create an observable.
  const current$ = useConstant(() => new BehaviorSubject<T | null>(ref.current));

  // Feed the ref value into the observable after each render (in the effect phase, not layout phase).
  useEffect(() => current$.next(ref.current));

  // Return a de-duped version of the observable.
  return useMemo(() => current$.pipe(distinctUntilChanged()), [current$]);
}

const SEARCH_QUERY_DEBOUNCE = 300;
export function useQueryDebounce<T extends string | undefined>(query: T): T {
  return useDebouncedValue(query, SEARCH_QUERY_DEBOUNCE);
}
