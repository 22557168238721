import React, {Component, createRef, RefObject} from 'react';

import {KeyboardActionsEnum} from '../../../../core/src/helpers/browser/keyboardHelpers';
import {InteractionComponentProps} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {ClickEventHandler, RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {ChromelessButton} from '../buttons/chromelessButton';
import {KeyboardShortcuts} from '../keyboard/keyboardShortcuts';

/*
 * Props.
 */

type MethodWrapper = <T extends ((...args: Array<any>) => any) | undefined>(method: T) => WrappedMethod<T>;
type WrappedMethod<T extends ((...args: Array<any>) => any) | undefined> = T extends (
  ...args: Array<any>
) => any
  ? (...args: Parameters<T>) => ReturnType<T>
  : () => void;

export interface PopoverTransferProps {
  anchor: RefObject<HTMLElement>;
  anchorWidth?: number;
  onRequestClose: () => void;
  wrap: MethodWrapper;
}

export interface PopoverCoordinatorProps extends InteractionComponentProps {
  renderButton: RendererOf<boolean>;
  renderModal: RendererOf<PopoverTransferProps>;
  keyboardAction?: KeyboardActionsEnum;
  onPopoverToggle?: (arg: boolean) => void;
  className?: string;
  shouldPreventMouseDown?: boolean;
  shouldHoverWhenOpen?: boolean;
  children?: RendererOf<RefObject<HTMLElement>>;
  isDisabled?: boolean;
  isModalInitiallyOpen?: boolean;
  targetRef?: RefObject<HTMLDivElement>;
  tabIndex?: number;
}

/*
 * Component.
 */

interface PopoverCoordinatorState {
  isModalOpen: boolean;
}

export class PopoverCoordinator extends Component<PopoverCoordinatorProps, PopoverCoordinatorState> {
  constructor(props: PopoverCoordinatorProps) {
    super(props);
    this.buttonRef = createRef<HTMLDivElement>();
    this.state = {isModalOpen: false};
  }

  private buttonRef: RefObject<HTMLDivElement>;

  /*
   * Event handlers.
   */

  private readonly onKeyboardAction = () => {
    if (this.props.isDisabled) {
      return;
    }

    this.toggleIsModalOpen();
  };

  private readonly onButtonClick: ClickEventHandler = (event) => {
    if (this.props.isDisabled) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    this.toggleIsModalOpen();
  };

  /*
   * Lifecycle.
   */

  componentDidMount() {
    // The modal need a ref to the button to be opened. This ref does not exist at the first render.
    // To have the modal opened on mount, we have to render again and we will be able to use the ref.
    if (this.props.isModalInitiallyOpen) {
      this.setState({isModalOpen: true});
    }
  }

  /*
   * Helpers.
   */

  private readonly toggleIsModalOpen = () => {
    this.setState(
      ({isModalOpen}) => ({
        isModalOpen: !isModalOpen,
      }),
      this.maybeNotifyOnPopoverToggle,
    );
  };

  private readonly wrap = <T extends ((...args: Array<any>) => any) | undefined>(method: T) => {
    // Create a wrapper that closes the modal before calling the method.
    const wrapper = (...args: Array<any>) => {
      this.onRequestClose();
      return method && method(...args);
    };

    return wrapper as WrappedMethod<T>;
  };

  private readonly onRequestClose = () => {
    this.setState({isModalOpen: false}, this.maybeNotifyOnPopoverToggle);
  };

  private maybeNotifyOnPopoverToggle = () => {
    const {onPopoverToggle} = this.props;
    if (!onPopoverToggle) {
      return;
    }

    onPopoverToggle(this.state.isModalOpen);
  };

  /*
   * Render.
   */

  render() {
    // If no keyboard action was provided, render directly.
    const {keyboardAction} = this.props;
    if (!keyboardAction) {
      return this.renderContent();
    }

    // Otherwise, listen for key presses.
    const keyHandlers = {
      [keyboardAction]: this.onKeyboardAction,
    };

    return <KeyboardShortcuts handlers={keyHandlers}>{this.renderContent()}</KeyboardShortcuts>;
  }

  private renderContent() {
    const {shouldHoverWhenOpen, children} = this.props;
    const {isModalOpen} = this.state;
    return (
      <>
        <ChromelessButton
          ref={this.buttonRef}
          onClick={this.onButtonClick}
          className={this.props.className}
          hasHover={shouldHoverWhenOpen && isModalOpen}
          shouldPreventMouseDown={this.props.shouldPreventMouseDown}
          isOpen={isModalOpen}
          isDisabled={this.props.isDisabled}
          interactionId={this.props.interactionId}
          tabIndex={this.props.tabIndex}
        >
          {this.props.renderButton(isModalOpen)}
        </ChromelessButton>
        {isModalOpen && this.renderModal()}
        {children && children(this.buttonRef)}
      </>
    );
  }

  private renderModal() {
    const currentButton = this.buttonRef.current;
    if (!currentButton) {
      return null;
    }

    const modalProps: PopoverTransferProps = {
      anchor: this.props.targetRef?.current ? this.props.targetRef : this.buttonRef,
      anchorWidth: currentButton.offsetWidth,
      onRequestClose: this.onRequestClose,
      wrap: this.wrap,
    };
    return this.props.renderModal(modalProps);
  }
}
