import {isArray, isPlainObject} from 'lodash';

import {isProduction} from '../environment/envHelpers';

const hasOwn = Object.prototype.hasOwnProperty;

/* eslint no-self-compare: 0 */
function is(x: any, y: any) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  }

  return x !== x && y !== y;
}

/**
 * ShallowEqual implementation similar to Redux'.
 * Based off https://github.com/reduxjs/react-redux/blob/master/src/utils/shallowEqual.js
 */
export function isShallowEqual(objA: any, objB: any) {
  if (is(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  if (!(isArray(objA) || isPlainObject(objA)) || !(isArray(objB) || isPlainObject(objB))) {
    if (!isProduction()) {
      // eslint-disable-next-line no-console
      console.warn(
        'Attempt to shallow compare non-plain objects, which will never compare equal',
        objA,
        objB,
      );
    }

    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwn.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }

  return true;
}

export function undefinedIfEmpty<T extends object>(obj: T): T | undefined {
  if (Object.keys(obj).length === 0) {
    return undefined;
  }

  if (Object.values(obj).every((v) => v === undefined)) {
    return undefined;
  }

  return obj;
}
