import * as O from 'fp-ts/lib/Option';
import {noop} from 'lodash';
import {lastValueFrom, Observable} from 'rxjs';

import {CustomError} from '../types/customErrors';

/*
 * Types.
 */

export interface CancelToken {
  promise: Promise<void>;
  subscribe(onCancel: CancelHandler): CancelSubscription;
  throwIfRequested(): void;
}

type CancelHandler = () => void;

interface CancelSubscriber {
  onCancel: CancelHandler;
}

interface CancelSubscription {
  unsubscribe(): void;
}

export interface CancelTokenSource {
  cancel(): void;
  token: CancelToken;
}

export class CancelError extends CustomError {
  constructor() {
    super('Process cancelled.');
  }
}

/*
 * Constants.
 */

const cancelledCancelTokenSource = buildCancelTokenSource();
cancelledCancelTokenSource.cancel();

/** Always-cancelled cancel token. */
export const cancelledCancelToken = Object.freeze(cancelledCancelTokenSource.token);

/** Never-cancelled cancel token. */
export const neverCancelToken = Object.freeze(buildCancelTokenSource().token);

/*
 * API.
 */

export function buildCancelTokenSource(): CancelTokenSource {
  let isCancelled = false;
  let maybeResolve: O.Option<() => void> = O.none;
  const subscribers: Array<CancelSubscriber> = [];

  const cancel = () => {
    if (isCancelled) {
      return;
    }

    if (O.isNone(maybeResolve)) {
      throw new Error('Promise initialization failed');
    }

    isCancelled = true;
    maybeResolve.value();
    subscribers.forEach((subscriber) => subscriber.onCancel());

    // Clean up our references.
    maybeResolve = O.some(noop);
    subscribers.splice(0, subscribers.length);
  };

  const subscribe = (onCancel: CancelHandler): CancelSubscription => {
    if (isCancelled) {
      onCancel();
      return {unsubscribe: noop};
    }

    // Create a subscriber object so that the same callback can be subscribed more than once.
    const subscriber = {onCancel};
    subscribers.push(subscriber);

    return {
      unsubscribe: () => {
        const index = subscribers.indexOf(subscriber);
        if (index === -1) {
          return;
        }

        subscribers.splice(index, 1);
      },
    };
  };

  const throwIfRequested = () => {
    if (!isCancelled) {
      return;
    }

    throw new CancelError();
  };

  return {
    cancel,
    // Create the token and token.promise inline so they are not retained by the closures created above and
    // can be garbage collected after cancellation.
    token: {
      promise: new Promise<void>((resolve) => {
        maybeResolve = O.some(resolve);
      }),
      subscribe,
      throwIfRequested,
    },
  };
}

export function combineCancelTokens(token: CancelToken, ...tokens: Array<CancelToken>) {
  const combinedTokenSource = buildCancelTokenSource();

  const combinedPromise = Promise.race([token.promise, ...tokens.map((t) => t.promise)]);
  combinedPromise.then(() => combinedTokenSource.cancel());

  return combinedTokenSource.token;
}

/**
 * @deprecated This function is dangerous because it works only if the Observable emits one value and completes.
 *
 * If the Observable does not emit any values, the Promise will reject with an EmptyError. If the Observable does not
 * complete, the Promise will hang.
 */
export async function toCancelablePromise<R>(source$: Observable<R>, cancelToken?: CancelToken): Promise<R> {
  if (!cancelToken) {
    return lastValueFrom(source$);
  }

  cancelToken.throwIfRequested();

  // FIXME: We should probably use rxjs.race so we unsubscribe from the source observable when canceled.
  return Promise.race([
    lastValueFrom(source$),
    cancelToken.promise.then(() => {
      throw new CancelError();
    }),
  ]);
}

/** To upgrade a promise-only cancel token to a subscribable cancel token. */
export function toSubscribableCancelToken(cancelToken: {promise: Promise<unknown>}): CancelToken;
/** To upgrade a promise-only cancel token to a subscribable cancel token. */
export function toSubscribableCancelToken(
  cancelToken: {promise: Promise<unknown>} | undefined,
): CancelToken | undefined;
/** To upgrade a promise-only cancel token to a subscribable cancel token. */
export function toSubscribableCancelToken(
  cancelToken: {promise: Promise<unknown>} | undefined,
): CancelToken | undefined {
  if (!cancelToken) {
    return undefined;
  }

  const source = buildCancelTokenSource();
  cancelToken.promise.then(() => source.cancel());
  return source.token;
}
