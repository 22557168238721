import React, {
  ChangeEventHandler,
  ClipboardEventHandler,
  Component,
  createRef,
  CSSProperties,
  RefObject,
} from 'react';
import styled, {css} from 'styled-components';

import {hasFocus} from '../../../../core/src/helpers/browser/domHelpers';
import {
  buildInteractionIdProps,
  InteractionComponentProps,
} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {EventHandler, KeyboardEventHandler} from '../../../../core/src/helpers/react/reactHelpers';
import {appearanceNone, textSelection} from '../../styles/mixins';
import {Anchor} from '../reposition/repositionHelpers';

/*
 * Props.
 */

export enum InputTypesEnum {
  TEXT = 'text',
  PASSWORD = 'password',
}

export interface InputInheritableProps {
  /** The text to display when the input is empty. */
  placeholder?: string;
  /** Set the initial value of the input for uncontrolled components. */
  defaultValue?: string;
  /** Set the value of the input for controlled components. */
  value?: string;

  /** The maximum number of characters allowed in the input. */
  maxLength?: number;

  /** Abstracted event handler providing the updated text. */
  onChange?: (arg: string) => void;
  /** Event handler called for every keydown event. */
  onKeyDown?: KeyboardEventHandler;
  /** Event handler called when the input receives focus. */
  onFocus?: EventHandler<HTMLInputElement>;
  /** Event handler called when the input is blurred. */
  onBlur?: EventHandler<HTMLInputElement>;
  /** Abstracted event handler providing the updated text. */
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
  /** Whether the input should focus after mount. */
  shouldFocus?: boolean;

  /** External ref. */
  inputRef?: RefObject<HTMLInputElement>;
  /** Whether the input is readonly. */
  isReadOnly?: boolean;
}

export interface InputProps extends InputInheritableProps, InteractionComponentProps {
  type: InputTypesEnum;
  inputId?: string;
  className?: string;
  style?: CSSProperties;
  isDisabled?: boolean;
  hasSpellCheck?: boolean;
  hasPlaceholderEllipsesOverflow?: boolean;
}

/*
 * Style.
 */

interface InputStyleProps {
  $hasPlaceholderEllipsesOverflow: boolean;
}

const StyledInput = styled.input<InputStyleProps>`
  display: block;
  color: inherit;
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0;

  ${appearanceNone()};
  ${textSelection()};

  &::placeholder {
    color: ${(p) => p.theme.greys.shade60};
  }

  ${(p) => addPlaceholderOverflowStyles(p)}
`;

function addPlaceholderOverflowStyles(p: InputStyleProps) {
  if (p.$hasPlaceholderEllipsesOverflow) {
    return css`
      &:placeholder-shown {
        text-overflow: ellipsis;
      }
    `;
  }

  return '';
}

/*
 * Component.
 */

interface InputSelection {
  start: number;
  end: number;
}

export class Input extends Component<InputProps> {
  constructor(props: InputProps) {
    super(props);
    this.inputRef = props.inputRef || createRef<HTMLInputElement>();
  }

  private readonly inputRef: RefObject<HTMLInputElement>;

  /*
   * API.
   */

  hasFocus() {
    const currentInput = this.inputRef.current;
    return Boolean(currentInput && hasFocus(currentInput));
  }

  focus(shouldSelectContent?: boolean) {
    const currentInput = this.inputRef.current;
    if (!currentInput) {
      return;
    }

    currentInput.focus();

    if (!shouldSelectContent) {
      return;
    }

    currentInput.setSelectionRange(0, currentInput.value.length);
  }

  getSelection(): InputSelection | null {
    const currentInput = this.inputRef.current;
    if (!currentInput) {
      return null;
    }

    return {
      start: currentInput.selectionStart || 0,
      end: currentInput.selectionEnd || 0,
    };
  }

  moveToEnd() {
    const currentInput = this.inputRef.current;
    if (!currentInput) {
      return;
    }

    const endIndex = currentInput.value.length;
    currentInput.setSelectionRange(endIndex, endIndex);
  }

  getValue() {
    const currentInput = this.inputRef.current;
    return (currentInput && currentInput.value) || '';
  }

  replaceSelectedText(text: string) {
    const currentInput = this.inputRef.current;
    if (!currentInput) {
      return;
    }

    const {value, selectionStart, selectionEnd} = currentInput;
    currentInput.value = value.substring(0, selectionStart || 0) + text + value.substring(selectionEnd || 0);
  }

  getAnchor(): Anchor {
    return this.inputRef;
  }

  getElement(): HTMLInputElement | null {
    return this.inputRef.current;
  }

  /*
   * Event handlers.
   */

  private readonly onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    // If we don't have a change handler, nothing to do.
    const {onChange} = this.props;
    if (!onChange) {
      return;
    }

    const value = event.currentTarget.value || '';
    onChange(value);
  };

  /*
   * Render.
   */

  render() {
    const interactionProps = this.props.interactionId
      ? buildInteractionIdProps(this.props.interactionId)
      : buildInteractionIdProps(this.props.inputId);

    return (
      <StyledInput
        id={this.props.inputId}
        {...interactionProps}
        className={this.props.className}
        maxLength={this.props.maxLength}
        style={this.props.style}
        ref={this.inputRef as any}
        disabled={this.props.isDisabled}
        type={this.props.type}
        defaultValue={this.props.defaultValue}
        value={this.props.value}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        onKeyDown={this.props.onKeyDown}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onPaste={this.props.onPaste}
        autoFocus={this.props.shouldFocus}
        autoComplete="off"
        spellCheck={this.props.hasSpellCheck}
        $hasPlaceholderEllipsesOverflow={Boolean(this.props.hasPlaceholderEllipsesOverflow)}
        readOnly={this.props.isReadOnly}
      />
    );
  }
}
