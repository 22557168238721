import {SideObject} from '@floating-ui/react-dom';
import {concat, noop, without} from 'lodash';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import {globalWindow} from '../../../../core/src/helpers/browser/browserHelpers';
import {getWindow} from '../../../../core/src/helpers/browser/domHelpers';
import {
  buildInteractionIdProps,
  InteractionComponentProps,
} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {usePrevious} from '../../../../core/src/helpers/react/hooks/hookHelpers';
import {RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {RepositionPlacement} from '../reposition/repositionHelpers';
import {TooltipCondition, TooltipContext, TooltipContextProps} from './tooltipContext';
import {TooltipCoordinatorAnchorRenderer} from './tooltipCoordinatorAnchorRenderer';
import {TooltipCoordinatorTooltipRenderer} from './tooltipCoordinatorTooltipRenderer';
import {TooltipCoordinatorRenderProps} from './tooltipCoordinatorTypes';
import {tooltipDefaultDelay} from './tooltipHelpers';

/*
 * Props.
 */

export interface TooltipCoordinatorProps extends InteractionComponentProps {
  className?: string;
  isInline?: boolean;
  isMultiline?: boolean;
  customMargin?: number | string;
  maxWidth?: number;
  minWidth?: number;
  onRender?: () => void;
  /** Renders the target with additional information, will ignore children passed in */
  renderTarget?: RendererOf<boolean>;
  placement?: RepositionPlacement;
  preventOverflowPadding?: Partial<SideObject>;
  render?: RendererOf<TooltipCoordinatorRenderProps> | false;
  /** This removes the styled wrapper Tooltip applies */
  plainTooltip?: ReactNode;
  hideOnMouseDown?: boolean;
  /** Persist the tooltip for a few hundred ms after the container loses focus. */
  persistent?: boolean;
  iconColor?: string;
  iconHoverColor?: string;
  tooltipColor?: string;
  delay?: number;
  /** Handler for showing the tooltip. */
  onShow?: () => void;
  /** Handler for hiding the tooltip. */
  onHide?: () => void;
  borderRadius?: number;
  children?: ReactNode;
  isBoxShadowDisabled?: boolean;
  tooltipZIndex?: number;
  /** For development purposes. */
  isAlwaysVisible?: boolean;
}

/*
 * Style.
 */

export const StyledBlockWrapper = styled.div`
  min-width: 0;
`;

export const StyledInlineWrapper = styled.span`
  display: inline-block;
  vertical-align: bottom;
`;

/*
 * Component.
 */

export const TooltipCoordinator: FC<PropsWithChildren<TooltipCoordinatorProps>> = (props) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const conditionsRef = useRef<Array<TooltipCondition>>([]);
  const {isVisible, hide, handlers} = useTooltipVisibility({
    anchorRef,
    conditionsRef,
    showDelay: props.delay,
    hideDelay: props.persistent ? 300 : 0,
    hideOnMouseDown: props.hideOnMouseDown,
    hasContent: Boolean(props.plainTooltip) || Boolean(props.render),
    isAlwaysVisibleDevOverride: props.isAlwaysVisible,
  });

  useNotifyListeners(isVisible, props.onShow, props.onHide);

  const contextValue = useMemo<TooltipContextProps>(
    () => ({
      registerCondition: (condition) => {
        conditionsRef.current = concat(conditionsRef.current, condition);
        return () => {
          conditionsRef.current = without(conditionsRef.current, condition);
        };
      },
    }),
    [conditionsRef],
  );

  const Wrapper = props.isInline ? StyledInlineWrapper : StyledBlockWrapper;

  return (
    <Wrapper
      ref={anchorRef}
      className={props.className}
      onMouseEnter={handlers.onMouseEnter}
      onMouseLeave={handlers.onMouseLeave}
      onMouseDown={handlers.onMouseDown}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buildInteractionIdProps(props.interactionId)}
    >
      <TooltipContext.Provider value={contextValue}>
        <TooltipCoordinatorAnchorRenderer
          isVisible={isVisible}
          iconColor={props.iconColor}
          iconHoverColor={props.iconHoverColor}
          renderTarget={props.renderTarget}
        >
          {props.children}
        </TooltipCoordinatorAnchorRenderer>
      </TooltipContext.Provider>
      <TooltipCoordinatorTooltipRenderer
        render={props.render}
        onRender={props.onRender}
        placement={props.placement}
        isMultiline={props.isMultiline}
        customMargin={props.customMargin}
        maxWidth={props.maxWidth}
        minWidth={props.minWidth}
        preventOverflowPadding={props.preventOverflowPadding}
        persistent={props.persistent}
        tooltipColor={props.tooltipColor}
        borderRadius={props.borderRadius}
        isBoxShadowDisabled={props.isBoxShadowDisabled}
        tooltipZIndex={props.tooltipZIndex}
        isVisible={isVisible}
        hide={hide}
        anchorRef={anchorRef}
        plainTooltip={props.plainTooltip}
      />
    </Wrapper>
  );
};

function useNotifyListeners(isVisible: boolean, onShow?: () => void, onHide?: () => void) {
  const prevIsVisible = usePrevious(isVisible);
  if (!prevIsVisible && isVisible) {
    onShow?.();
    return;
  }

  if (prevIsVisible && !isVisible) {
    onHide?.();
  }
}

interface UseTooltipVisibilityOptions {
  anchorRef: RefObject<HTMLElement>;
  conditionsRef: RefObject<ReadonlyArray<TooltipCondition>>;
  showDelay: number | undefined;
  hideDelay: number | undefined;
  hideOnMouseDown: boolean | undefined;
  hasContent: boolean;
  isAlwaysVisibleDevOverride: boolean | undefined;
}

function useTooltipVisibility({
  anchorRef,
  conditionsRef,
  showDelay,
  hideDelay,
  hideOnMouseDown,
  hasContent,
  isAlwaysVisibleDevOverride,
}: UseTooltipVisibilityOptions) {
  const [isVisible, setIsVisible] = useState(false);
  const syncTimeoutRef = useRef(0);

  const cancelSync = useCallback(() => {
    globalWindow.clearTimeout(syncTimeoutRef.current);
    syncTimeoutRef.current = 0;
  }, []);

  const immediatelySyncState = useCallback(
    (isEnabled: boolean) => {
      cancelSync();
      setIsVisible(isEnabled);
    },
    [cancelSync],
  );

  const scheduleSync = useCallback(
    (isEnabled: boolean, timeout: number) => {
      cancelSync();

      if (!timeout) {
        immediatelySyncState(isEnabled);
        return;
      }

      syncTimeoutRef.current = globalWindow.setInstrumentedTimeout(
        () => immediatelySyncState(isEnabled),
        timeout,
      );
    },
    [immediatelySyncState, cancelSync],
  );

  const onMouseEnter = useCallback(() => {
    if (!hasContent || hasAllUnsatisfiedConditions(conditionsRef)) {
      return;
    }

    scheduleSync(true, showDelay ?? tooltipDefaultDelay);
  }, [hasContent, conditionsRef, scheduleSync, showDelay]);

  const onMouseLeave = useCallback(() => {
    scheduleSync(false, hideDelay ?? 0);
  }, [hideDelay, scheduleSync]);

  const onMouseDown = useCallback(() => {
    if (!hideOnMouseDown) {
      return;
    }

    immediatelySyncState(false);
  }, [hideOnMouseDown, immediatelySyncState]);

  useEffect(() => {
    const window = getWindow(anchorRef.current);
    if (!window) {
      return noop;
    }

    const onWindowMouseLeave = () => {
      immediatelySyncState(false);
    };

    window.addEventListener('mouseleave', onWindowMouseLeave);
    return () => {
      window.removeEventListener('mouseleave', onWindowMouseLeave);
    };
  }, [anchorRef, immediatelySyncState]);

  return {
    isVisible: isAlwaysVisibleDevOverride || isVisible,
    hide: useCallback(() => immediatelySyncState(false), [immediatelySyncState]),
    handlers: {
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
    },
  };
}

function hasAllUnsatisfiedConditions(conditionsRef: RefObject<ReadonlyArray<TooltipCondition>>) {
  if (!conditionsRef.current || !conditionsRef.current.length) {
    return false;
  }

  return conditionsRef.current.every((condition) => !condition());
}
