import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import {isFunction, isNull, isObject, isUndefined} from 'lodash';

import {makeEnumRuntimeType} from '../../models/api/types/enumTypes';
import {isDevelopment} from '../environment/envHelpers';
import {ErrorWithData, UnreachableError} from '../types/customErrors';

/** Transform an object to its readonly version. */
export function toReadonly<T>(src: T): Readonly<T> {
  return src;
}

/** Type guard for a promise. */
export function isPromise(p: any): p is Promise<any> {
  return isObject(p) && 'then' in p && isFunction(p.then);
}

/** Type guard for an integer. */
export function isInteger(p: any): p is number {
  return Number.isInteger(p);
}

/** Type guard that is the inverse of isUndefined. */
export function isDefined<T>(value: T | undefined): value is T {
  return !isUndefined(value);
}

/** Type guard that is the inverse of isNull. */
export function isNotNull<T>(value: T | null): value is T {
  return !isNull(value);
}

/** Returns the value of the property of an object when you don't know any of the types. */
export function getObjectValue(obj: unknown, key: string, fallbackValue: unknown = undefined): unknown {
  // Check whether it's an object (and not null), the property exists, and the property is not private.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return isObject(obj) && key in obj ? obj[key as keyof typeof obj] : fallbackValue;
}

export function getRecordValue<T>(obj: Record<string, T>, key: string, fallbackValue: T): T {
  return key in obj ? obj[key] : fallbackValue;
}

/** Throw if the provided value does not conform to the specified type. */
export function enforceType<T>(type: t.Type<T, any, any>, value: unknown) {
  const result = type.decode(value);
  if (!E.isRight(result)) {
    throw new ErrorWithData('Value does not conform to type.', {type, value});
  }

  return result.right;
}

/** Unless the code is unreachable, this will cause a type error and throw an error in development. */
export function assertUnreachable(_arg: never): void {
  if (isDevelopment()) {
    throw new UnreachableError(_arg);
  }
}

/**
 * Returns a runtime type that represents a key of a *sealed* object.
 *
 * Warning: this should only be used with objects whose properties at run-time are the same as at compile-time.
 */
export function makeKeyOfType<T extends {}>(name: string, object: T): t.Type<keyof T, string, unknown> {
  const keys = new Set<string>(Object.keys(object));
  const isKeyOf = (value: unknown): value is keyof T => keys.has(String(value));

  return new t.Type(
    name,
    isKeyOf,
    (value, context) => (isKeyOf(value) ? t.success(value) : t.failure(value, context)),
    (value) => String(value),
  );
}

/**
 * Returns the keys of a record.
 *
 * Warning: this does not work for all objects. See
 * https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript
 *
 * @see collectEnumKeys
 */
export function collectRecordKeys<K extends string | number | symbol, V>(obj: Record<K, V>) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.keys(obj) as Array<K>;
}

/**
 * Returns the entries of a record.
 *
 * Warning: this does not work for all objects. See
 * https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript
 */
export function collectRecordEntries<K extends string | number | symbol, V>(obj: Record<K, V>) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.entries(obj) as Array<[K, V]>;
}

/**
 * Returns the values of a record.
 *
 * Warning: this does not work for all objects. See
 * https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript
 */
export function collectRecordValues<K extends string | number | symbol, V>(obj: Record<K, V>) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.values(obj) as Array<V>;
}

export function mapRecordValues<TKey extends string | number | symbol, TValue, TMappedValue>(
  obj: Record<TKey, TValue>,
  mapper: (value: TValue, key: TKey) => TMappedValue,
): Record<TKey, TMappedValue> {
  return fromEntries(collectRecordEntries(obj).map(([key, value]) => [key, mapper(value, key)]));
}

export function filterRecordValues<TKey extends string | number | symbol, TValue>(
  obj: Record<TKey, TValue>,
  predicate: (value: TValue) => boolean,
): Partial<Record<TKey, TValue>> {
  return fromEntries(collectRecordEntries(obj).filter(([key, value]) => predicate(value)));
}

/**
 * Returns true if srcEnum is of the srcEnum type.
 *
 * @deprecated Use makeEnumRuntimeType.
 */
export function isEnumType<T extends {[key: string]: string | number}>(
  srcString: any,
  srcEnum: T,
): srcString is T[keyof T] {
  return makeEnumRuntimeType(srcEnum).is(srcString);
}

export type Dictionary<T> = {[T: string]: T | undefined};
export type Mutable<T> = {-readonly [P in keyof T]: T[P]};

export type DeepMutable<T> = T extends (...args: Array<any>) => any
  ? T
  : T extends t.Branded<string | number, any>
    ? T
    : T extends ReadonlyArray<infer TItem>
      ? Array<DeepMutable<TItem>>
      : T extends ReadonlySet<infer TItem>
        ? Set<DeepMutable<TItem>>
        : T extends ReadonlyMap<infer TKey, infer TValue>
          ? Map<DeepMutable<TKey>, DeepMutable<TValue>>
          : T extends object
            ? {
                -readonly [K in keyof T]: DeepMutable<T[K]>;
              }
            : T;

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends t.Branded<string | number, any> | undefined
    ? T[K]
    : T[K] extends object
      ? DeepPartial<T[K]>
      : T[K] extends object | undefined
        ? DeepPartial<Exclude<T[K], undefined>> | undefined
        : T[K];
};

export type DeepReadonly<T> = T extends {} ? {readonly [K in keyof T]: DeepReadonly<T[K]>} : Readonly<T>;

/*
 * Variant of Required<T> that does not strip the "| undefined".
 */

declare const pack: unique symbol;
type Packed<T> = {[pack]: T};

type PackOptional<T> = {
  [P in keyof T]: undefined extends T[P] ? Packed<T[P]> : T[P];
};

type UnpackOptional<T> = {
  [P in keyof T]: T[P] extends Packed<infer A> ? A : T[P];
};

export type FixedRequired<T> = UnpackOptional<Required<PackOptional<T>>>;

export type OrNull<T> = T | null;

/**
 * Creates a new io-ts type that will be valid if the `is` typeguard validates the type.
 * This is useful to validate strings, as well as numbers (i.e. Only positives)
 * @param name The name of the resulting type
 * @param is The validation method, which returns a type guard
 */
export function typeFromValidation<A>(name: string, is: (u: unknown) => u is A): t.Type<A, A, unknown> {
  return new t.Type(name, is, (u, c) => (is(u) ? t.success(u) : t.failure(u, c)), t.identity);
}

/**
 * Version of Object.entries() with typed keys.
 */
export function fromEntries<TKey extends PropertyKey, TValue>(
  entries: ReadonlyArray<readonly [TKey, TValue]>,
): Record<TKey, TValue> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.fromEntries(entries) as Record<TKey, TValue>;
}

/** Utility type to infer the type of "all params of a function except the first" */
export type DropFirstParameter<Func extends (...args: Array<any>) => any> = Func extends (
  firstArg: any,
  ...restArgs: infer Rest
) => any
  ? Rest
  : never;

// Concatenates two string or number types with a dot in the middle
// ex: Join<"a","b.c"> becomes "a.b.c"
type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

// Tuple returning the previous number.
// ex: Prev[10] is 9, and Prev[1] is 0
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...Array<0>];

// Target recursion depth (only 1 level deep: `originalData.something`)
type DefaultTargetDepth = 1;

/**
 * Extracts the types of all the keys at the targeted depth (default 1).
 * @example
 * const obj = {a : {c : "hello", d: "world"}}
 * type ExtractedPaths = Paths<typeof obj> => "a.c" | "a.d"
 */
export type Paths<T, D extends number = DefaultTargetDepth> = [D] extends [never]
  ? never
  : T extends object
    ? {
        [K in keyof T]-?: K extends string | number
          ? (D extends DefaultTargetDepth ? never : `${K}`) | Join<K, Paths<T[K], Prev[D]>>
          : never;
      }[keyof T]
    : '';
