import {alphas, greys} from '../../styles/colorStyles';

export const lightCheckboxSemanticStyles = {
  disabledBoxColor: greys.shade40,
  disabledCheckColor: greys.shade60,
};

export type CheckboxSemanticStyles = typeof lightCheckboxSemanticStyles;

export const darkCheckboxSemanticStyles: CheckboxSemanticStyles = {
  disabledBoxColor: alphas.white30,
  disabledCheckColor: alphas.white60,
};
